import { PrimaryButton } from "@fm-frontend/uikit";
import { Auth } from "aws-amplify";
import { useRef, useState, VFC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Form } from "../../../style";
import { validators } from "../../../utils/validators";
import { FormHeader, FormInputError, FormLabel } from "../../form/style";
import { FormRow } from "../loginPage.styled";
import { Inputs, Props } from "./ResetPasswordCognito.types";

export const ResetPasswordCognito: VFC<Props> = ({ user, onEnableMFA }) => {
    const [globalError, setGlobalError] = useState<string | null>(null);
    const password = useRef({});
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<Inputs>({
        mode: "onChange",
    });
    password.current = watch("newPassword");

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            await Auth.completeNewPassword(user, data.newPassword);
            onEnableMFA(user);
        } catch (error) {
            setGlobalError(String(error));
        }
    };

    const handleChangePassword = () => {
        if (watch("newPasswordRepeat").length > 0) {
            setValue("newPasswordRepeat", watch("newPasswordRepeat"), { shouldValidate: true });
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormHeader>Reset your password</FormHeader>
            <FormRow>
                <FormLabel>New password</FormLabel>
                <input
                    type="password"
                    placeholder="New password"
                    {...register("newPassword", { ...validators.password, onChange: handleChangePassword })}
                />
                {errors.newPassword && <FormInputError>{errors.newPassword.message}</FormInputError>}
            </FormRow>
            <FormRow>
                <FormLabel>Confirm new password</FormLabel>
                <input
                    type="password"
                    placeholder="Confirm new password"
                    {...register("newPasswordRepeat", {
                        validate: (value) => value === watch("newPassword") || "The passwords do not match",
                    })}
                />
                {errors.newPasswordRepeat && <FormInputError>{errors.newPasswordRepeat.message}</FormInputError>}
            </FormRow>
            <FormRow>
                <FormInputError isCentered>{globalError}</FormInputError>
                <PrimaryButton fullWidth size="large" type="submit" loading={isSubmitting}>
                    Reset Password
                </PrimaryButton>
            </FormRow>
        </Form>
    );
};
