import {
    BasicInput,
    Form,
    FormActions,
    FormAttention,
    FormBody,
    FormInfo,
    FormProgress,
    Icons,
    P,
    PrimaryButton,
    ShadowCard,
    Skeleton,
} from "@fm-frontend/uikit";
import { Header as FormHeader } from "@fm-frontend/uikit/src/components/headers/Header";
import { HeaderTitle } from "@fm-frontend/uikit/src/components/headers/HeaderTitle";
import { AUTH_ERRORS } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Auth } from "aws-amplify";
import { CopyToClipboard } from "components/CopyToClipboard";
import { IconError } from "components/icons";
import { useAwsAuth, useFormHelpers } from "hooks";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { get2FATitle } from "const";
import { OnboardingMap, ONBOARDING_STEPS } from "store/onboardingSlice";
import styled, { useTheme } from "styled-components";
import { displayError } from "utils";
import { setUser } from "../../auth";
import { ChallengeName } from "../../auth/types";
import { trimLongText } from "../common";
import { confirmTwoFaDataSchema } from "./schemas";

export interface Inputs {
    secretKey: string;
    code: string;
}

const SecretCodeContainer = styled.div`
    min-width: 136px;
    min-height: 24px;
    overflow: scroll;
    display: flex;
    align-items: center;
    padding: 0 8px;

    background: ${(p) => p.theme.colors.ui8};
    border-radius: 40px;
    margin-right: 4px;
`;

const SkeletonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 4px;

    & > div:first-of-type {
        width: 145px;
        border-radius: 40px;
    }
    & > div:nth-of-type(2) {
        width: 24px;
        border-radius: 50%;
    }
`;

export interface Props {
    onBack: () => void;
    onNext: (user?: any) => any;
    email?: string;
    user?: any;
}

const SecretCodeSkeleton = () => {
    const { colors } = useTheme();

    return (
        <>
            <Skeleton width="128px" height="128px" />
            <P color={colors.ui52}>or, input the secret key in app manually</P>
            <SkeletonContainer>
                <Skeleton width="172px" height="24px" />
                <Skeleton width="20px" height="24px" />
            </SkeletonContainer>
        </>
    );
};

export const ConfirmTwoFA: React.FC<Props> = ({ onBack, user, email }) => {
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const [companyEmail, setCompanyEmail] = useState();
    const [secretCode, setSecretCode] = useState<string | undefined>();
    const { error, setError, isLoading, setLoading } = useFormHelpers();
    const { confirmMfa, verifyTotpToken, ensureUser } = useAwsAuth(user);
    const history = useHistory();

    useEffect(() => {
        setCompanyEmail(user?.signInUserSession?.idToken?.payload?.email ?? email);
        Auth.setupTOTP(user)
            .then((code) => {
                setSecretCode(code);
            })
            .catch((e: any) => {
                if (e?.code === AUTH_ERRORS.NotAuthorizedException) {
                    displayError(e?.name);
                    history.push("/login");
                } else {
                    displayError(e);
                }
            });
    }, [user]);

    const mfaURL = `otpauth://totp/${get2FATitle()}(${companyEmail})?secret=${secretCode}`;

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>({
        defaultValues: {
            secretKey: secretCode,
        },
        mode: "onSubmit",
        resolver: yupResolver(confirmTwoFaDataSchema),
    });

    const submit = async ({ code }: Inputs) => {
        let error: string | null = null;

        setLoading(true);

        if (user?.challengeName === ChallengeName.MFA_SETUP) {
            error = await verifyTotpToken(code);
        } else if (user?.challengeName === ChallengeName.SOFTWARE_TOKEN_MFA) {
            const data = await confirmMfa(String(code), ChallengeName.SOFTWARE_TOKEN_MFA);
            error = data.error;
        }

        if (error) {
            setError(error);
            setLoading(false);
            return;
        }

        await ensureUser();

        setError(null);

        history.push("/");
        dispatch(setUser(user?.signInUserSession));
    };

    return (
        <Form onSubmit={handleSubmit(submit)}>
            <FormProgress
                currentStep={OnboardingMap[ONBOARDING_STEPS.ACCOUNT_CONFIRM_TWO_FA].progress.current}
                totalSteps={OnboardingMap[ONBOARDING_STEPS.ACCOUNT_CONFIRM_TWO_FA].progress.total}
                onBackClick={onBack}
            />
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Set two-factor authentication" />
                </FormHeader>
                <FormBody>
                    <FormInfo>
                        First, <span>scan the QR code</span> below with Google Authenticator or similar app
                    </FormInfo>
                    {secretCode ? (
                        <>
                            <QRCode value={mfaURL} />
                            <P color={colors.ui52}>or, input the secret key in app manually</P>
                            <CopyToClipboard value={secretCode}>
                                <SecretCodeContainer>{trimLongText(secretCode)}</SecretCodeContainer>
                                <Icons.CopyIcon />
                            </CopyToClipboard>
                        </>
                    ) : (
                        <SecretCodeSkeleton />
                    )}
                    <FormAttention>
                        <IconError />
                        <P>
                            We strongly recommend to keep this key in a safe place to restore the access in case of a
                            phone loss.
                        </P>
                    </FormAttention>
                </FormBody>
            </ShadowCard>
            <ShadowCard>
                <FormBody>
                    <FormInfo>
                        Then <span>enter the 6-digit</span> code from the app
                    </FormInfo>
                    <BasicInput placeholder="000000" {...register("code")} error={errors.code?.message} />
                    {error && (
                        <FormAttention>
                            <IconError />
                            <P>{error}</P>
                        </FormAttention>
                    )}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton fullWidth size="large" loading={isLoading}>
                        Send
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};
