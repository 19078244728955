import {
    BasicButton,
    ClientId,
    getMatch,
    getMatchStartsWith,
    H2,
    HStack,
    Modal,
    ShadowCard,
    TextSmall,
    VStack,
} from "@fm-frontend/uikit";
import { getEnv, useFormShouldCloseConfirm } from "@fm-frontend/utils";
import { post } from "api";
import { CLIENT_TYPE_TITLES } from "const";
import { FIXED_MODAL_STYLE } from "const/modal";
import { createNotification, setConfirmModalContent } from "feature/app/store";
import { useFaqLinks } from "hooks/useFaqLinks";
import { useLimitsByAsset } from "hooks/useLimitsByAsset";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as CounterpartyIcon } from "resources/counterparty_icon.svg";
import { CpStatus } from "store/counterpartiesSlice";
import {
    useIsMakerUser,
    useIsPrimeBrokerUser,
    useIsSubaccountUser,
    usePrimeBrokerViewType,
    useUsdPrices,
} from "store/hooks";
import { clearPreopenedCpIdLimit, getPreopenedCpIdLimit } from "store/preopenedCpIdLimit";
import { Spacer } from "style";
import styled from "styled-components";
import { CounterpartyLimitByAsset } from "types";
import { CounterpartyLimitModal } from "../counterpartyLimitModal";
import { LimitByAssetPreview } from "../counterpartyLimitModal/limitByAssetPreview/LimitByAssetPreview";
import {
    ASSETS_CONTROL_TAB,
    COUNTERPARTY_LIMIT_MODAL_KEY,
    TRADING_LIMITS_TAB,
    URL_DELETE_CLIMIT,
} from "../counterpartyLimitModal/utils";
import { LimitProps, LimitType } from "../types";
import {
    CounterpartyStatus,
    CounterpartyStatusType,
    isSubaccountCp,
    parseLimit,
    parseLimitByAsset,
    shouldUseUserGrossLimit,
} from "../utils";
import { CounterpartyIndicator } from "./counterpartyIndicator";
import { IndicatorHint } from "./indicatorHint/IndicatorHint";
import * as Styled from "./styled";
import {
    getAppliedLimit,
    getCpLimitIndicatorData,
    getEquityIndicatorData,
    getGrossFreeIndicatorData,
    getGrossLimitIndicatorData,
    getInitialMarginLimitIndicatorData,
    getMaintenanceMarginLimitIndicatorData,
    getRestrictedTradingLimitIndicatorData,
    getTradingMarkupIndicatorData,
} from "./utils";

const EditFormHead = styled(HStack)`
    white-space: nowrap;
    gap: 8px;
    padding: 12px;
`;

const EditFormTitle = styled(HStack)`
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    align-items: baseline;
    gap: 8px;

    ${H2} {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const BigCounterpartyIcon = styled(CounterpartyIcon)`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

const defaultStatusObj: CpStatus = {
    status: CounterpartyStatusType.NORMAL,
    grossStatus: CounterpartyStatusType.NORMAL,
    equityStatus: CounterpartyStatusType.NORMAL,
} as const;

const marginRequirementOffIndicator = {
    title: "Margin requirement",
    value: "Off",
};

const { HRP_MASTER_ID, HRP_CLEARING_TIME } = getEnv();
const ClearingTime = styled.div`
    width: 100%;
    text-align: right;
    color: ${(p) => p.theme.colors.ui72};
`;
const hrpClearingTime = <ClearingTime>🗓️ Clearing at {HRP_CLEARING_TIME}</ClearingTime>;

export const Counterparty: React.FC<LimitProps> = (props) => {
    const faqLinks = useFaqLinks();
    const { cpTitle, extendedLimit, cpId, cpStatus, searchQuery = "" } = props;
    const [isOpened, setIsOpened] = useState(getPreopenedCpIdLimit() === cpId);
    const [defaultTab, setDefaultTab] = useState(TRADING_LIMITS_TAB);
    const isSubaccountUser = useIsSubaccountUser();
    const isMakerUser = useIsMakerUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    useEffect(() => {
        clearPreopenedCpIdLimit();
    }, []);

    const { priceObj } = useUsdPrices();

    const { limit, cpType: limitCpType } = extendedLimit;
    const shouldTakeUserGrossLimit = shouldUseUserGrossLimit(limitCpType, isMakerUser);
    const { limitsByAssets } = useLimitsByAsset(isSubaccountCp(limitCpType));

    const limitsByAssetObj = useMemo(() => {
        return limitsByAssets.reduce<Record<string, CounterpartyLimitByAsset[]>>((acc, current) => {
            const { counterpartyId } = parseLimitByAsset(current);

            if (acc[counterpartyId]) {
                acc[counterpartyId].push(current);
            } else {
                acc[counterpartyId] = [current];
            }

            return acc;
        }, {});
    }, [limitsByAssets]);

    const { counterpartyId, maintenanceMargin, restrictedTrading, initialMargin } = parseLimit(limit);

    const { status, grossStatus, equityStatus } = cpStatus ?? defaultStatusObj;

    const { appliedLimitType, appliedLimit } = useMemo(() => getAppliedLimit(limit, priceObj), [limit, priceObj]);

    const grossFreeIndicator = useMemo(
        () => getGrossFreeIndicatorData(limit, appliedLimit, isSubaccountUser),
        [limit, appliedLimit, isSubaccountUser],
    );

    const grossLimitIndicator = useMemo(
        () => getGrossLimitIndicatorData(limit, appliedLimitType),
        [limit, appliedLimitType],
    );

    const cpLimitIndicator = useMemo(
        () => getCpLimitIndicatorData(limit, appliedLimitType, isSubaccountUser, cpTitle),
        [limit, appliedLimitType, isSubaccountUser, cpTitle],
    );

    const equityIndicator = useMemo(
        () => getEquityIndicatorData(limit, shouldTakeUserGrossLimit, limitCpType, cpTitle),
        [limit, shouldTakeUserGrossLimit, limitCpType, cpTitle],
    );

    const maintenanceMarginLimitIndicator = useMemo(
        () => getMaintenanceMarginLimitIndicatorData(limit, shouldTakeUserGrossLimit),
        [limit, shouldTakeUserGrossLimit],
    );

    const restrictedTradingLimitIndicator = useMemo(
        () => getRestrictedTradingLimitIndicatorData(limit, shouldTakeUserGrossLimit),
        [limit, shouldTakeUserGrossLimit],
    );

    const tradingMarkupIndicator = useMemo(() => getTradingMarkupIndicatorData(limit), [limit]);

    const initialMarginLimitIndicator = useMemo(
        () => getInitialMarginLimitIndicatorData(limit, shouldTakeUserGrossLimit),
        [limit, shouldTakeUserGrossLimit],
    );

    const isMarginRequirementOn = Boolean(maintenanceMargin && restrictedTrading && initialMargin);

    // CounterpartyLimitModal helpers
    const dispatch = useDispatch();
    const [limitDeleting, setLimitDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState<string>();
    const closeModal = () => {
        setIsOpened(false);
        setDeleteError(undefined);
    };
    const shouldConfirmClose = useFormShouldCloseConfirm(COUNTERPARTY_LIMIT_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldConfirmClose, closeModal);
    const handleLimitDelete = async () => {
        dispatch(
            setConfirmModalContent({
                title: "Delete your trading activity with this client?",
                onConfirm: async () => {
                    try {
                        setLimitDeleting(true);
                        await post(URL_DELETE_CLIMIT, {
                            counterpartyId: counterpartyId,
                        });

                        dispatch(
                            createNotification({
                                type: "success",
                                content: "Limit has been deleted",
                            }),
                        );
                        closeModal();
                    } catch (e) {
                        setDeleteError(String(e));
                    } finally {
                        setLimitDeleting(false);
                    }
                },
                description: "",
                confirmButtonTitle: "Delete",
            }),
        );
    };

    const cpLimitHidden = isPrimeBrokerUser && primeBrokerViewType === "subaccounts";
    const handleConfigureClick = async () => {
        setDefaultTab(TRADING_LIMITS_TAB);
        setIsOpened(true);
    };

    const type = Number(cpId) === HRP_MASTER_ID ? "Clearing broker" : CLIENT_TYPE_TITLES[limitCpType];

    return (
        <ShadowCard>
            <Styled.Container>
                <Styled.Header>
                    <Styled.Title>
                        <Styled.TitleIcon status={status} />
                        <Styled.TitleBody>
                            <Styled.TitleHeader>
                                <H2>{getMatch(cpTitle, searchQuery) ?? cpTitle}</H2>
                                <ClientId
                                    id={getMatchStartsWith(String(counterpartyId), searchQuery) ?? counterpartyId}
                                />
                            </Styled.TitleHeader>
                            <Styled.TitleDescription>
                                <TextSmall>{type}</TextSmall>
                                {tradingMarkupIndicator.copyableValue && (
                                    <TextSmall>
                                        Markup <span>{tradingMarkupIndicator.value}</span>
                                    </TextSmall>
                                )}
                            </Styled.TitleDescription>
                        </Styled.TitleBody>
                    </Styled.Title>
                    <Styled.Actions>
                        <Styled.Badge status={status}>{status ? CounterpartyStatus[status].title : ""}</Styled.Badge>
                        {!isSubaccountUser && (
                            <BasicButton size="small" onClick={handleConfigureClick}>
                                Edit
                            </BasicButton>
                        )}
                    </Styled.Actions>
                </Styled.Header>
                <Styled.ScrollContainer>
                    <Styled.Body>
                        <Styled.HintWrapper>
                            <Styled.IndicatorContainer status={status} subStatus={grossStatus}>
                                <Styled.IndicatorWrapper>
                                    <CounterpartyIndicator
                                        status={status}
                                        subStatus={grossStatus}
                                        indicatorData={grossFreeIndicator}
                                        mainIndicator
                                        highlighted
                                    />
                                    <LimitByAssetPreview
                                        onClick={() => {
                                            setDefaultTab(ASSETS_CONTROL_TAB);
                                            setIsOpened(true);
                                        }}
                                        limitsByAsset={limitsByAssetObj[counterpartyId]}
                                    />
                                </Styled.IndicatorWrapper>
                                <Styled.IndicatorWrapper>
                                    {!isSubaccountUser && (
                                        <CounterpartyIndicator
                                            indicatorData={grossLimitIndicator}
                                            highlighted={appliedLimitType === LimitType.UserLimit}
                                        />
                                    )}
                                    {!cpLimitHidden && (
                                        <CounterpartyIndicator
                                            indicatorData={cpLimitIndicator}
                                            highlighted={appliedLimitType === LimitType.MutualLimit}
                                        />
                                    )}
                                </Styled.IndicatorWrapper>
                            </Styled.IndicatorContainer>
                            <IndicatorHint status={status} statusMask={CounterpartyStatusType.LOW_GROSS_FREE} />
                        </Styled.HintWrapper>
                        <Styled.HintWrapper>
                            <Styled.IndicatorContainer status={status} subStatus={equityStatus}>
                                <Styled.IndicatorWrapper>
                                    <CounterpartyIndicator
                                        status={status}
                                        subStatus={equityStatus}
                                        indicatorData={equityIndicator}
                                        mainIndicator={true}
                                        highlighted={true}
                                    />
                                    {HRP_MASTER_ID === Number(cpId) && hrpClearingTime}
                                </Styled.IndicatorWrapper>
                                <Styled.IndicatorWrapper>
                                    {isMarginRequirementOn ? (
                                        <>
                                            <CounterpartyIndicator
                                                indicatorData={
                                                    isMakerUser || isPrimeBrokerUser
                                                        ? maintenanceMarginLimitIndicator
                                                        : initialMarginLimitIndicator
                                                }
                                            />
                                            <CounterpartyIndicator indicatorData={restrictedTradingLimitIndicator} />
                                        </>
                                    ) : (
                                        <CounterpartyIndicator
                                            indicatorData={marginRequirementOffIndicator}
                                            highlighted={false}
                                        />
                                    )}
                                </Styled.IndicatorWrapper>
                            </Styled.IndicatorContainer>
                            <IndicatorHint status={status} statusMask={CounterpartyStatusType.LIQUIDATION} />
                        </Styled.HintWrapper>
                    </Styled.Body>
                </Styled.ScrollContainer>
                <Modal
                    isOpen={isOpened}
                    onClose={closeModalWithConfirm}
                    additionalActions={{
                        helpButtonUrlOrClickHandler: faqLinks?.faqRiskManagement,
                    }}
                    style={FIXED_MODAL_STYLE}
                >
                    <VStack width="360px" asCard>
                        <EditFormHead>
                            <BigCounterpartyIcon />
                            <EditFormTitle>
                                <H2>{cpTitle}</H2>
                                <ClientId id={cpId} />
                                <Spacer />
                                {
                                    <Styled.Badge status={status}>
                                        {status ? CounterpartyStatus[status].title : ""}
                                    </Styled.Badge>
                                }
                            </EditFormTitle>
                        </EditFormHead>
                        <VStack padding={12} paddingTop={0}>
                            <CounterpartyLimitModal
                                defaultTab={defaultTab}
                                status={status}
                                title={cpTitle}
                                limit={limit}
                                cpData={{ cpId: counterpartyId, cpType: limitCpType, cpName: cpTitle }}
                                deleting={limitDeleting}
                                error={deleteError}
                                onSubmit={closeModal}
                                onClose={closeModalWithConfirm}
                                onDelete={handleLimitDelete}
                            />
                        </VStack>
                    </VStack>
                </Modal>
            </Styled.Container>
        </ShadowCard>
    );
};
