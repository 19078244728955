import { post } from "api";
import { EMPTY_ARRAY } from "const";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import useSWR from "swr";
import { CounterpartyLimit, SubaccountLimit } from "types";
import { displayError } from "utils";

export const useFilteredLimits = (refreshIntervalMs?: number) => {
    const fetcher = (url: string) => post(url, {});
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const apiUrl =
        isPrimeBrokerUser && primeBrokerViewType === "subaccounts"
            ? "subaccountsLimits"
            : "climits";

    return useSWR<(CounterpartyLimit | SubaccountLimit)[]>(apiUrl, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY as (CounterpartyLimit | SubaccountLimit)[],
        revalidateOnMount: true,
        revalidateOnFocus: false,
        refreshInterval: refreshIntervalMs,
    });
};
