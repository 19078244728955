import { createContext, FC, useContext, useState } from "react";

export type FilterInputs = {
    searchQuery: string;
    types: ("maker" | "taker")[];
    status: ("active" | "pending" | "disabled")[];
    sort: "status" | "type";
};
type FilterContextType = {
    filters: FilterInputs;
    setFilters: (values: FilterInputs) => void;
};

export const DEFAULT_FILTER_INPUTS: FilterInputs = {
    searchQuery: "",
    types: [],
    status: [],
    sort: "status",
};

const FilterContext = createContext<FilterContextType | null>(null);
FilterContext.displayName = "FilterContext";

export const useFilterContext = (): FilterContextType => {
    const context = useContext(FilterContext);

    if (context === null) {
        throw new Error("useFilterContext must be within FilterContextProvider");
    }

    return context;
};

export const FilterContextProvider: FC = ({ children }) => {
    const [filters, setFilters] = useState<FilterInputs>({ ...DEFAULT_FILTER_INPUTS });

    return (
        <FilterContext.Provider
            value={{
                filters,
                setFilters,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};
