import { useGA4Context } from "@fm-frontend/utils";
import { useSelector } from "hooks";
import { useCallback } from "react";

enum BulkLogEventsEnum {
    Export = "bulk_export",
    Import = "bulk_import",
}

export const useBulkLogger = () => {
    const { userId } = useSelector((state) => state.auth);
    const { logCustomEvent } = useGA4Context();

    const logBulkExport = useCallback(() => {
        logCustomEvent(BulkLogEventsEnum.Export, { clientId: userId ?? 0 });
    }, [logCustomEvent]);

    const logBulkImport = useCallback(() => {
        logCustomEvent(BulkLogEventsEnum.Import, { clientId: userId ?? 0 });
    }, [logCustomEvent]);

    return {
        logBulkExport,
        logBulkImport,
    };
};
