import { PrimaryButton } from "@fm-frontend/uikit";
import { Auth } from "aws-amplify";
import { IconError } from "components/icons";
import QRCode from "qrcode.react";
import React, { useEffect, useState, VFC } from "react";
import { get2FATitle } from "const";
import {
    BottomLogin,
    FormRow,
    GetAppCodeInput,
    GetAppQRCode,
    HeadTitle,
    SlimWidget,
    SlimWidgetMessage,
    Text,
} from "../loginPage.styled";
import { FormButtonWrapper, FormImageContainer, FormImageWrapper } from "../style";
import { Props } from "./enableMFAPage.types";
import { MfaSteps } from "./types";

export const EnableMFAPage: VFC<Props> = ({ onNext, user, email }) => {
    const [compemail, setEmail] = useState();
    const [code, setCode] = useState<string | undefined>();
    const [mfaStep, setMfaStep] = useState(MfaSteps.setupAuthenticator);

    useEffect(() => {
        setEmail(user?.signInUserSession?.idToken?.payload?.email ?? email);
        Auth.setupTOTP(user)
            .then((code) => {
                setCode(code);
            })
            .catch((e) => console.error(e));
    }, [user]);

    const mfaURL = `otpauth://totp/${get2FATitle()}(${compemail})?secret=${code}`;

    const getInstallAuthenticatorStep = () => {
        return (
            <>
                <Text>
                    First, download <b>Google Authenticator</b> app from App Store or Google Play
                </Text>
                <FormImageContainer>
                    <img src={"/img/authenticator.png"} width={120} height={120} alt={"Google Authenticator"} />
                </FormImageContainer>
                <FormImageContainer>
                    <FormImageWrapper>
                        <a
                            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={"/img/app-store.png"} height={54} alt={"App Store"} />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={"/img/google-play.png"} height={54} alt={"Google Play"} />
                        </a>
                    </FormImageWrapper>
                </FormImageContainer>
                <Text>
                    If Google Authenticator or another app with the same functionality is already installed, please
                    continue
                </Text>
                <FormButtonWrapper>
                    <PrimaryButton fullWidth size="large" onClick={() => setMfaStep(MfaSteps.setupMfaCode)}>
                        Next
                    </PrimaryButton>
                </FormButtonWrapper>
            </>
        );
    };

    const getSetupMfaCodeStep = () => {
        return (
            <>
                <Text>
                    Scan the QR code below with <b>Google Authenticator</b>
                </Text>
                <div>
                    <GetAppQRCode>{mfaURL ? <QRCode value={mfaURL} /> : null}</GetAppQRCode>
                </div>
                <form>
                    <FormRow>
                        <Text>Or, input the secret key manually</Text>
                        <GetAppCodeInput
                            name="key"
                            placeholder="xxx xxx"
                            type="text"
                            value={code || ""}
                            id="key"
                            required
                            readOnly
                            autoComplete="off"
                        />
                    </FormRow>
                    <SlimWidgetMessage>
                        <IconError />
                        We strongly recommend you to keep this key in a safe place to restore the access in case of a
                        phone loss.
                    </SlimWidgetMessage>
                    <FormRow>
                        <PrimaryButton
                            size="large"
                            fullWidth
                            type="submit"
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                onNext(user);
                            }}
                        >
                            Next
                        </PrimaryButton>
                    </FormRow>
                </form>
            </>
        );
    };

    return (
        <>
            <SlimWidget>
                <HeadTitle>Set 2FA authentication</HeadTitle>
                {mfaStep === MfaSteps.setupAuthenticator
                    ? getInstallAuthenticatorStep()
                    : mfaStep === MfaSteps.setupMfaCode
                    ? getSetupMfaCodeStep()
                    : null}
            </SlimWidget>
            <BottomLogin>
                {mfaStep === MfaSteps.setupMfaCode && (
                    <a
                        href="#"
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            setMfaStep(MfaSteps.setupAuthenticator);
                        }}
                    >
                        Back
                    </a>
                )}
            </BottomLogin>
        </>
    );
};
