import { post } from "api";
import { EMPTY_ARRAY } from "const";
import { parseLimitByAsset } from "feature/counterparties/utils";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import useSWR from "swr";
import { CounterpartyLimitByAsset } from "types";
import { displayError } from "utils";

export const useLimitByAssetSWRKey = () => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    return isPrimeBrokerUser && primeBrokerViewType === "subaccounts" ? "subaccountsAssetLimits" : "cAssetLimits";
};

export const useExtendedLimitsByAsset = () => {
    const fetcher = (url: string) => post(url, {});
    const apiMethod = useLimitByAssetSWRKey();
    const { data, mutate, isLoading } = useSWR<CounterpartyLimitByAsset[]>(apiMethod, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY as CounterpartyLimitByAsset[],
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    return {
        limitsByAssets: data?.map((l) => parseLimitByAsset(l)) ?? [],
        isLoading,
        mutate,
    };
};
