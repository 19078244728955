import EfxTypes from "utils/EfxTypes";
import { FormatConfig, FormatPercentConfig } from "utils/format";
import { Inputs } from "./types";

export const COUNTERPARTY_LIMIT_MODAL_KEY = Symbol('COUNTERPARTY_LIMIT_MODAL_KEY');

export const TRADING_LIMITS_TAB = "1";
export const ASSETS_CONTROL_TAB = "2";

export const URL_DELETE_CLIMIT = "delCLimit";
export const FMT_PERCENT_CONFIG: FormatPercentConfig = {
    significantDigits: 4,
    minSignificantDigits: 2,
};
export const getFmtModalIndicatorConfig = (currency: string): FormatConfig => ({
    type: "limit",
    significantDigits: 2,
    unitPosition: "post",
    showZero: false,
    unit: ` ${currency}`,
});

const getQueryParams = (
    values: Inputs,
    {
        isNewLimit,
        isMarkupActive,
        isMarginActive,
    }: {
        isNewLimit?: boolean;
        isMarginActive?: boolean;
        isMarkupActive?: boolean;
    },
) => {
    let marginParams = isNewLimit
        ? {}
        : {
              maintenanceMargin: 0,
              restrictedTrading: 0,
              initialMargin: 0,
          };
    let markupParams = isNewLimit
        ? {}
        : {
              takerMarkup: 0,
          };
    if (isMarginActive) {
        marginParams = {
            maintenanceMargin: EfxTypes.parseValue(String(values.maintenanceMargin), "deltaratePercent"),
            restrictedTrading: EfxTypes.parseValue(String(values.restrictedTrading), "deltaratePercent"),
            initialMargin: EfxTypes.parseValue(String(values.initialMargin), "deltaratePercent"),
        };
    }
    if (isMarkupActive) {
        markupParams = {
            takerMarkup: EfxTypes.parseValue(String(values.markup), "deltaratePercent"),
        };
    }

    return {
        counterpartyId: Number(values.counterpartyId),
        grossLimit: EfxTypes.parseValue(String(values.grossLimit), "limit"),
        currency: values.currency,
        marginParams,
        markupParams,
    };
};

interface LimitSettings {
    isNewLimit?: boolean;
    isMarginActive?: boolean;
    isMarkupActive?: boolean;
}

export const getRequestBodyForNotSubaccount = (values: Inputs, limitSettings: LimitSettings) => {
    const { counterpartyId, grossLimit, currency, marginParams, markupParams } = getQueryParams(values, limitSettings);

    return {
        counterpartyId,
        grossLimit,
        currency,
        ...marginParams,
        ...markupParams,
    };
};

export const getRequestBodyForSubaccount = (values: Inputs, limitSettings: LimitSettings) => {
    const { counterpartyId, grossLimit, currency, marginParams, markupParams } = getQueryParams(values, limitSettings);

    return {
        subaccountId: counterpartyId,
        limits: {
            grossLimit,
            currency,
            margins: marginParams,
            markup: markupParams?.takerMarkup ?? 0,
        },
    };
};

const formatPercentageOverflow = (margin: number) => (margin > 100 ? 100 : margin);

export const getMarginsAutocomplete = (maintenanceMargin: number) => {
    const marginCall = maintenanceMargin * 1.5;
    const initialMargin = maintenanceMargin * 2;

    return {
        restrictedTrading: formatPercentageOverflow(marginCall),
        initialMargin: formatPercentageOverflow(initialMargin),
    };
};


