import { createColumnHelper } from "@tanstack/react-table";
import { InstrumentNameCell } from "./cells";
import { RowData } from "./types";

export const columnHelper = createColumnHelper<RowData>();

export const columns = [
    columnHelper.accessor((row) => row, {
        id: "name",
        cell: (subject) => {
            const value = subject.getValue();

            return <InstrumentNameCell instrument={value.instrument} />;
        },
        header: "Name",
        enableSorting: false,
        meta: {
            cellStyleProps: {
                padding: [0, 0, 0, 0],
            },
        },
    }),
];
