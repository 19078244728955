import { PrimaryButton } from "@fm-frontend/uikit";
import { getEnv, useFormCloseConfirmer } from "@fm-frontend/utils";
import { Auth } from "aws-amplify";
import { useFormHelpers } from "hooks";
import React, { useState } from "react";
import { useStepper } from "../../hooks/useStepper";
import { ButtonGroup, Form, GuestActions } from "../../style";
import { GreenLink } from "../../style/menu";
import { CommonValidation, getPasswordField } from "../form/fields";
import FormInput from "../form/FormInput";
import { FormHeader, FormHint, FormLabel } from "../form/style";
import { FormConfig, useForm } from "../form/useForm";
import { Text } from "./loginPage.styled";
import { RESET_PASSWORD_MODAL_KEY } from "./EnableMFAPage/utils";

export const PasswordForm: React.FC<{
    isInline?: true;
    onSuccess?: () => void;
}> = ({ isInline, onSuccess }) => {
    const [isDirty, setIsDirty] = useState(false);

    const passwordForm: FormConfig = {
        fields: {
            password: getPasswordField({
                autoComplete: "current-password",
            }),
            newPassword: getPasswordField({
                validtion: true,
                autoComplete: "new-password",
            }),
            newPasswordRepeat: getPasswordField({
                validtion: true,
                autoComplete: "new-password",
            }),
            code: {},
        },
        validate: CommonValidation.passwordsMatch,
        url: "changePassword",
        onChange: () => {
            setIsDirty(true);
        },
    };

    useFormCloseConfirmer(RESET_PASSWORD_MODAL_KEY, isDirty);

    const { propsFor, globalError, areActionsDisabled, values } = useForm(passwordForm);
    const { currentStep, next } = useStepper();
    const { startLoading, stopLoading, isLoading } = useFormHelpers();

    const handleChangePassword = async (e: React.MouseEvent) => {
        e.preventDefault();
        startLoading();

        Auth.currentAuthenticatedUser()
            .then((user) => {
                return Auth.changePassword(user, String(values.password), String(values.newPassword));
            })
            .then(() => {
                next();
            })
            .finally(() => {
                stopLoading();
            });
    };

    return [
        <>
            <Form>
                <FormHeader>Change password</FormHeader>
                <FormLabel>Current password</FormLabel>
                <FormInput {...propsFor("password")} type="password" />
                <FormLabel>New password</FormLabel>
                <FormInput {...propsFor("newPassword")} type="password" />
                <FormHint>Password must be at least 10 characters, contain one uppercase and one digit</FormHint>
                <FormLabel>Repeat password</FormLabel>
                <FormInput {...propsFor("newPasswordRepeat")} type="password" />
                {globalError}
                <ButtonGroup>
                    <PrimaryButton
                        size="large"
                        type="submit"
                        disabled={areActionsDisabled}
                        onClick={handleChangePassword}
                        loading={isLoading}
                        fullWidth
                    >
                        Submit
                    </PrimaryButton>
                </ButtonGroup>
            </Form>
            {isInline ? null : (
                <GuestActions>
                    <GreenLink href="#" onClick={handleChangePassword}>
                        Logout
                    </GreenLink>
                </GuestActions>
            )}
        </>,
        <Form>
            <FormHeader>Password changed successfully</FormHeader>
            <Text>Well done! You can now use your new password to log into your account.</Text>
            <ButtonGroup>
                <PrimaryButton
                    size="large"
                    fullWidth
                    type="submit"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        onSuccess?.();
                    }}
                >
                    Back to {getEnv().PROJECT_NAME}
                </PrimaryButton>
            </ButtonGroup>
        </Form>,
    ][currentStep];
};
