import { post } from "api";
import useSWR from "swr";

export interface ClientData {
    registrationNumber: string;
    country: string;
    companyName: string;
    address: string;
    city: string;
    zipCode: string;
    fineryShare: number;
    makerFee: number;
    signTnc: boolean;
    subsSignTnc: boolean;
}

export const useClientData = () => {
    const fetcher = (key: string) => post(key, {});
    const { data: clientData, isValidating } = useSWR<ClientData>("getClientData", fetcher);

    return {
        clientData,
        isLoading: isValidating,
    };
};
