import { createColumnHelper } from "@tanstack/react-table";
import { CpNameCell, InstrumentNameCell } from "./cells";
import { RowData } from "./types";
import { isInstrumentRow } from "./utils";

export const columnHelper = createColumnHelper<RowData>();

export const columns = [
    columnHelper.accessor((row) => row, {
        id: "name",
        cell: (subject) => {
            const value = subject.getValue();

            if (isInstrumentRow(value)) {
                return <InstrumentNameCell instrument={value.instrument} />;
            }

            const { id, name } = value;

            return <CpNameCell cpId={id} cpName={name} />;
        },
        header: "Name",
        enableSorting: false,
        meta: {
            cellStyleProps: {
                padding: [0, 0, 0, 0],
            },
        },
    }),
];
