import { CLIENT_TYPE_TITLES, EmDash } from "const";
import { useCounterparties, useInstruments } from "hooks";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useExtendedLimitsByAsset } from "hooks/useExtentedLimitsByAsset";
import { useShortSalesBan } from "hooks/useShortSalesBan";
import { useMemo } from "react";

export const useBulkEditData = () => {
    const { shortSalesBans } = useShortSalesBan();
    const { currencies } = useInstruments();
    const { limitsByAssets } = useExtendedLimitsByAsset();
    const { counterparties } = useCounterparties();
    const { getCpType } = useCpInfoHelpers();

    return useMemo(() => {
        return Object.values(currencies)
            .map((currency) => {
                return Object.entries(counterparties).map(([cpId, cpName]) => {
                    const limit = limitsByAssets.find(
                        (l) => l.counterpartyId === Number(cpId) && l.asset === currency.name,
                    );
                    const shortSalesBan = shortSalesBans[Number(cpId)]?.some(
                        (shorSalesInfo) => shorSalesInfo.asset === currency.name,
                    );
                    const cpType = getCpType(Number(cpId));

                    return {
                        currencyName: currency.name,
                        currencyType: currency.currencyType,
                        cpName: cpName,
                        cpType: cpType === undefined ? EmDash : CLIENT_TYPE_TITLES[cpType],
                        cpId: Number(cpId),
                        shortSales: !shortSalesBan,
                        limitAmount: limit?.grossLimit,
                        limitCurrency: limit?.limitCurrency || limit?.asset,
                    };
                });
            })
            .flat();
    }, [currencies, counterparties]);
};
