import { HStack } from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { Control, Controller } from "react-hook-form";
import { useIsSubaccountUser } from "store/hooks";
import { Filters } from "../useFilters";

type AssetLimitStatus = "enabledByUser" | "disabledByUser" | "enabledByCp" | "disabledByCp";
const ASSET_LIMIT_STATUS_OPTIONS: DropdownOption<AssetLimitStatus>[] = [
    {
        text: "Enabled",
        value: "enabledByUser",
    },
    {
        text: "Disabled",
        value: "disabledByUser",
    },
    {
        text: "Enabled",
        value: "enabledByCp",
    },
    {
        text: "Disabled",
        value: "disabledByCp",
    },
];

export const LimitPerAssetFilter = ({ control }: { control: Control<Filters> }) => {
    const isSubaccountUser = useIsSubaccountUser();

    if (isSubaccountUser) {
        return (
            <Controller
                control={control}
                render={({ field }) => (
                    <MultipleDropdown
                        values={field.value}
                        onChange={field.onChange}
                        renderTrigger={(trigger) => (
                            <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                                <MultipleDropdown.TriggerEssence
                                    {...trigger}
                                    option={trigger.selectedOptions}
                                    size="small"
                                />
                            </MultipleDropdown.Trigger>
                        )}
                        options={ASSET_LIMIT_STATUS_OPTIONS.slice(2)}
                        align="end"
                        caption="Limit per asset"
                        asFilter
                    >
                        <MultipleDropdown.BasicSheet
                            size="small"
                            options={ASSET_LIMIT_STATUS_OPTIONS.slice(2)}
                        />
                    </MultipleDropdown>
                )}
                name="limitPerAssetFilter"
            />
        );
    }

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                            <MultipleDropdown.TriggerEssence
                                {...trigger}
                                option={trigger.selectedOptions}
                                size="small"
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    options={ASSET_LIMIT_STATUS_OPTIONS}
                    align="end"
                    caption="Limit per asset"
                    asFilter
                >
                    <MultipleDropdown.Sheet size="small">
                        {() => (
                            <>
                                <MultipleDropdown.EmptySearch>
                                    <MultipleDropdown.SheetGroup caption="By you" columns={1}>
                                        {ASSET_LIMIT_STATUS_OPTIONS.slice(0, 2).map((opt) => (
                                            <MultipleDropdown.Option
                                                key={opt.value}
                                                value={opt.value}
                                            >
                                                <HStack spacing={8} alignItems="center">
                                                    {opt.text}
                                                </HStack>
                                            </MultipleDropdown.Option>
                                        ))}
                                    </MultipleDropdown.SheetGroup>
                                    <MultipleDropdown.SheetGroup caption="By CP" columns={1}>
                                        {ASSET_LIMIT_STATUS_OPTIONS.slice(2).map((opt) => (
                                            <MultipleDropdown.Option
                                                key={opt.value}
                                                value={opt.value}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {opt.text}
                                                </div>
                                            </MultipleDropdown.Option>
                                        ))}
                                    </MultipleDropdown.SheetGroup>
                                </MultipleDropdown.EmptySearch>
                            </>
                        )}
                    </MultipleDropdown.Sheet>
                </MultipleDropdown>
            )}
            name="limitPerAssetFilter"
        />
    );
};
