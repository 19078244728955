import { Skeleton } from "@fm-frontend/uikit";
import { FC, useEffect, useState } from "react";
import { TopMenu } from "components/TopMenu";
import { CounterpartyStatusGroupType } from "feature/counterparties/utils";
import { useIsSubaccountUser, usePrimeBrokerViewType } from "store/hooks";
import { Page, Sidebar } from "style";
import { CounterpartyList } from "./CounterpartyList";
import { useCounterpartiesStatuses } from "./hooks/useCounterpartiesStatuses";
import { Onboarding } from "./Onboarding";

export const RiskManagement: FC = () => {
    const [activeStatusGroups, setActiveStatusGroups] = useState<CounterpartyStatusGroupType[]>([]);
    const isSubaccountUser = useIsSubaccountUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const { data: cpStatuses, isLoading } = useCounterpartiesStatuses();
    const counterpartyCount = Object.values(cpStatuses).length;

    useEffect(() => {
        setActiveStatusGroups([]);
    }, [primeBrokerViewType])

    return (
        <Page>
            <TopMenu>Risk management</TopMenu>
            <Sidebar>
                {isLoading && (
                    <Skeleton height="170px" />
                )}
                {!isLoading && !isSubaccountUser && counterpartyCount === 0 && (
                    <Onboarding />
                )}
                {!isLoading && (isSubaccountUser || counterpartyCount > 0) && (
                    <CounterpartyList
                        counterpartiesStatuses={cpStatuses}
                        activeStatusGroups={activeStatusGroups}
                        onChangeStatusGroups={setActiveStatusGroups}
                    />
                )}
            </Sidebar>
        </Page>
    );
};
