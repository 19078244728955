import { HStack } from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { Control, Controller } from "react-hook-form";
import { Filters } from "../useFilters";

type OvernightsStatus = "enabledLong" | "disabledLong" | "enabledShort" | "disabledShort";
const OVERNIGHTS_STATUS_OPTIONS: DropdownOption<OvernightsStatus>[] = [
    {
        text: "Enabled",
        value: "enabledLong",
    },
    {
        text: "Disabled",
        value: "disabledLong",
    },
    {
        text: "Enabled",
        value: "enabledShort",
    },
    {
        text: "Disabled",
        value: "disabledShort",
    },
];

export const OvernightsFilter = ({ control }: { control: Control<Filters> }) => {
    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                            <MultipleDropdown.TriggerEssence
                                {...trigger}
                                option={trigger.selectedOptions}
                                size="small"
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    options={OVERNIGHTS_STATUS_OPTIONS}
                    align="end"
                    caption="Overnights"
                    asFilter
                >
                    <MultipleDropdown.Sheet size="small">
                        {() => (
                            <>
                                <MultipleDropdown.EmptySearch>
                                    <MultipleDropdown.SheetGroup caption="Long" columns={1}>
                                        {OVERNIGHTS_STATUS_OPTIONS.slice(0, 2).map((opt) => (
                                            <MultipleDropdown.Option
                                                key={opt.value}
                                                value={opt.value}
                                            >
                                                <HStack spacing={8} alignItems="center">
                                                    {opt.text}
                                                </HStack>
                                            </MultipleDropdown.Option>
                                        ))}
                                    </MultipleDropdown.SheetGroup>
                                    <MultipleDropdown.SheetGroup caption="Short" columns={1}>
                                        {OVERNIGHTS_STATUS_OPTIONS.slice(2).map((opt) => (
                                            <MultipleDropdown.Option
                                                key={opt.value}
                                                value={opt.value}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {opt.text}
                                                </div>
                                            </MultipleDropdown.Option>
                                        ))}
                                    </MultipleDropdown.SheetGroup>
                                </MultipleDropdown.EmptySearch>
                            </>
                        )}
                    </MultipleDropdown.Sheet>
                </MultipleDropdown>
            )}
            name="overnightsFilter"
        />
    );
};
