import { noop, useModalControl } from "@fm-frontend/utils";
import { ROUTES } from "feature/app/router";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import { AccountStatusType, AddressSide, AddressType, AddressUrlParams, isAddressSide, isAddressType } from "./types";

type AddressesContextValue = {
    side: AddressSide;
    type: AddressType;
    setSide: (side: AddressSide) => void;
    setType: (type: AddressType) => void;
    setSelectedCps: (cpIds: number[]) => void;
    selectedCps: number[];
    setSelectedStatuses: (status: AccountStatusType[]) => void;
    selectedStatuses: AccountStatusType[];
    isLightBoxOpen: boolean;
    openLightBox: () => void;
    closeLightBox: () => void;
    cryptoNeedToConfirm: number;
    setCryptoNeedToConfirm: (count: number) => void;
    bankNeedToConfirm: number;
    setBankNeedToConfirm: (count: number) => void;
};

const DEFAULT_SIDE: AddressSide = "your";
const DEFAULT_TYPE: AddressType = AddressType.Crypto;
export const AddressesContext = createContext<AddressesContextValue>({
    side: DEFAULT_SIDE,
    type: DEFAULT_TYPE,
    setSide: noop,
    setType: noop,
    setSelectedCps: noop,
    selectedCps: [],
    setSelectedStatuses: noop,
    selectedStatuses: [],
    isLightBoxOpen: false,
    openLightBox: noop,
    closeLightBox: noop,
    cryptoNeedToConfirm: 0,
    setCryptoNeedToConfirm: noop,
    bankNeedToConfirm: 0,
    setBankNeedToConfirm: noop,
});

const addViewType = (path: string, viewType?: string) => (viewType ? `${path}${viewType}` : path);

export const useCurrentAddressPage = () => {
    const history = useHistory();
    const { side, type } = useParams<AddressUrlParams>();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();

    const subAccountUrlPart =
        primeBrokerViewType === "subaccounts" && isPrimeBrokerUser ? "?viewType=subaccounts" : undefined;

    useEffect(() => {
        if (isAddressSide(side) && isAddressType(type)) {
            return;
        }

        history.replace(addViewType(`${ROUTES.addresses}/${DEFAULT_SIDE}/${DEFAULT_TYPE}`, subAccountUrlPart));
    }, [history, side, type]);

    const setSide = (newSide: AddressSide) => {
        history.replace(addViewType(`${ROUTES.addresses}/${newSide}/${type}`, subAccountUrlPart));
    };

    const setType = (newType: AddressType) => {
        history.replace(addViewType(`${ROUTES.addresses}/${side}/${newType}`, subAccountUrlPart));
    };

    return useMemo(
        () => ({
            side: side || DEFAULT_SIDE,
            type: type || DEFAULT_TYPE,
            setSide,
            setType,
        }),
        [side, type, setSide, setType],
    );
};

export const useAddressContext = () => useContext(AddressesContext);

export const AddressesContextProvider: React.FC = ({ children }) => {
    const addressPageValue = useCurrentAddressPage();
    const { closeModal: closeLightBox, isModalOpen: isLightBoxOpen, openModal: openLightBox } = useModalControl();

    const [selectedCps, setSelectedCps] = useState<number[]>([]);
    const [selectedStatuses, setSelectedStatuses] = useState<AccountStatusType[]>([]);
    const [cryptoNeedToConfirm, setCryptoNeedToConfirm] = useState<number>(0);
    const [bankNeedToConfirm, setBankNeedToConfirm] = useState<number>(0);

    const addressContextValue = {
        ...addressPageValue,
        closeLightBox,
        openLightBox,
        isLightBoxOpen,
        selectedCps,
        setSelectedCps,
        selectedStatuses,
        setSelectedStatuses,
        cryptoNeedToConfirm,
        setCryptoNeedToConfirm,
        bankNeedToConfirm,
        setBankNeedToConfirm,
    };

    return <AddressesContext.Provider value={addressContextValue}>{children}</AddressesContext.Provider>;
};
