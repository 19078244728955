import {
    DiffLightbox,
    FIXED_MODAL_STYLE,
    HStack,
    Mark,
    Modal,
    P,
    PlainButton,
    PrimaryButton,
} from "@fm-frontend/uikit";
import { Gap } from "@fm-frontend/uikit/src/components/common/Gap";
import { useFileContext } from "@fm-frontend/uikit/src/components/dropZone/FileContext";
import {
    DiffDataResult,
    EmDash,
    useModalControl,
    ValueConvert,
    ValueFormat,
    ValueParse,
} from "@fm-frontend/utils";
import format from "date-fns/format";
import { createNotification } from "feature/app";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import {
    BulkService,
    BulkUpdateRequest,
    BulkUpdateResponse,
} from "../AssetsBulkEditModal/bulkService";
import { AssetsDiffTable } from "../AssetsBulkEditModal/components/AssetsDiffTable";
import {
    AssetsBulkPreparedDataType,
    AssetsImportType,
    isEmptyOrRestrictedValue,
    isShortSalesOn,
} from "../AssetsBulkEditModal/utils";
import { AssetsBulkEditResultModal } from "../AssetsBulkEditResultModal";

const prepareImportDataToApiData = (importItem: AssetsImportType): AssetsBulkPreparedDataType => ({
    asset: ValueConvert.normalize(importItem.asset),
    cpId: Number(importItem.cpId),
    shortSales: isEmptyOrRestrictedValue(importItem.shortSales)
        ? false
        : isShortSalesOn(importItem.shortSales),
    limitCurrency: isEmptyOrRestrictedValue(importItem.limitCurrency)
        ? undefined
        : ValueConvert.normalize(importItem.limitCurrency),
    limitAmount: isEmptyOrRestrictedValue(importItem.limitAmount)
        ? undefined
        : ValueParse.size(importItem.limitAmount),
});

type DiffLightBoxProps = {
    importData: AssetsImportType[];
    diffResult: DiffDataResult;
    diffTableColumns: string[];
    onClose: () => void;
    onBack: () => void;
};

type DiffLightBoxFooterInfoProps = {
    diffResult: DiffDataResult;
};

export const DiffLightBoxFooterInfo: FC<DiffLightBoxFooterInfoProps> = ({ diffResult }) => {
    const { colors } = useTheme();

    const updatedCellsCount = diffResult.diff.reduce((total, diffItem) => {
        const diffItemUpdatedCellCount = Object.values(diffItem).reduce(
            (totalPerItem, currentItem) => {
                if (currentItem.diff) {
                    totalPerItem++;
                }

                return totalPerItem;
            },
            0,
        );

        total += diffItemUpdatedCellCount;

        return total;
    }, 0);

    const errorCellsCount = diffResult.diff.reduce((total, diffItem) => {
        const diffItemErrorsCellCount = Object.values(diffItem).reduce(
            (totalPerItem, currentItem) => {
                totalPerItem += currentItem.errors.length;

                return totalPerItem;
            },
            0,
        );

        total += diffItemErrorsCellCount;

        return total;
    }, 0);

    if (diffResult.isLoading) {
        return <P>Loading...</P>;
    }

    if (diffResult.hasErrors) {
        return (
            <P>
                <Mark weight={600} color={colors.negative100}>
                    {errorCellsCount === 1
                        ? `${errorCellsCount} error. `
                        : `${errorCellsCount} errors. `}
                </Mark>
                Please verify and update CSV file.
            </P>
        );
    }

    if (diffResult.hasDiffs) {
        return (
            <P>
                <Mark weight={600} color={colors.positive100}>
                    {updatedCellsCount === 1
                        ? `${updatedCellsCount} update. `
                        : `${updatedCellsCount} updates. `}
                </Mark>
                Please verify and start config update.
            </P>
        );
    }

    return (
        <P>
            <strong>No changes found.</strong> This configuration is up to date.
        </P>
    );
};

const FileInfo = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;
const FileErrorInfo = styled(P)`
    color: ${(p) => p.theme.colors.negative100};
`;

export const AssetsBulkEditDiffLightBox: FC<DiffLightBoxProps> = ({
    importData,
    diffResult,
    diffTableColumns,
    onClose,
    onBack,
}) => {
    const dispatch = useDispatch();
    const [isApplyingUpdates, setIsApplyingUpdates] = useState<boolean>(false);
    const { acceptedFiles } = useFileContext();
    const fileName = acceptedFiles[0]?.name || EmDash;
    const fileInfo = `File size: ${ValueFormat.formatBytes(
        acceptedFiles[0]?.size ?? 0,
    )} Last changes: ${format(acceptedFiles[0]?.lastModified ?? 0, "d MMMM yyyy 'at' HH:mm")}`;

    const [bulkUpdateResults, setBulkUpdateResults] = useState<{
        response: BulkUpdateResponse[];
    }>({ response: [] });
    const { isModalOpen: isBulkUpdateResultModalOpened, openModal: openBulkUpdateResultModal } =
        useModalControl();
    const { closeModal: closeBulkUpdateLightbox, isModalOpen: isBulkUpdateLightboxOpened } =
        useModalControl(true);

    const handleBulkUpdateResult = async (result: { response: BulkUpdateResponse[] }) => {
        closeBulkUpdateLightbox();

        const hasErrors = result.response.some(({ errorCode }) => errorCode !== undefined);

        if (hasErrors) {
            setBulkUpdateResults(result);
            openBulkUpdateResultModal();
        } else {
            dispatch(
                createNotification({
                    type: "success",
                    content: "All changes were successfully applied.",
                }),
            );
            onClose();
        }
    };

    const handleSave = async () => {
        const updatedData = importData.reduce<BulkUpdateRequest[]>(
            (acc, importItem, currentIndex) => {
                const diffItem = diffResult.diff[currentIndex];

                if (!diffItem) {
                    return acc;
                }

                if (diffItem.shortSales?.diff) {
                    acc.push(
                        BulkService.mapBulkDataToShortSalesRequest(
                            prepareImportDataToApiData(importItem),
                        ),
                    );
                }
                if (diffItem.limitCurrency?.diff || diffItem.limitAmount?.diff) {
                    acc.push(
                        BulkService.mapBulkDataToAssetLimitRequest(
                            prepareImportDataToApiData(importItem),
                        ),
                    );
                }

                return acc;
            },
            [],
        );

        setIsApplyingUpdates(true);
        const updateResult = await BulkService.bulkUpdateAssets(updatedData);
        setIsApplyingUpdates(false);
        handleBulkUpdateResult(updateResult);
    };

    return (
        <>
            <DiffLightbox isOpen={isBulkUpdateLightboxOpened} onClose={onClose}>
                <DiffLightbox.Header
                    title={fileName}
                    essence={() =>
                        diffResult.hasErrors ? (
                            <FileErrorInfo>
                                Some cells contains wrong values. Overview the issues, correct the
                                initial file and upload again.
                            </FileErrorInfo>
                        ) : (
                            <FileInfo>{fileInfo}</FileInfo>
                        )
                    }
                />
                <DiffLightbox.Body>
                    <AssetsDiffTable
                        isLoading={diffResult.isLoading}
                        columns={diffTableColumns}
                        data={diffResult.diff}
                    />
                </DiffLightbox.Body>
                <DiffLightbox.Footer>
                    <DiffLightBoxFooterInfo diffResult={diffResult} />
                    <Gap />
                    <HStack spacing={6}>
                        <PlainButton size="medium" onClick={onBack}>
                            Cancel
                        </PlainButton>
                        {diffResult.hasDiffs && !diffResult.hasErrors && (
                            <PrimaryButton
                                disabled={diffResult.isLoading}
                                size="medium"
                                onClick={handleSave}
                                loading={isApplyingUpdates}
                            >
                                Update
                            </PrimaryButton>
                        )}
                        {!diffResult.hasDiffs && !diffResult.hasErrors && (
                            <PrimaryButton
                                disabled={diffResult.hasErrors || diffResult.isLoading}
                                size="medium"
                                onClick={onClose}
                                loading={isApplyingUpdates}
                            >
                                Continue
                            </PrimaryButton>
                        )}
                    </HStack>
                </DiffLightbox.Footer>
            </DiffLightbox>
            <Modal
                isOpen={isBulkUpdateResultModalOpened}
                onClose={onClose}
                style={FIXED_MODAL_STYLE}
            >
                <AssetsBulkEditResultModal
                    onClose={onClose}
                    result={bulkUpdateResults}
                    onUpdate={handleBulkUpdateResult}
                />
            </Modal>
        </>
    );
};
