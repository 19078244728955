import { toLongDateString } from "utils/format";
import { Settlement } from "../../../../../types";
import { Statistic } from "./Statistic";

export const TimestampItem = ({ settlement }: { settlement: Settlement }) => {
    let title;
    switch (settlement.status) {
        case "request":
            title = settlement.type === "in" ? "Received" : "Created";
            break;
        case "created":
            title = "Created";
            break;
        case "sent":
            title = "Sent";
            break;
        case "received":
            title = "Received";
            break;
        case "cancelled":
            title = "Cancelled";
            break;
        case "committed":
            title = "Committed";
            break;
    }

    const date = toLongDateString(settlement.lastActionAt);

    return <Statistic title={title} value={date} />;
};
