import {
    Flex,
    IconButton,
    Icons,
    Modal,
    PrimaryButton,
    Search,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useModalControl } from "@fm-frontend/utils";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { FIXED_MODAL_STYLE } from "const/modal";
import { useDelayedState, useInstruments } from "hooks";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { usePrimeBrokerViewType } from "store/hooks";
import styled from "styled-components";
import { AssetControlMenu } from "../../AssetControlMenu";
import { MarkupsSetupResultsModal } from "../MarkupsSetupResultsModal";
import { UpdateMarkupResponse } from "../types";
import { useWhitelistingManager } from "../useWhitelistingManager";
import { WhitelistingProvider } from "../whitelistingContext";
import { WhitelistingSwitch } from "../WhitelistingSwitch";
import {
    InstrumentMarkupInputs,
    InstrumentMarkupsSetupModal,
} from "./InstrumentMarkupsSetupModal/InstrumentMarkupsSetupModal";
import { getMarkupText, InstrumentsTable, SearchQueryProvider } from "./IntsrumentsTable";
import { useTableData } from "./IntsrumentsTable/hooks";
import { useNotMakerCpsIds } from "./IntsrumentsTable/hooks/useCounterpartiesWithTypes";
import { InstrumentTableData } from "./IntsrumentsTable/types";

const Container = styled(Flex)`
    overflow: auto;
    min-height: calc(100vh - 87px);

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const StyledSearch = styled(Search)`
    max-width: 160px;
`;

type OnOffValues = "enabled" | "disabled";
const INSTRUMENT_MARKUPS_OPTIONS: DropdownOption<OnOffValues>[] = [
    {
        text: "Enabled",
        value: "enabled",
    },
    {
        text: "Disabled",
        value: "disabled",
    },
];
const WHITELIST_OPTIONS: DropdownOption<OnOffValues>[] = [
    {
        text: "Whitelisted",
        value: "enabled",
    },
    {
        text: "Not listed",
        value: "disabled",
    },
];

export type SetupModalSettings = {
    defaultValues?: Partial<InstrumentMarkupInputs>;
    isParticularMarkup?: boolean;
};

type Filters = {
    cpFilter: number[];
    instrumentMarkupsFilter: OnOffValues[];
    whitelistFilter: OnOffValues[];
};
const DEFAULT_FILTER_VALUE: Filters = {
    cpFilter: [],
    instrumentMarkupsFilter: [],
    whitelistFilter: [],
};

const useFilteredInstrumentsData = ({
    searchQuery,
    instrumentsData,
    cpFilter,
    cpCount,
    instrumentMarkupsFilter,
    whitelistFilter,
    isWhitelistingOn,
    isInstrumentInWhitelist,
}: {
    searchQuery: string;
    instrumentsData: InstrumentTableData[];
    cpCount: number;
    isWhitelistingOn: boolean;
    isInstrumentInWhitelist: (instrumentName: string) => boolean;
} & Filters) => {
    const normalizedSearchQuery = searchQuery.toLocaleLowerCase().trim();

    return useMemo(() => {
        const isSearchQueryEmpty = normalizedSearchQuery === "";
        const isCpFilterEmpty = cpFilter.length === 0 || cpFilter.length === cpCount;
        const isInstrumentMarkupsFilterEmpty =
            instrumentMarkupsFilter.length === 0 ||
            instrumentMarkupsFilter.length === INSTRUMENT_MARKUPS_OPTIONS.length;
        const isWhitelistFilterEmpty =
            whitelistFilter.length === 0 || whitelistFilter.length === WHITELIST_OPTIONS.length;

        const isEmptyFilters =
            isSearchQueryEmpty &&
            isCpFilterEmpty &&
            isInstrumentMarkupsFilterEmpty &&
            isWhitelistFilterEmpty;
        if (isEmptyFilters) {
            return instrumentsData;
        }

        const cpFilterSet = new Set(cpFilter);
        const filteredInstrumentsData: InstrumentTableData[] = [];
        for (const instrumentData of instrumentsData) {
            const instrumentName = instrumentData.instrument.instrumentName;
            const instrumentNameMatched = instrumentName
                .toLocaleLowerCase()
                .includes(normalizedSearchQuery);
            if (
                !isSearchQueryEmpty &&
                instrumentNameMatched &&
                isCpFilterEmpty &&
                isInstrumentMarkupsFilterEmpty &&
                isWhitelistFilterEmpty
            ) {
                filteredInstrumentsData.push(instrumentData);
                continue;
            }

            if (!isWhitelistFilterEmpty && isWhitelistingOn) {
                if (
                    whitelistFilter[0] === "enabled"
                        ? !isInstrumentInWhitelist(instrumentName)
                        : isInstrumentInWhitelist(instrumentName)
                ) {
                    continue;
                }
            }

            const filteredCounterparties = instrumentData.counterparties.filter(
                ({ name, id, defaultMarkup, askMarkup, bidMarkup }) => {
                    const markupsFilterMatched =
                        isInstrumentMarkupsFilterEmpty ||
                        (instrumentMarkupsFilter[0] === "enabled"
                            ? bidMarkup !== undefined
                            : bidMarkup === undefined);

                    if (!markupsFilterMatched) return false;

                    const cpFilterMatched = isCpFilterEmpty || cpFilterSet.has(id);

                    if (!cpFilterMatched) return false;

                    const searchQueryFilterMatched =
                        isSearchQueryEmpty ||
                        instrumentNameMatched ||
                        [
                            name.toLocaleLowerCase(),
                            String(id),
                            ...[defaultMarkup, askMarkup, bidMarkup].map(getMarkupText),
                        ].some((str) => str.includes(normalizedSearchQuery));

                    return searchQueryFilterMatched;
                },
            );

            if (filteredCounterparties.length > 0) {
                filteredInstrumentsData.push({
                    ...instrumentData,
                    counterparties: filteredCounterparties,
                });
            }
        }

        return filteredInstrumentsData;
    }, [
        normalizedSearchQuery,
        cpFilter,
        instrumentsData,
        instrumentMarkupsFilter,
        whitelistFilter,
        isWhitelistingOn,
    ]);
};

const MakerMasterInstrumentsTabContentView = () => {
    const primeBrokerViewType = usePrimeBrokerViewType();
    const [delayedSearchQuery, setSearchQuery, searchQuery, setSearchQueryImmediately] =
        useDelayedState(750, "");
    const {
        isLoading: isInstrumentsDataLoading,
        instrumentsData,
        refetchInstrumentsData,
    } = useTableData();
    const whitelistManager = useWhitelistingManager();
    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm<Filters>({
        defaultValues: { ...DEFAULT_FILTER_VALUE },
    });
    const resetFilter = () => {
        reset({ ...DEFAULT_FILTER_VALUE });
        setSearchQueryImmediately("");
    };
    useEffect(() => {
        resetFilter();
    }, [primeBrokerViewType]);
    const cpFilter = watch("cpFilter");
    const instrumentMarkupsFilter = watch("instrumentMarkupsFilter");
    const whitelistFilter = watch("whitelistFilter");

    const cpFilterOptions: DropdownOption<number>[] = (
        instrumentsData[0]?.counterparties ?? []
    ).map(({ id, name }) => ({
        text: name,
        value: id,
    }));

    const filteredInstrumentsData = useFilteredInstrumentsData({
        searchQuery: delayedSearchQuery,
        instrumentsData,
        cpFilter,
        cpCount: cpFilterOptions.length,
        instrumentMarkupsFilter,
        whitelistFilter,
        isWhitelistingOn: whitelistManager.isWhitelistingOn,
        isInstrumentInWhitelist: whitelistManager.isInstrumentInWhitelist,
    });

    const {
        closeModal: closeMarkupsSetup,
        isModalOpen: isMarkupsSetupOpened,
        openModal: openMarkupsSetup,
    } = useModalControl();
    const initialSetupModalSettingsRef = useRef<SetupModalSettings>();
    const openInstrumentMarkupsSetupModal = useCallback(
        (setupModalSettings?: SetupModalSettings) => {
            initialSetupModalSettingsRef.current = setupModalSettings;
            openMarkupsSetup();
        },
        [openMarkupsSetup],
    );

    const {
        closeModal: closeMarkupsResult,
        isModalOpen: isMarkupsResultOpened,
        openModal: openMarkupsResult,
    } = useModalControl();
    const [markupsResult, setMarkupsResult] = useState<UpdateMarkupResponse[]>([]);

    const handleUpdateMarkups = async (result: UpdateMarkupResponse[]) => {
        if (result.some(({ errorCode }) => errorCode === undefined)) {
            await refetchInstrumentsData();
        }

        if (result.some(({ errorCode }) => errorCode !== undefined)) {
            setMarkupsResult(result);
            openMarkupsResult();
        }
    };

    const isNoCounterparties = !instrumentsData[0]?.counterparties.length;
    const configureButtonDisabled = isNoCounterparties || isInstrumentsDataLoading;
    const configureButtonTooltip = isNoCounterparties
        ? "There are no counterparties"
        : isInstrumentsDataLoading
        ? "Loading counterparties and instruments..."
        : "";

    const notMakerCpsIds = useNotMakerCpsIds();
    const { instruments } = useInstruments();

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <WhitelistingProvider value={whitelistManager}>
                <CardContainer flex={1} asCard minWidth="445px">
                    <Flex padding={12} spacing={6} alignItems="flex-start">
                        <AssetControlMenu />
                        <WhitelistingSwitch />
                        <Tooltip content={configureButtonTooltip}>
                            <PrimaryButton
                                size="small"
                                disabled={configureButtonDisabled}
                                onClick={() => {
                                    openInstrumentMarkupsSetupModal({
                                        defaultValues: {
                                            cps: notMakerCpsIds,
                                            instruments: instruments.map(
                                                ({ instrumentName }) => instrumentName,
                                            ),
                                        },
                                    });
                                }}
                            >
                                Configure
                            </PrimaryButton>
                        </Tooltip>
                    </Flex>
                    <Flex alignItems="flex-start" paddingX={12} paddingBottom={20} spacing={6}>
                        <StyledSearch
                            size="small"
                            placeholder="Search"
                            query={searchQuery}
                            onChange={(value) => setSearchQuery(value)}
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <CounterpartyTriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                noIcon
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={cpFilterOptions}
                                    align="end"
                                    caption="CP"
                                    asFilter
                                >
                                    <CounterpartiesSheet
                                        size="medium"
                                        options={cpFilterOptions}
                                        Dropdown={MultipleDropdown}
                                    />
                                </MultipleDropdown>
                            )}
                            name="cpFilter"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <MultipleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={INSTRUMENT_MARKUPS_OPTIONS}
                                    align="end"
                                    caption="Instrument markups"
                                    asFilter
                                >
                                    <MultipleDropdown.BasicSheet
                                        size="small"
                                        options={INSTRUMENT_MARKUPS_OPTIONS}
                                    />
                                </MultipleDropdown>
                            )}
                            name="instrumentMarkupsFilter"
                        />
                        {whitelistManager.isWhitelistingOn && (
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <MultipleDropdown
                                        values={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(trigger) => (
                                            <MultipleDropdown.Trigger
                                                {...trigger}
                                                size="small"
                                                variant="simple"
                                            >
                                                <MultipleDropdown.TriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOptions}
                                                    size="small"
                                                />
                                            </MultipleDropdown.Trigger>
                                        )}
                                        options={WHITELIST_OPTIONS}
                                        align="end"
                                        caption="Whitelist"
                                        asFilter
                                    >
                                        <MultipleDropdown.BasicSheet
                                            size="small"
                                            options={WHITELIST_OPTIONS}
                                        />
                                    </MultipleDropdown>
                                )}
                                name="whitelistFilter"
                            />
                        )}
                        {(isDirty || searchQuery.trim() !== "") && (
                            <Tooltip content="Reset filter" align="center">
                                <IconButton
                                    variant="plain"
                                    type="button"
                                    Icon={Icons.Recent}
                                    onClick={resetFilter}
                                />
                            </Tooltip>
                        )}
                    </Flex>

                    <SearchQueryProvider value={delayedSearchQuery}>
                        <InstrumentsTable
                            onEdit={openInstrumentMarkupsSetupModal}
                            instrumentsData={filteredInstrumentsData}
                            isLoading={isInstrumentsDataLoading}
                        />
                    </SearchQueryProvider>
                </CardContainer>
            </WhitelistingProvider>
            {isMarkupsSetupOpened && (
                <InstrumentMarkupsSetupModal
                    onClose={closeMarkupsSetup}
                    initialSetupModalSettings={initialSetupModalSettingsRef.current}
                    onUpdate={handleUpdateMarkups}
                    instrumentsData={instrumentsData}
                />
            )}
            <Modal
                isOpen={isMarkupsResultOpened}
                onClose={closeMarkupsResult}
                style={FIXED_MODAL_STYLE}
            >
                <MarkupsSetupResultsModal
                    onClose={closeMarkupsResult}
                    result={markupsResult}
                    onUpdate={handleUpdateMarkups}
                />
            </Modal>
        </Container>
    );
};

export const MakerMasterInstrumentsTabContent = memo(MakerMasterInstrumentsTabContentView);
