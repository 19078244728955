import { Form, FormActions, FormBody, FormInfo, FormProgress, PrimaryButton, ShadowCard } from "@fm-frontend/uikit";
import { Header as FormHeader } from "@fm-frontend/uikit/src/components/headers/Header";
import { HeaderTitle } from "@fm-frontend/uikit/src/components/headers/HeaderTitle";
import React, { FormEvent } from "react";
import { OnboardingMap, ONBOARDING_STEPS } from "store/onboardingSlice";
import styled from "styled-components";

export const FormImageContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const FormImageWrapper = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
`;

export const EnableTwoFA: React.FC<{ onNext: () => void }> = ({ onNext }) => {
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onNext();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormProgress
                currentStep={OnboardingMap[ONBOARDING_STEPS.ACCOUNT_ENABLE_TWO_FA].progress.current}
                totalSteps={OnboardingMap[ONBOARDING_STEPS.ACCOUNT_ENABLE_TWO_FA].progress.total}
            />
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Set two-factor authentication" />
                </FormHeader>
                <FormBody>
                    <FormInfo>
                        First, download <span>Google Authenticator</span> app from App Store or Google Play
                    </FormInfo>
                    <FormImageContainer>
                        <img src={"/img/authenticator.png"} width={120} height={120} alt={"Google Authenticator"} />
                    </FormImageContainer>
                    <FormImageContainer>
                        <FormImageWrapper>
                            <a
                                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={"/img/app-store.png"} height={54} alt={"App Store"} />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={"/img/google-play.png"} height={54} alt={"Google Play"} />
                            </a>
                        </FormImageWrapper>
                    </FormImageContainer>
                    <FormInfo>
                        If Google Authenticator or another app with the same functionality is already installed, please
                        continue
                    </FormInfo>
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton fullWidth size="large">
                        Continue
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};
