import {
    BasicInput,
    Form,
    FormAttention,
    FormBody,
    FormInfo,
    LinkButton,
    P,
    PrimaryButton,
    ShadowCard,
} from "@fm-frontend/uikit";
import { FormActions } from "@fm-frontend/uikit/src/components/forms/FormActions";
import { FormCard } from "@fm-frontend/uikit/src/components/forms/FormCard";
import { FormProgress } from "@fm-frontend/uikit/src/components/forms/FormProgress";
import { Header as FormHeader } from "@fm-frontend/uikit/src/components/headers/Header";
import { HeaderTitle } from "@fm-frontend/uikit/src/components/headers/HeaderTitle";
import { AUTH_ERRORS } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CognitoUser } from "amazon-cognito-identity-js";
import { IconError } from "components/icons";
import { LinkPrivacyPolicy } from "components/Links";
import { authSlice } from "feature/auth";
import { SignUpQueryParams } from "feature/auth/useSignupQueryParams";
import { useFormHelpers } from "hooks";
import { useLogin, useSignUp } from "hooks/useAws";
import { useSettings } from "hooks/useSettings";
import { useStepper } from "hooks/useStepper";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { OnboardingMap, ONBOARDING_STEPS } from "store/onboardingSlice";
import { ClientData, InviteToken } from "types";
import { displayError, when } from "utils";
import { decodeInviteJwtToken } from "utils/decodeInviteJwtToken";
import { ConfirmTwoFA } from "./ConfirmTwoFA";
import { EnableTwoFA } from "./EnableTwoFA";
import { accountDataSchema } from "./schemas";

export interface Inputs {
    companyName: string;
    password: string;
    confirmPassword: string;
}

export enum AccountDataSteps {
    AccountData,
    EnableMfa,
    ConfirmMfa,
}

export const AccountData: React.FC<SignUpQueryParams> = ({ invite }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const signUp = useSignUp();
    const login = useLogin();
    const { error, setError } = useFormHelpers();
    const { currentStep, next, previous, goToStep } = useStepper(AccountDataSteps.AccountData);
    const [user, setUser] = useState<CognitoUser | undefined>();
    const [email, setEmail] = useState<string | undefined>();
    const [doubleSignUpError, setDoubleSignUpError] = useState(false);
    const decodeInviteToken: InviteToken | undefined = useMemo(() => decodeInviteJwtToken(invite), [invite]);
    const settings = useSettings();

    const {
        register,
        formState: { errors, isSubmitting },
        setValue,
        handleSubmit,
    } = useForm<Inputs>({
        defaultValues: {},
        mode: "onSubmit",
        resolver: yupResolver(accountDataSchema),
    });

    useEffect(() => {
        if (!decodeInviteToken) {
            return;
        }

        try {
            setEmail(decodeInviteToken.email);
            setValue("companyName", decodeInviteToken.name);

            if (decodeInviteToken.type) {
                dispatch(
                    authSlice.actions.setClientData({
                        clientType: decodeInviteToken.type,
                        primeBrokerId: decodeInviteToken.primeBrokerId,
                    } as ClientData),
                );
            }
        } catch (error) {
            displayError(error);
        }
    }, [decodeInviteToken]);

    const submit = async (data: Inputs) => {
        if (!invite || !email) {
            return;
        }

        const { error: signUpError, errorCode } = await signUp(invite, data.password, data.companyName);
        setDoubleSignUpError(errorCode === AUTH_ERRORS.UsernameExistsException);

        if (signUpError) {
            setError(signUpError);
            return;
        }

        setError(null);

        const { user: loginUser, error: loginError } = await login(email, data.password);

        if (loginError) {
            setError(loginError?.message);
            return;
        }

        setUser(loginUser);
        goToStep(AccountDataSteps.EnableMfa);
    };

    return (
        [
            <Form onSubmit={handleSubmit(submit)}>
                <FormProgress
                    currentStep={OnboardingMap[ONBOARDING_STEPS.ACCOUNT_DATA].progress.current}
                    totalSteps={OnboardingMap[ONBOARDING_STEPS.ACCOUNT_DATA].progress.total}
                />
                <ShadowCard>
                    <FormHeader>
                        <HeaderTitle title="Account data" />
                    </FormHeader>
                    <FormBody>
                        <FormCard>
                            <FormCard.Content>
                                <FormCard.Caption>Email</FormCard.Caption>
                                <FormCard.Essence>{email}</FormCard.Essence>
                            </FormCard.Content>
                            {!decodeInviteToken?.allowChangeInvitedUsername && (
                                <FormCard.Content>
                                    <FormCard.Caption>Display name</FormCard.Caption>
                                    <FormCard.Essence>{decodeInviteToken?.name}</FormCard.Essence>
                                </FormCard.Content>
                            )}
                        </FormCard>
                        {decodeInviteToken?.allowChangeInvitedUsername && (
                            <BasicInput
                                label="Your company name"
                                placeholder="Company Name"
                                hint="Use a brand name of your company for other users to see. Max 15 symbols"
                                {...register("companyName")}
                                error={errors.companyName?.message}
                            />
                        )}

                        <BasicInput
                            type="password"
                            label="Create password"
                            hint="Password must be at least 10 characters, contain one uppercase and one digit"
                            placeholder="Password"
                            {...register("password")}
                            error={errors.password?.message}
                        />
                        <BasicInput
                            type="password"
                            label="Repeat password"
                            placeholder="Repeat Password"
                            {...register("confirmPassword")}
                            error={errors.confirmPassword?.message}
                        />
                        {error && (
                            <FormAttention>
                                <IconError />
                                <P>
                                    {error}{" "}
                                    {when(
                                        doubleSignUpError,
                                        <LinkButton onClick={() => history.push("/login")} type="button" size="small">
                                            Login
                                        </LinkButton>,
                                    )}
                                </P>
                            </FormAttention>
                        )}
                        {settings.privacy_policy_link && (
                            <FormInfo>
                                The obtained data is processed in accordance with our <LinkPrivacyPolicy />
                            </FormInfo>
                        )}
                        {!settings.privacy_policy_link && (
                            <FormInfo>
                                The obtained data will be processed by {settings.display_name} in accordance with client
                                agreement
                            </FormInfo>
                        )}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton type="submit" fullWidth size="large" loading={isSubmitting}>
                            Continue
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>,
            <EnableTwoFA onNext={next} />,
            <ConfirmTwoFA onNext={next} onBack={previous} email={email} user={user} />,
        ][currentStep] || null
    );
};
