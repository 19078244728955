import { HStack, PBold } from "@fm-frontend/uikit";
import { InstrumentWhitelistingSwitch } from "feature/assetsControl/components/InstrumentsTabContent/InstrumentWhitelistingSwitch";
import { useWhitelistingContext } from "feature/assetsControl/components/InstrumentsTabContent/whitelistingContext";
import { Instrument } from "hooks/useInstruments";
import styled from "styled-components";
import { InstrumentIcon } from "../../../InstrumentIcon";

const Container = styled(HStack)<{ $isWhitelistingOn: boolean; $enabled: boolean }>`
    white-space: nowrap;
    flex-wrap: nowrap;
    height: 100%;

    align-items: center;
    padding: 0 12px;

    background-color: ${(p) => (p.$isWhitelistingOn && !p.$enabled ? p.theme.colors.negative4 : "transparent")};
`;

const InstrumentContainer = styled(HStack)<{ $isWhitelistingOn: boolean; $enabled: boolean }>`
    gap: 6px;
    opacity: ${(p) => (p.$isWhitelistingOn && !p.$enabled ? 0.52 : 1)};
`;

const StyledInstrumentWhitelistingSwitch = styled(InstrumentWhitelistingSwitch)`
    margin-left: auto;
`;

export const InstrumentNameCell = ({ instrument }: { instrument: Instrument }) => {
    const { instrumentName } = instrument;
    const { isWhitelistingOn, isInstrumentInWhitelist } = useWhitelistingContext();
    const isWhitelisted = isInstrumentInWhitelist(instrumentName);

    return (
        <Container $isWhitelistingOn={isWhitelistingOn} $enabled={isWhitelisted}>
            <InstrumentContainer $isWhitelistingOn={isWhitelistingOn} $enabled={isWhitelisted}>
                <InstrumentIcon {...instrument} />
                <PBold>{instrumentName}</PBold>
            </InstrumentContainer>
            <StyledInstrumentWhitelistingSwitch instrumentName={instrumentName} />
        </Container>
    );
};
