import { PrimaryButton } from "@fm-frontend/uikit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Copyright } from "components/Copyright";
import { FormInputError } from "feature/form/style";
import { FormConfig, useForm } from "feature/form/useForm";
import { useAwsAuth } from "hooks/useAws";
import { setUser } from "../index";
import { BottomLogin, FormRow, HeadTitle, SlimWidget, Text } from "../loginPage.styled";
import { ChallengeName } from "../types";
import { Props } from "./mfaPage.types";

export const MFAPage: React.FC<Props> = (props) => {
    const { credentials, user } = props;
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const mfaForm: FormConfig = {
        fields: {
            code: { placeholder: "000000", type: "2faCode" },
        },
        implicitValues: props.onEnterCode ? undefined : credentials,
        url: "login",
    };
    const { propsFor, globalError, areActionsDisabled, parsedValues, values } = useForm(mfaForm);
    const { confirmMfa, verifyTotpToken, ensureUser } = useAwsAuth(user);

    const history = useHistory();

    const handleClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (props.onEnterCode) {
            props.onEnterCode(parsedValues.code as string);
            return;
        }
        let error: string | null = null;
        setLoading(true);

        if (user?.challengeName === ChallengeName.MFA_SETUP) {
            error = await verifyTotpToken(String(values.code));
        } else if (user?.challengeName === ChallengeName.SOFTWARE_TOKEN_MFA) {
            const data = await confirmMfa(String(values.code), ChallengeName.SOFTWARE_TOKEN_MFA);
            error = data.error;
        }

        error = error ? error : await ensureUser();

        setLoading(false);

        if (error) {
            setError(error);
            return;
        }
        setError(null);
        dispatch(setUser(user?.signInUserSession));
        history.push("/");
    };

    return (
        <>
            <SlimWidget>
                <HeadTitle>Two-factor authentication</HeadTitle>
                <form>
                    <Text>Please enter the 6-digit code from the app</Text>
                    <FormRow>
                        <input name="code" type="number" autoComplete="off" autoFocus {...propsFor("code")} required />
                    </FormRow>
                    <FormRow>
                        {globalError}
                        {error ? <FormInputError isCentered>{error}</FormInputError> : null}
                        <PrimaryButton
                            size="large"
                            fullWidth
                            type="submit"
                            disabled={areActionsDisabled || String(values.code).length !== 6}
                            onClick={handleClick}
                            loading={isLoading}
                        >
                            Login
                        </PrimaryButton>
                    </FormRow>
                </form>
            </SlimWidget>
            {props.isInline ? null : (
                <BottomLogin>
                    <a
                        href="#"
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            props.onBack();
                        }}
                    >
                        Back
                    </a>
                    <span>
                        <Copyright />
                    </span>
                </BottomLogin>
            )}
        </>
    );
};
