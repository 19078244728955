import { getMatch } from "@fm-frontend/uikit";
import { ReactNode } from "react";
import { CounterpartyLimitByAsset } from "../../../../types";
import { parseLimitByAsset } from "../../utils";

export interface LimitByAssetCurrency {
    asset: string;
    grossLimit: bigint | null;
    limitCurrency?: string | null;
    title: string;
}

const sortFunc = (left: LimitByAssetCurrency, right: LimitByAssetCurrency) => {
    return left.grossLimit === null && right.grossLimit !== null
        ? 1
        : left.grossLimit !== null && right.grossLimit === null
        ? -1
        : left.title.localeCompare(right.title);
};

export const mapLimitsByAssetsToCurrency = (
    currencies: { text: string; value: string }[],
    limitsByAssets: CounterpartyLimitByAsset[],
) => {
    return currencies
        .map(({ value, text }) => {
            const limitByAsset = limitsByAssets?.find(([, asset]) => asset === value);

            const result: LimitByAssetCurrency = {
                asset: value,
                grossLimit: null,
                title: text,
                limitCurrency: value,
            };

            if (limitByAsset) {
                const { grossLimit, limitCurrency } = parseLimitByAsset(limitByAsset);
                result.grossLimit = grossLimit;
                result.limitCurrency = limitCurrency;
            }

            return result;
        })
        .sort(sortFunc);
};

export const searchByAsset = (query: string, mappedLimitsByAssets: LimitByAssetCurrency[]) => {
    const normalizedQuery = query.toLowerCase().trim();

    return mappedLimitsByAssets.reduce<{ asset: string; title: string; match: ReactNode; grossLimit: bigint | null }[]>(
        (acc, { asset, title, grossLimit }) => {
            const match = getMatch(asset, normalizedQuery);

            if (match) {
                acc.push({ title, match, asset, grossLimit });
            }

            return acc;
        },
        [],
    );
};
