import { CurrencyType, EmDash } from "@fm-frontend/utils";
import { AccountStatusType, AddressGroup, AddressSide, BankAddress, CpRevisionHash, CryptoAddress } from "./types";

export type MakeOptional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

const CURRENCIES_WITH_MEMO_OR_TAG = ["XRP", "XLM", "CRO", "EOS"];
export const CRYPTO_CURRENCY_TYPES: CurrencyType[] = ["crypto", "stablecoin"];
export const BANK_CURRENCY_TYPES: CurrencyType[] = ["fiat"];

export const LIGHTBOX_SCROLL_CONTAINER = "lightbox-scroll-container";
export const PRIVATE_NOTE_HINT_TEXT =
    "A private note to simplify navigation and address management. For example, specify the issuing exchange or any other useful specification.";

export const ADDRESSES_COLLECTION_NAME = "addresses";
export const OTHER_DETAILS_PLACEHOLDER =
    "• Transfer method (SEPA, SWIFT, Bacs, CHAPS, Faster Payments)\n" +
    "• Routing\n" +
    "• Sort code\n" +
    "• Internal account number\n" +
    "• Bank reference";
export const PUBLIC_DETAILS_PLACEHOLDER = "Public description, payment instructions";
export const EDITING_UNAVAILABLE_MESSAGE = "Editing is unavailable due to the lack of permissions";
export const CONFIRMATION_UNAVAILABLE_MESSAGE = "Confirmation is unavailable due to the lack of permissions";

export const ALL_ADDRESS_INFO_COPIED_TEXT = "All address information has been successfully copied.";
export const ALL_ACCOUNT_INFO_COPIED_TEXT = "All account information has been successfully copied.";
export const NETWORKS_OPTION_SHEET_INFO = "Choosing the wrong network may lead to the loss of funds";
export const ADDRESSES_ARE_SAVED_NOTIFICATION = "Addresses are saved";
export const ADDRESS_IS_SAVED_NOTIFICATION = "Address is saved";
export const ACCOUNTS_ARE_SAVED_NOTIFICATION = "Accounts are saved";
export const ACCOUNT_IS_SAVED_NOTIFICATION = "Account is saved";

export const SAB_CRYPTO_EDIT_MODAL_KEY = Symbol();
export const SAB_CRYPTO_LIGHTBOX_KEY = Symbol();
export const SAB_BANK_EDIT_MODAL_KEY = Symbol();
export const SAB_BANK_LIGHTBOX_KEY = Symbol();

export const hasCurrencyMemoOrTag = (currency: string | null | undefined) =>
    Boolean(currency && CURRENCIES_WITH_MEMO_OR_TAG.includes(currency));

export function handleInnerFormSubmit(callback: () => void) {
    return (e: { stopPropagation: () => void; preventDefault: () => void }) => {
        e.stopPropagation();
        callback();
        e.preventDefault();
    };
}

export const parseCryptoAddress = ({
    rule,
    currency,
    network,
    wallet,
    privateNote,
    memoOrTag,
    accountId,
}: CryptoAddress) => {
    return {
        accountId: accountId,
        cpName: rule?.cp?.name,
        currency: currency?.name,
        cpId: rule?.cp?.id,
        network: network?.name,
        wallet,
        privateNote,
        memoOrTag,
    };
};

export const isAddressGroupType = <TAddress>(value: any): value is AddressGroup<TAddress> => {
    if (!value) {
        return false;
    }

    return "count" in value;
};

export const getAccountStatus = (side: AddressSide, revisions: CpRevisionHash[] = []) => {
    const revisionHashes = revisions.filter(({ revisionHash }) => revisionHash !== undefined);

    if (side === "your") {
        return revisionHashes.length ? AccountStatusType.Approved : AccountStatusType.Verification;
    }
    if (side === "shared") {
        return revisionHashes.length ? AccountStatusType.NeedToConfirm : AccountStatusType.Approved;
    }

    throw new Error("Invalid side value");
};

export const isBankAccountType = (value: any): value is BankAddress => {
    if (!value) {
        return false;
    }

    return "bankPrimary" in value;
};

export const hasCryptoAddressDuplicate = (
    allAddresses: CryptoAddress[],
    newOrUpdatedAddresses: CryptoAddress[],
    currentAddress: CryptoAddress,
): boolean => {
    const { rule: currentRule, currency: currentCurrency, accountId: currentAccountId } = currentAddress;
    const currentCP = currentRule?.cp;

    const duplicateInNew = newOrUpdatedAddresses.filter(({ rule, currency }) => {
        let cpIdMatch = false;
        let currencyIdMatch = false;

        if (rule?.cp?.id && currentCP?.id) {
            cpIdMatch = rule.cp.id === currentCP.id;
        }
        if (currency?.id && currentCurrency?.id) {
            currencyIdMatch = currency.id === currentCurrency.id;
        }

        return cpIdMatch && currencyIdMatch;
    });

    if (duplicateInNew.length > 1) {
        return true;
    }

    const duplicateInAll = allAddresses.find(({ rule, currency }) => {
        return rule?.cp?.id === currentCP?.id && currency?.id === currentCurrency?.id;
    });

    if (duplicateInAll === undefined) {
        return false;
    }

    return currentAccountId !== duplicateInAll?.accountId;
};

export const filterAddresses = <TAddress extends CryptoAddress | BankAddress>(
    addresses: TAddress[],
    selectedCps: number[],
    selectedStatuses: AccountStatusType[],
) => {
    return addresses.filter((a) => {
        const cpStagePass = selectedCps.length && a.rule?.cp?.id ? selectedCps.includes(a.rule?.cp?.id) : true;
        const statusStagePass = selectedStatuses.length ? selectedStatuses.includes(a.status) : true;

        return cpStagePass && statusStagePass;
    });
};

export const emptyStringSubstitution = (value: string | undefined | null, substitution: string = EmDash) =>
    Boolean(value) ? value : substitution;
