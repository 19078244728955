import { H3, PlainButton } from "@fm-frontend/uikit";
import { useBanksAddresses } from "feature/addresses/hooks/useBanksAddresses";
import { ROUTES } from "feature/app/router";
import { useHistory } from "react-router";
import styled from "styled-components";
import { BankAccounts } from "../../components/BankAccounts";
import { Empty, GroupContent, HintsHeader, Loading } from "../styled";
import { HintsLayout } from "./HintsLayout";

const Actions = styled.div`
    margin-top: auto;
    text-align: center;
`;

export const BankAccountHints = ({ asset }: { asset: string }) => {
    const history = useHistory();
    const { data: bankAccounts, isLoading } = useBanksAddresses();

    if (isLoading) {
        return (
            <HintsLayout>
                <Loading />
            </HintsLayout>
        );
    }

    const filteredBankAccounts = bankAccounts.filter(({ currency }) => currency?.name === asset);

    return (
        <HintsLayout>
            <HintsHeader>
                <H3>Your accounts</H3>
            </HintsHeader>
            <GroupContent>
                {filteredBankAccounts.length === 0 ? (
                    <Empty message="No information provided" />
                ) : (
                    <BankAccounts bankAccounts={filteredBankAccounts} />
                )}
            </GroupContent>
            <Actions>
                <PlainButton size="small" onClick={() => history.push(`${ROUTES.addresses}/your/bank`)}>
                    Edit your bank accounts to receive fiat
                </PlainButton>
            </Actions>
        </HintsLayout>
    );
};
