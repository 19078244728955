import { H2, HStack, P, Table, VStack } from "@fm-frontend/uikit";
import { getCoreRowModel, getSortedRowModel, SortingState } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import { useIsMakerUser, useIsTakerUser } from "store/hooks";
import { useTheme } from "styled-components";
import { Card, Content } from "../styled";
import { withSettingsLayout } from "../withSettingsLayout";
import { AddNewTakerButton } from "./AddNewTakerButton";
import { InvitesTableProps, MakerInvitesType } from "./types";
import { useInvitesList } from "./useInvitesList";
import { useOnboardingStatuses } from "./useOnboardingStatuses";
import { referralsTableColumns } from "./utils";

const InvitesTable: React.FC<InvitesTableProps<MakerInvitesType>> = ({ data, isLoading }) => {
    const [sorting, setSorting] = useState<SortingState>([]);

    const tableOptions = {
        data,
        columns: referralsTableColumns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    };

    return (
        <VStack width="100%">
            <Table
                tableOptions={tableOptions}
                isLoading={isLoading}
                selectedRows={{}}
                paginator={{
                    pageSize: 10,
                }}
                onInitialize={(table) => {
                    table.setSorting([{ id: "invitation", desc: true }]);
                }}
            />
        </VStack>
    );
};

const SettingsReferralsView = () => {
    const { colors } = useTheme();
    const isMakerUser = useIsMakerUser();
    const isTakerUser = useIsTakerUser();
    const { onboardingStatuses } = useOnboardingStatuses();
    const { invitesList, isLoading } = useInvitesList();

    const memoData: MakerInvitesType[] = useMemo(() => {
        if (Array.isArray(invitesList)) {
            return invitesList.map((invite) => {
                return {
                    ...invite,
                    onboardingStatus: onboardingStatuses.find(
                        ({ taker_id }) => taker_id === invite.userId,
                    )?.status,
                } as MakerInvitesType;
            });
        }

        return [];
    }, [invitesList, onboardingStatuses]);

    return (
        <Content>
            <Card>
                <VStack spacing={20}>
                    <HStack width="100%" justifyContent="space-between">
                        <H2>Referrals</H2>
                        <AddNewTakerButton />
                    </HStack>
                    {isMakerUser && (
                        <P color={colors.ui52}>
                            Invite new takers to the platform and engage in trading with them
                        </P>
                    )}
                    {isTakerUser && <P color={colors.ui52}>Invite new companies to the platform</P>}
                    <InvitesTable data={memoData} isLoading={isLoading} />
                </VStack>
            </Card>
        </Content>
    );
};

export const SettingsReferrals = withSettingsLayout(SettingsReferralsView);
