import { HStack, Icons, TextSmall } from "@fm-frontend/uikit";
import { isCurrencyType } from "@fm-frontend/utils";
import { CoinIcon } from "components/CoinIcon";
import { MatchedPart } from "components/select/SearchSelect/style";
import React from "react";
import styled, { useTheme } from "styled-components";

const Container = styled(HStack)`
    min-height: 44px;
    justify-content: space-between;
    background-color: ${(p) => p.theme.colors.ui8};

    border-radius: 8px;
    cursor: pointer;

    :hover {
        background-color: ${(p) => p.theme.colors.ui12};
    }
`;

export const Asset: React.FC<{
    asset: string;
    text: string;
    match: React.ReactNode;
    onSelect: (assets: string) => void;
}> = ({ asset, text, match, onSelect }) => {
    const { colors } = useTheme();

    return (
        <Container data-field-group onClick={() => onSelect(asset)}>
            <HStack spacing={8} alignItems="center" paddingLeft={12}>
                {isCurrencyType(asset) || asset === "all" ? (
                    <TextSmall color={colors.ui72}>
                        Configure <b>{text.toLowerCase()}</b>
                    </TextSmall>
                ) : (
                    <>
                        <CoinIcon coin={asset} />
                        {match ? <MatchedPart>{match}</MatchedPart> : <TextSmall>{text}</TextSmall>}
                    </>
                )}
            </HStack>
            <HStack alignItems="center" paddingRight={18}>
                <Icons.ArrowRight />
            </HStack>
        </Container>
    );
};
