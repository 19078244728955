import { useFormShouldCloseConfirm } from "@fm-frontend/utils";
import { useCallback, useState } from "react";
import { ModalDialog } from "../../components/modal/Modal";
import { PasswordForm } from "../../feature/auth/form";
import { RESET_PASSWORD_MODAL_KEY } from "../../feature/auth/EnableMFAPage/utils";
import { SmallButton } from "../../feature/form/Buttons";
import { useModalCloseWithConfirm } from "../../hooks/useModalCloseWithConfirm";
import { useItemTransition } from "../../hooks/useTransition";
import { Card, CenteredContent, Row, Title } from "./styled";
import { withSettingsLayout } from "./withSettingsLayout";

const SettingsSecurityView = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { isActive, transitionState } = useItemTransition(isOpen);
    const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

    const shouldConfirmClose = useFormShouldCloseConfirm(RESET_PASSWORD_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldConfirmClose, closeModal);

    return (
        <>
            <CenteredContent>
                <Card>
                    <Title>Login data</Title>
                    <Row>
                        <div>Password</div>
                        <span>
                            <SmallButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsOpen(true);
                                }}
                            >
                                Change
                            </SmallButton>
                        </span>
                    </Row>
                </Card>
            </CenteredContent>
            {isActive && (
                <ModalDialog isOpen isDismissable size="small" onClose={closeModalWithConfirm} transitionState={transitionState}>
                    <PasswordForm isInline onSuccess={closeModal} />
                </ModalDialog>
            )}
        </>
    );
};

export const SettingsSecurity = withSettingsLayout(SettingsSecurityView);
