import { isSubaccountCp } from "feature/counterparties/utils";
import { useUserType } from "store/hooks";
import { MakerMasterInstrumentsTabContent } from "./MakerMasterInstrumentsTabContent";
import { SubaccountInstrumentsTabContent } from "./SubaccountInstrumentsTabContent";
import { TakerInstrumentsTabContent } from "./TakerInstrumentsTabContent";

export const InstrumentsTabContent = () => {
    const userType = useUserType();

    if (isSubaccountCp(userType)) {
        return <SubaccountInstrumentsTabContent />;
    }

    switch (userType) {
        case "maker":
        case "primeBroker":
            return <MakerMasterInstrumentsTabContent />;
        case "taker":
            return <TakerInstrumentsTabContent />;
        default:
            return null;
    }
};
