import { H1 } from "@fm-frontend/uikit";
import React from "react";
import { Header, Layout } from "../common";
import { AccountData } from "./AccountData";

export const AccountFlow: React.FC<{ invite?: string }> = ({ invite }) => {
    return (
        <Layout>
            <Header>
                <H1>Create account</H1>
            </Header>
            <AccountData invite={invite} />
        </Layout>
    );
};
