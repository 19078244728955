import { onMobile } from "@fm-frontend/uikit/src/const";
import styled, { css } from "styled-components";

export const LayoutContainer = styled.main<{ isOverlayEnabled: boolean; primeBrokerSubaccountsView?: boolean }>`
    display: flex;
    flex-wrap: wrap;

    min-width: 1024px;

    ${onMobile} {
        min-width: auto;
    }

    ${({ isOverlayEnabled }) =>
        isOverlayEnabled &&
        css`
            height: 100vh;
            overflow: hidden;
        `}
    ${({ primeBrokerSubaccountsView, theme }) =>
        primeBrokerSubaccountsView &&
        css`
            background: ${theme.customGradients.subBg};
        `}
`;
export const MainContent = styled.div`
    flex-basis: 0;
    flex-grow: 999;

    min-width: 812px;

    ${onMobile} {
        min-width: auto;
    }
`;

export const FormImageContainer = styled.div`
    padding-top: 8px;
    display: flex;
    justify-content: center;
`;

export const FormImageWrapper = styled.div`
    width: 100%;

    padding: 48px 0 8px 0;
    display: flex;
    justify-content: space-between;
`;

export const FormButtonWrapper = styled.div`
    padding-top: 8px;
`;
