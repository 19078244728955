import { PSBold } from "feature/inboxPopover/styled";
import styled from "styled-components";

type Variant = "active" | "pending" | "disabled";
const PillContainer = styled.div<{ variant: Variant }>`
    display: inline-flex;
    padding: 6px 8px;
    border-radius: 12px;
    background-color: ${(p) =>
        p.variant === "active"
            ? p.theme.colors.brand8
            : p.variant === "pending"
            ? p.theme.colors.process8
            : p.theme.colors.ui8};
`;

const PillTitle = styled(PSBold)<{ variant: Variant }>`
    line-height: 12px;
    color: ${(p) =>
        p.variant === "active"
            ? p.theme.colors.brand100
            : p.variant === "pending"
            ? p.theme.colors.process100
            : p.theme.colors.ui100};
`;
const STATUS_TITLES = {
    active: "Active",
    pending: "Pending",
    disabled: "Disabled",
};
export const Status = ({ variant }: { variant: Variant }) => (
    <PillContainer variant={variant}>
        <PillTitle variant={variant}>{STATUS_TITLES[variant]}</PillTitle>
    </PillContainer>
);
