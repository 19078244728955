import { ISignUpResult } from "amazon-cognito-identity-js";
import { post } from "api";
import { Auth } from "aws-amplify";
import { setUser } from "feature/auth";
import { ChallengeName } from "feature/auth/types";
import { useDispatch } from "react-redux";
import { InviteToken } from "types";
import { displayError } from "utils";
import { decodeInviteJwtToken } from "utils/decodeInviteJwtToken";

export const useLogin = () => {
    const login = async (email: string, password: string): Promise<{ user: any; error: Error | null }> => {
        let userCognito = null;
        let errorLogin: Error | null = null;

        try {
            userCognito = await Auth.signIn(email, password);
        } catch (error) {
            if (error instanceof Error) {
                errorLogin = error;
            }
        }

        return { user: userCognito, error: errorLogin };
    };

    return login;
};

export const useSignUp = () => {
    const signUp = async (
        invite: string,
        password: string,
        username: string,
    ): Promise<{ signUpData: ISignUpResult | null; error: string | null; errorCode: string | null }> => {
        let signUpData: ISignUpResult | null = null;
        let error = null;
        const inviteToken: InviteToken | undefined = decodeInviteJwtToken(invite);
        let errorCode: string | null = null;

        if (inviteToken === undefined) {
            return { signUpData: null, error: "No invite token", errorCode: null };
        }

        try {
            signUpData = await Auth.signUp({
                username: inviteToken.email,
                password,
                attributes: {
                    email: inviteToken.email,
                    "custom:inviteId": inviteToken.id,
                    "custom:fm_username": username ?? "",
                },
                clientMetadata: {
                    invite: invite,
                },
            });
        } catch (e: any) {
            if (e instanceof Error) {
                error = e?.message;
            }

            errorCode = e?.code ?? null;
        }

        return { signUpData, error, errorCode };
    };

    return signUp;
};

export const useAwsAuth = (user: any) => {
    const dispatch = useDispatch();

    let error: string | null = null;
    let token: any = null;

    const confirmMfa = async (code: string, mfaType: ChallengeName.SOFTWARE_TOKEN_MFA | null | undefined) => {
        try {
            token = await Auth.confirmSignIn(user, code, mfaType);
        } catch (errorConfirm) {
            if (errorConfirm instanceof Error) {
                error = errorConfirm?.message;
                return { error };
            }
        }

        try {
            await Auth.currentAuthenticatedUser({ bypassCache: true });
        } catch (errorConfirm) {
            if (errorConfirm instanceof Error) {
                error = errorConfirm?.message;
                console.error(errorConfirm?.message);
            }
        }

        return { token, error, user };
    };

    const verifyTotpToken = async (code: string) => {
        let error = null;

        try {
            await Auth.verifyTotpToken(user, code);
            await Auth.setPreferredMFA(user, "TOTP");
        } catch (e) {
            if (e instanceof Error) {
                error = e?.message;
            }
        }

        return error;
    };

    const ensureUser = async (count = 3): Promise<string | null> => {
        try {
            const currentSession = await Auth.currentSession();

            const data = await post("ensureUser", {
                accessToken: user?.signInUserSession?.accessToken?.jwtToken,
            });

            if (data.tokenRefreshNeeded) {
                // @ts-ignore
                user?.refreshSession(currentSession.refreshToken, (err) => {
                    if (err) {
                        console.error(err);
                        return err;
                    }
                });
            }
        } catch (e) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            count--;
            if (count > 0) {
                return await ensureUser(count);
            }
            if (e instanceof Error) {
                return e?.message;
            }
            return String(e);
        }
        return null;
    };

    // step 2 signup
    const dbSignUp = async (name: string | undefined): Promise<string | null> => {
        let error: string | null = null;
        try {
            await post("signup", {
                key: user?.signInUserSession?.accessToken?.jwtToken,
                name: name,
            });
        } catch (e) {
            if (e instanceof Error) {
                error = e?.message;
            }
            displayError(e);
        }

        if (!error) {
            dispatch(setUser(user?.signInUserSession));
        }

        return error;
    };

    return { confirmMfa, ensureUser, dbSignUp, verifyTotpToken };
};
