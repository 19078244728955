import { subDays } from "date-fns";

export type InviteStatus = "Accepted" | "Sent" | "Expired";
export type OnboardingStatus = "LP choosing" | "LP verification" | "Joined";
export type Invite = [
    email: string,
    name: string,
    expire: number,
    userId: number,
    status: InviteStatus,
];
const INVITE_EXPIRATION_DAYS = 30;
export const prepareInvite = (invite: Invite) => ({
    email: invite[0],
    name: invite[1],
    invitation: subDays(invite[2], INVITE_EXPIRATION_DAYS).getTime(),
    userId: invite[3],
    status: invite[4],
});
export type PreparedInvite = ReturnType<typeof prepareInvite>;
export type AliasStatus = Extract<InviteStatus, "Sent">;
export type MakerInvitesType = {
    email: string;
    name: string;
    invitation: number;
    userId: number;
    status: InviteStatus;
    onboardingStatus?: OnboardingStatus;
};
export type OnboardingStatusType = { taker_id: number; status: OnboardingStatus | null };
export type InvitesTableProps<T> = {
    data: T[];
    isLoading: boolean;
};
