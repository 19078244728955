import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { ValidationResult, ValueConvert } from "@fm-frontend/utils";
import { UserPermissions } from "hooks/useActionPermissions";
import { Currency } from "hooks/useInstruments";
import { AssetsGroupType, CpGroupType } from "../../types";

export const ALL_ASSETS_OPTION: DropdownOption<AssetsGroupType> = {
    text: "All Assets",
    value: AssetsGroupType.All,
};

export const GROUPING_ASSETS_OPTIONS: DropdownOption<AssetsGroupType>[] = [
    { text: "All Crypto", value: AssetsGroupType.Crypto },
    { text: "All Stablecoins", value: AssetsGroupType.Stablecoin },
    { text: "All Fiat", value: AssetsGroupType.Fiat },
];

export const ALL_GP_OPTION: DropdownOption<CpGroupType> = {
    text: "All сounterparties",
    value: "all",
};

export type AssetsBulkPreparedDataType = {
    asset: string;
    cpId: number;
    shortSales: boolean;
    limitCurrency: string | undefined;
    limitAmount: bigint | undefined;
};

export const CSV_COLUMNS_TO_DATA_TYPE: Record<string, keyof AssetsOriginalDataType> = {
    Asset: "asset",
    "CP ID": "cpId",
    "CP type": "cpType",
    "CP name (read only)": "cpName",
    "Short sales (ON/BAN)": "shortSales",
    "Limit currency": "limitCurrency",
    "Limit amount (e.g. 100005.54)": "limitAmount",
};

export const REQUIRED_CSV_COLUMNS: Record<
    keyof Pick<AssetsOriginalDataType, "asset" | "cpId">,
    string
> = {
    asset: "Asset",
    cpId: "CP ID",
};
export const DATA_TYPE_TO_CSV_COLUMNS: Record<keyof AssetsOriginalDataType, string> = {
    asset: "Asset",
    cpId: "CP ID",
    cpType: "CP type",
    cpName: "CP name (read only)",
    shortSales: "Short sales (ON/BAN)",
    limitCurrency: "Limit currency",
    limitAmount: "Limit amount (e.g. 100005.54)",
};

export const DATA_TYPE_TO_DIFF_TABLE_COLUMNS: Record<keyof AssetsOriginalDataType, string> = {
    asset: "Asset",
    cpId: "CP ID",
    cpType: "CP type",
    cpName: "CP name",
    shortSales: "Short sales",
    limitCurrency: "Limit currency",
    limitAmount: "Limit amount",
};

export const LIMIT_HEADERS_PAIR = ["limitCurrency", "limitAmount"] as Array<
    keyof AssetsOriginalDataType
>;

export const CSV_VALIDATION_ERROR_MESSAGES = {
    get fileMissed() {
        return "File missed";
    },
    get invalidCharacters() {
        return "There are invalid characters in one or more cells of the CSV file";
    },
    missingRequiredColumns(requiredColumns: string[]) {
        return `The required columns in the CSV file are missed. Required columns are: ${requiredColumns.join(
            ", ",
        )}`;
    },
    get headersMismatchFormat() {
        return "The headers in the CSV file do not match the expected format";
    },
};

export const getAssetsDiffTableColumnName = (name: string) => {
    return DATA_TYPE_TO_DIFF_TABLE_COLUMNS[name as keyof AssetsOriginalDataType];
};

export const capitalizeFirstLetter = (input: string): string => {
    if (input.length === 0) {
        return input;
    }

    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export type AssetsImportType = {
    asset: string;
    cpId: string;
    cpType: string;
    cpName: string;
    shortSales: string;
    limitCurrency: string;
    limitAmount: string;
};

export type AssetsOriginalDataType = {
    asset: string;
    cpId: number;
    cpType: string;
    cpName: string;
    shortSales: boolean;
    limitCurrency: string | undefined;
    limitAmount: bigint | undefined;
};
export type ValidationContext = {
    originalValues: AssetsOriginalDataType[];
    availableAssets: string[];
    currencies: { [p: string]: Currency };
    actionsPermissions: UserPermissions;
};

export const EMPTY_VALUE = "NOT SET";
export const RESTRICTED_VALUE = "NOT APPLICABLE";
export const RESTRICTED_DISPLAY_VALUE = "n/a";
export const DEFAULT_SHORT_SALES_VALUE = true;
export const DEFAULT_SHORT_SALES_DISPLAY_VALUE = "On";

export const isEmptyOrRestrictedValue = (value: string | undefined) => {
    return (
        value === "" ||
        value === undefined ||
        ValueConvert.normalize(value) === EMPTY_VALUE ||
        ValueConvert.normalize(value) === RESTRICTED_VALUE
    );
};
export const isRestrictedValue = (value: string | undefined) => {
    return value?.toUpperCase() === RESTRICTED_VALUE;
};
export const isEmptyValue = (value: string | undefined) => {
    return value?.toUpperCase() === EMPTY_VALUE;
};
export const isNotEmptyAndRestrictedValue = (value: string | undefined) => {
    return !isEmptyOrRestrictedValue(value);
};

export const isShortSalesOn = (value: string | undefined) => {
    return value?.toUpperCase() === "ON";
};
export const formatShortSales = (value: boolean | undefined) => {
    return value ? "On" : "Ban";
};

export const setValueOrRestricted = (isAllowedToAdjust: boolean, value: string | undefined) => {
    return isAllowedToAdjust ? value : RESTRICTED_VALUE;
};

export const emptyOrRestrictedValueAssetsImportValidator = (
    value: string,
    cb: () => ValidationResult,
) => {
    if (isEmptyOrRestrictedValue(value)) {
        return {
            isValid: true,
            errors: [],
        };
    }

    return cb();
};
