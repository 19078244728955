import { TextSmall } from "@fm-frontend/uikit";
import React from "react";
import { useIsSubaccountUser } from "store/hooks";
import styled from "styled-components";
import { CounterpartyLimitByAsset } from "types";
import { ReactComponent as LimitIcon } from "./LimitIcon.svg";

const Container = styled.div<{ $isSet: boolean; onClick: (() => void) | undefined }>`
    display: flex;
    cursor: ${(p) => (p.onClick ? "pointer" : "default")};
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: flex-start;

    ${TextSmall} {
        margin: 0;
        color: ${(p) => (p.$isSet ? p.theme.colors.ui72 : p.theme.colors.ui32)};

        :hover {
            color: ${(p) => p.onClick && p.theme.colors.ui100};
        }
    }
`;

const Limit = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const LimitByAssetPreview: React.FC<{
    onClick: () => void;
    limitsByAsset: CounterpartyLimitByAsset[];
}> = (props) => {
    const { onClick, limitsByAsset = [] } = props;
    const isSubaccountUser = useIsSubaccountUser();
    const handleClick = isSubaccountUser ? undefined : onClick;
    const limitsCount = limitsByAsset.length;

    if (limitsCount === 0) {
        return (
            <Container $isSet={false} onClick={handleClick}>
                <TextSmall>No limits per asset</TextSmall>
            </Container>
        );
    }

    return (
        <Container $isSet={true} onClick={handleClick}>
            <Limit>
                <LimitIcon />
                <TextSmall>
                    Limits per {limitsCount} {limitsCount === 1 ? "asset" : "assets"}
                </TextSmall>
            </Limit>
        </Container>
    );
};
