import { post } from "api";
import { createNotification, setConfirmModalContent } from "feature/app/store";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import useSWR, { useSWRConfig } from "swr";
import { ScopedMutator } from "swr/_internal";
import { displayError } from "utils";

export const SUBACCOUNTS_URL = "getSubaccounts";

export interface Subaccount {
    info: {
        clientId?: number;
        username: string;
        email: string;

        activatedAt?: number;
        lastTradeAt?: number;
        signedAt?: number;
        legalName?: string;
        signatoryName?: string;
        signatoryPosition?: string;
        signed?: boolean;
        signTnc: boolean;
    };
    status: "active" | "pending" | "disabled";
    createdAt: number;
    type: "maker" | "taker";
}

const fetcher = (url: string) => post(url, {});
const useFetchData = (url: string) => {
    const { data, isLoading } = useSWR<Subaccount[]>(url, fetcher, {
        onError: displayError,
    });

    return { data, isLoading };
};

const collator = new Intl.Collator();
const STATUS_WEIGHTS: { [Key in Subaccount["status"]]: number } = {
    active: 3,
    pending: 2,
    disabled: 1,
};
const sortSubaccounts = (subaccounts: Subaccount[]) =>
    subaccounts.sort(({ status: s1, info: { username: u1 } }, { status: s2, info: { username: u2 } }) => {
        if (s1 === s2) {
            return collator.compare(u1, u2);
        }

        return STATUS_WEIGHTS[s2] - STATUS_WEIGHTS[s1];
    });

export const useSubaccounts = () => {
    const { data, isLoading } = useFetchData(SUBACCOUNTS_URL);

    return { subaccounts: sortSubaccounts(data ?? []), isLoading: isLoading };
};

export interface NewSubaccount {
    type: "maker" | "taker";
    email: string;
    name: string;
    limits?: {
        grossLimit: bigint;
        currency: string;
        markup: number;
        margins: {
            initialMargin: number;
            maintenanceMargin: number;
            restrictedTrading: number;
        };
    };
}

const withRefetch = (promise: Promise<unknown>, mutate: ScopedMutator) => promise.then(() => mutate(SUBACCOUNTS_URL));

export const useCreateSubaccount = () => {
    const { mutate } = useSWRConfig();

    return (subaccount: NewSubaccount) => withRefetch(post("sendSubaccountInvite", subaccount), mutate);
};

const CompanyName = styled.span`
    color: ${(p) => p.theme.colors.brand100};
`;
const SecondaryText = styled.span`
    color: ${(p) => p.theme.colors.ui52};
`;

export const useDisableSubaccount = () => {
    const { mutate } = useSWRConfig();
    const dispatch = useDispatch();

    return (
        subaccountId: Subaccount["info"]["clientId"],
        subaccountName: Subaccount["info"]["username"],
        onDisableSubaccount: (sending: boolean, successful: boolean) => void,
    ) =>
        dispatch(
            setConfirmModalContent({
                title: (
                    <>
                        Disable the <CompanyName>{subaccountName}</CompanyName>
                    </>
                ),
                onConfirm: async () => {
                    try {
                        onDisableSubaccount(true, false);
                        await withRefetch(post("disableSubaccount", { id: subaccountId }), mutate);
                        onDisableSubaccount(false, true);

                        dispatch(
                            createNotification({
                                type: "success",
                                content: "Subaccount has been disabled",
                            }),
                        );
                    } catch (e) {
                        onDisableSubaccount(false, false);

                        dispatch(
                            createNotification({
                                type: "error",
                                content: String(e),
                            }),
                        );
                    }
                },
                description: (
                    <>
                        This action cannot be undone. Do you want to disable the sub-account? <br />
                        <br />
                        <SecondaryText>
                            Note: Trading data will be archived. For reactivating the sub-account, contact support.
                        </SecondaryText>
                    </>
                ),
                confirmButtonTitle: "Yes, disable",
            }),
        );
};
