import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { post } from "api";
import { useCallback, useEffect, useMemo } from "react";
import { usePositionsSnapshot, usePrimeBrokerViewType } from "store/hooks";
import useSWR from "swr";
import { CounterpartyLimitByAsset } from "types";
import { displayError } from "utils";

export const usePositionsMap = () => {
    const { data: positions, isLoading } = usePositionsSnapshot();

    const positionsMap = useMemo(() => {
        return positions.reduce<Record<number, Record<string, bigint>>>(
            (acc, [currencyName, position, cpId]) => {
                acc[cpId] = acc[cpId] ?? {};
                acc[cpId][currencyName] = BigInt(position);
                return acc;
            },
            {},
        );
    }, [positions]);

    return {
        positionsMap,
        isLoading,
    };
};

export const useLimitsMap = () => {
    const brokerViewType = usePrimeBrokerViewType();
    const apiMethod = brokerViewType === "subaccounts" ? "subaccountsAssetLimits" : "cAssetLimits";
    const fetcher = useCallback(() => post(apiMethod, {}), [apiMethod]);
    const { data, isLoading } = useSWR(apiMethod, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY,
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    const limitsMap = useMemo(() => {
        return data.reduce(
            (
                acc: any,
                [
                    cpId,
                    asset,
                    grossLimit,
                    limitCurrency,
                    cpGrossLimit,
                    cpLimitCurrency,
                ]: CounterpartyLimitByAsset,
            ) => {
                acc[cpId] = acc[cpId] ?? {};
                acc[cpId][asset] = {
                    grossLimit: grossLimit !== null ? BigInt(grossLimit) : null,
                    limitCurrency,
                    cpGrossLimit:
                        cpGrossLimit !== null && cpGrossLimit !== undefined
                            ? BigInt(cpGrossLimit)
                            : null,
                    cpLimitCurrency,
                };
                return acc;
            },
            {},
        ) as Record<
            number,
            Record<
                string,
                {
                    grossLimit: bigint | null;
                    limitCurrency: string | null;
                    cpGrossLimit: bigint | null;
                    cpLimitCurrency: string | null;
                }
            >
        >;
    }, [data]);

    return {
        limitsMap,
        isLoading,
    };
};

export const use2WayBinding = <TValue = unknown>(
    value1: TValue,
    setValue1: (val: TValue) => void,
    value2: TValue,
    setValue2: (val: TValue) => void,
) => {
    useEffect(() => {
        if (String(value1) !== String(value2)) {
            setValue2(value1);
        }
    }, [value1]);
    useEffect(() => {
        if (String(value1) !== String(value2)) {
            setValue1(value2);
        }
    }, [value2]);
};
