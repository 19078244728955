import { OnboardingLayout } from "pages/onboarding/Onboarding";
import { Redirect } from "react-router";
import { ONBOARDING_STEPS } from "store/onboardingSlice";
import { AccountFlow } from "../../onboarding";
import { useSignupQueryParams } from "../useSignupQueryParams";

export const SignUpFlow = () => {
    const { invite } = useSignupQueryParams();

    if (!invite) {
        return <Redirect to="/login" />;
    }

    return (
        <OnboardingLayout currentStep={ONBOARDING_STEPS.ACCOUNT_DATA}>
            <AccountFlow invite={invite} />
        </OnboardingLayout>
    );
};
