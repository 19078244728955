import { VFC } from "react";
import { MainTabsContextProvider, useSelectedAssetControlPage } from "./AssetControlMenu";
import { AssetsTabContent } from "./AssetsTabContent";
import { InstrumentsTabContent } from "./InstrumentsTabContent";

export const ContentView: VFC = () => {
    const selectedPage = useSelectedAssetControlPage();

    return selectedPage === "assets" ? <AssetsTabContent /> : <InstrumentsTabContent />;
};

export const Content = () => (
    <MainTabsContextProvider>
        <ContentView />
    </MainTabsContextProvider>
);
