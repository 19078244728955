import { LinkLogout, LinkSettingsNotifications, LinkSettingsProfile } from "components/Links";
import { useSelector } from "hooks";

export const AdditionalMobileMenuOptions = () => {
    const isMobile = useSelector((state) => state.app.isMobile);
    const username = useSelector((state) => state.auth.username);

    if (!isMobile) {
        return null;
    }

    return (
        <>
            <div>{username}</div>
            <LinkSettingsProfile />
            <LinkSettingsNotifications />
            <LinkLogout />
        </>
    );
};
