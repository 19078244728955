import { PrimaryButton } from "@fm-frontend/uikit";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Copyright } from "../../components/Copyright";
import { LinkResetPassword, LinkSupport } from "../../components/Links";
import { useLogin } from "../../hooks/useAws";
import { kInvalidPassword, messages } from "../../utils/ErrorCodes";
import { getPasswordField } from "../form/fields";
import { FormInputError } from "../form/style";
import { FormConfig, useForm } from "../form/useForm";
import { BottomLogin, ButtonContainer, FormRow, HeadTitle, SlimWidget } from "./loginPage.styled";
import { ChallengeName } from "./types";

const RESET_REQUIRED = "UserNotFoundException";
const INCORRECT_LOGIN = "username or password";
const PASSWORD_RESET_REQUIRED_EXCEPTION = "PasswordResetRequiredException";

const loginForm: FormConfig = {
    fields: {
        email: { placeholder: "trader@example.com", type: "email" },
        password: getPasswordField({ autoComplete: "current-password" }),
        code: { placeholder: "000000" },
    },
    url: "login",
    transformError: (error) => {
        if (error === messages[5]) {
            return (
                <>
                    {error}
                    <ButtonContainer>
                        <Link to="/resetpassword">Forgot it?</Link>
                    </ButtonContainer>
                </>
            );
        }
        return error;
    },
};

export const LoginPage: React.FC<{
    onEnterMFACode: (values: Record<string, unknown>) => any;
    onEnableMFA: (values: Record<string, unknown>, email?: string) => any;
    onConfirmMfa: (user?: any) => any;
    onResetPassword: (user: any, email: string) => any;
}> = ({ onEnterMFACode, onResetPassword, onEnableMFA }) => {
    const [cognitoError, setCognitoError] = useState<string | null>();
    const { propsFor, globalError, areActionsDisabled, values, inputProps, parsedValues } = useForm(loginForm);
    // TODO: replace with ui-kit
    const [isLoading, setLoading] = useState(false);
    const login = useLogin();
    const history = useHistory();

    const emailError = inputProps.email.errorValue;
    const passwordError = inputProps.password.errorValue;
    const isForgot = cognitoError?.includes(INCORRECT_LOGIN) ?? false;

    const onClickHandler = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (String(values.password).length === 0) return;
        setLoading(true);
        let { user, error } = await login(String(values.email), String(values.password));
        setLoading(false);

        // for migrate cognito
        if (error?.name === RESET_REQUIRED) {
            Auth.configure({
                authenticationFlowType: "USER_PASSWORD_AUTH",
            });

            const auth = await login(String(values.email), String(values.password));
            user = auth.user;
            error = auth.error;
            Auth.configure({
                authenticationFlowType: "USER_SRP_AUTH",
            });
        }

        // for migrate cognito
        if (error?.name === PASSWORD_RESET_REQUIRED_EXCEPTION) {
            Auth.forgotPassword(String(parsedValues.email), { password: String(values.password) })
                .then(() => {
                    history.push({
                        pathname: `/resetpassword/${String(parsedValues.email)}`,
                    });
                })
                .catch((error) => {
                    setCognitoError(error?.message);
                });
            return;
        }

        // for migrate cognito
        if (error?.name === RESET_REQUIRED) {
            setCognitoError(messages[kInvalidPassword]);
            return;
        }

        if (error) {
            setCognitoError(error?.message);
            return;
        }

        if (user?.challengeName === ChallengeName.MFA_SETUP) {
            onEnableMFA(user, String(values.email));
            return;
        }

        if (user.challengeName === ChallengeName.NEW_PASSWORD_REQUIRED) {
            onResetPassword(user, String(values.email));
            return;
        }
        onEnterMFACode(user);
    };

    return (
        <>
            <SlimWidget>
                <HeadTitle>Log In</HeadTitle>
                <form>
                    <FormRow>
                        <label htmlFor="email">Email</label>
                        <input name="email" {...propsFor("email")} required />
                        {emailError && <FormInputError>{emailError}</FormInputError>}
                    </FormRow>
                    <FormRow>
                        <label htmlFor="password">Password</label>
                        <input name="password" type="password" {...propsFor("password")} required />
                        {passwordError && <FormInputError>{passwordError}</FormInputError>}
                    </FormRow>
                    <FormRow>
                        {globalError}
                        <FormInputError isCentered>{cognitoError}</FormInputError>
                        {isForgot ? (
                            <ButtonContainer>
                                <Link to="/resetpassword">Forgot password?</Link>
                            </ButtonContainer>
                        ) : null}
                        <PrimaryButton
                            fullWidth
                            size="large"
                            type="submit"
                            disabled={areActionsDisabled}
                            onClick={onClickHandler}
                            loading={isLoading}
                        >
                            Login
                        </PrimaryButton>
                    </FormRow>
                </form>
            </SlimWidget>
            <BottomLogin>
                <LinkSupport />
                <LinkResetPassword />
                <span>
                    <Copyright />
                </span>
            </BottomLogin>
        </>
    );
};
