import { HStack, Skeleton, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";

export const HintsContainer = styled(VStack)`
    background: ${(p) => p.theme.colors.ui4};
    padding: 12px 4px;
    width: 280px;
    height: 580px;
`;

export const HintsHeader = styled.div`
    padding: 6px 0;
    margin: 0 8px 4px 8px;
    border-bottom: 1px solid ${(p) => p.theme.colors.ui20};
    color: ${(p) => p.theme.colors.ui72};
`;

export const IconContainer = styled.span``;

export const HintContainer = styled(HStack)`
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding: 5px 14px 5px 8px;

    ${IconContainer} {
        opacity: 0;
    }

    :hover {
        background: ${(p) => p.theme.colors.ui4};
        cursor: pointer;

        ${IconContainer} {
            opacity: 1;
        }
    }
`;

export const HintContent = styled(VStack)`
    align-items: space-between;
`;

export const Amount = styled.div`
    color: ${(p) => p.theme.colors.ui72};
    font-size: 10px;
    line-height: 16px;

    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
`;

export const AssetAmount = styled.div`
    white-space: nowrap;
`;

export const UsdAmount = styled.div`
    white-space: nowrap;
`;

export const GroupContent = styled(VStack)`
    overflow-y: auto;
    flex: 1;
    gap: 2px;
`;

export const Loading = () => (
    <VStack spacing={4} width="100%" paddingX={8}>
        <Skeleton />
        <Skeleton />
    </VStack>
);

const EmptyContainer = styled(HStack)`
    color: ${(p) => p.theme.colors.ui52};
`;
export const Empty = ({ message }: { message: string }) => (
    <EmptyContainer width="100%" justifyContent="center" paddingTop={27}>
        {message}
    </EmptyContainer>
);
