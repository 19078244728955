import { LocalFile, parse, ParseResult, unparse } from "papaparse";
import { CSV_SEPARATOR, CSV_SEPARATOR_ADDITIONAL } from "../const";

export type DataKey = string;
export type DataValue = number | bigint | string | boolean | undefined | null;
export type ExportData = { [key: DataKey]: DataValue };
export type ImportData = { [key: DataKey]: string };

export type DiffType = {
    diff: boolean;
    oldValue: string | undefined;
    newValue: string | undefined;
    formatValue?: (value: string | undefined) => string;
    errors: string[];
};
export type DiffDataType = { [key: string]: DiffType };
export type DiffDataResult = {
    hasErrors: boolean;
    hasDiffs: boolean;
    isLoading: boolean;
    diff: DiffDataType[];
};

type ParseToCsvOptions = Partial<{
    header: boolean;
}>;
export const parseToCsv = (data: ExportData[], options?: ParseToCsvOptions): string => {
    return unparse(data, {
        header: options?.header,
        delimiter: CSV_SEPARATOR,
    });
};

export type ValidationResult = {
    isValid: boolean;
    errors: string[];
};
export type Validator<T extends ImportData> = Record<
    keyof T,
    (value: string, row: T, rows: T[]) => ValidationResult
>;
export type ComparisonResult = {
    diff: boolean;
    oldValue: string | undefined;
    newValue: string | undefined;
};
export type Comparator<T extends ImportData> = Record<
    keyof T,
    (value: string, row: T, isValid: boolean) => ComparisonResult
>;

export const validateData = async <T extends ImportData>(
    rows: ImportData[],
    validator: Validator<T>,
    comparator: Comparator<T>,
): Promise<DiffDataResult> => {
    const diff: DiffDataType[] = [];
    let hasErrors = false;
    let hasDiffs = false;

    for (const row of rows) {
        const result: Partial<DiffDataType> = {};

        Object.entries(row).forEach(([key, value]) => {
            const castedKey = key as keyof T;

            let validationResult: ValidationResult = {
                isValid: true,
                errors: [],
            };

            if (validator[castedKey] !== undefined) {
                validationResult = validator[castedKey](value, row, rows);
            }

            if (!hasErrors && !validationResult.isValid) {
                hasErrors = true;
            }

            let comparisonResult: ComparisonResult = {
                diff: false,
                oldValue: undefined,
                newValue: undefined,
            };

            if (comparator[castedKey] !== undefined) {
                comparisonResult = comparator[castedKey](value, row as T, validationResult.isValid);
            }

            if (!hasDiffs && comparisonResult.diff) {
                hasDiffs = true;
            }

            result[key] = {
                ...validationResult,
                ...comparisonResult,
            } as DiffType;
        });

        diff.push(result as DiffDataType);
    }

    return {
        hasErrors,
        hasDiffs,
        isLoading: false,
        diff,
    };
};

type ParseToJsonOptions = Partial<{
    header: boolean;
    transformHeader: (header: string, index: number) => string;
}>;
export const parseToJson = async <T extends ImportData>(
    source: LocalFile | string,
    options?: ParseToJsonOptions,
): Promise<ParseResult<T>> => {
    return new Promise((resolve, reject) => {
        parse(source, {
            complete: (results: ParseResult<T>) => {
                resolve(results);
            },
            error: (error: Error) => {
                reject(error);
            },
            delimitersToGuess: [CSV_SEPARATOR, CSV_SEPARATOR_ADDITIONAL],
            skipEmptyLines: true,
            header: options?.header,
            transformHeader: options?.transformHeader,
        });
    });
};
