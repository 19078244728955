import { P, PBold as FQALink, VStack } from "@fm-frontend/uikit";
import { InfoBox } from "components/InfoBox";
import { LinkFAQBulkEdit } from "components/Links";
import { FC } from "react";
import styled from "styled-components";

const Block = styled(VStack)`
    gap: 4px;
`;

const Title = styled(P)`
    color: ${(p) => p.theme.colors.ui100};
`;

const UnorderedList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding-left: 12px;
`;
const ListItem = styled.li`
    color: ${(p) => p.theme.colors.ui72};
    font-size: 12px;
    line-height: 16px;

    ::marker {
        color: ${(p) => p.theme.colors.ui20};
    }
`;

export const BulkEditModalHint: FC = () => {
    return (
        <InfoBox
            title={
                <P>
                    How <span>Bulk edit</span> works?
                </P>
            }
        >
            <Title>Make mass changes easily to the configuration using spreadsheet upload.</Title>
            <Block>
                <Title>Step 1. Create new template</Title>
                <UnorderedList>
                    <ListItem>
                        Choose the counterparties, assets, and adjustments you want to edit.
                    </ListItem>
                    <ListItem>
                        Download the CSV template with your current configuration data.
                    </ListItem>
                </UnorderedList>
            </Block>
            <Block>
                <Title>Step 2. Edit the CSV</Title>
                <UnorderedList>
                    <ListItem>
                        Open the downloaded CSV file in your preferred spreadsheet application.
                    </ListItem>
                    <ListItem>
                        Modify the data as needed, using tooltips in the top cells for guidance on
                        data formats.
                    </ListItem>
                    <ListItem>Save the file once all changes are made.</ListItem>
                </UnorderedList>
            </Block>
            <Block>
                <Title>Step 3. Import and verification</Title>
                <UnorderedList>
                    <ListItem>Go to “Import config” and upload edited CSV file.</ListItem>
                    <ListItem>
                        If errors are found, you will be notified with options for correction.
                    </ListItem>
                    <ListItem>Once the file is error-free, you can apply all changes.</ListItem>
                </UnorderedList>
            </Block>
            <FQALink>
                <LinkFAQBulkEdit />
            </FQALink>
        </InfoBox>
    );
};
