import {
    ACTIONS_TABLE_COLUMN_KEY,
    EXPAND_TABLE_COLUMN_KEY,
    IconButton,
    Icons,
    InfiniteTable,
} from "@fm-frontend/uikit";
import {
    ColumnDef,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    Row,
} from "@tanstack/react-table";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { memo, useMemo, useState } from "react";
import styled from "styled-components";
import { columnHelper, columns } from "./table";
import { InstrumentTableData, RowData } from "./types";
import { isInstrumentRow } from "./utils";

const StyledBottomFixTableContainer = styled(BottomFixTableContainer)`
    display: flex;
    flex-direction: column;
`;

const TakerInstrumentsTableView = ({
    isLoading,
    instrumentsData,
}: {
    isLoading: boolean;
    instrumentsData: RowData[];
}) => {
    const expandColumn = useMemo(
        () =>
            columnHelper.display({
                id: EXPAND_TABLE_COLUMN_KEY,
                header: ({ table }) => {
                    const isAllRowsExpanded = table.getIsAllRowsExpanded();

                    return (
                        <IconButton
                            variant="plain"
                            Icon={isAllRowsExpanded ? Icons.CollapseAll : Icons.ExpandAll}
                            onClick={table.getToggleAllRowsExpandedHandler()}
                        />
                    );
                },
                cell: ({ row }) => {
                    return !row.getCanExpand() ? null : row.getIsExpanded() ? (
                        <Icons.Collapse />
                    ) : (
                        <Icons.Expand />
                    );
                },
                meta: {
                    cellStyleProps: {
                        width: "32px",
                        paddingRight: "4px",
                        textAlign: "center",
                    },
                    headerStyleProps: {
                        textAlign: "center",
                    },
                },
            }),
        [],
    );
    const memoizedColumns = useMemo(() => [expandColumn, ...columns], [expandColumn]);
    const [expanded, setExpanded] = useState<ExpandedState>(true);
    const handleRowClick = (row: Row<RowData>, id: string) => {
        if (id === ACTIONS_TABLE_COLUMN_KEY) {
            return;
        }

        const isExpandable = row.getCanExpand();

        if (isExpandable) {
            row.toggleExpanded();
        }
    };

    return (
        <StyledBottomFixTableContainer>
            <InfiniteTable
                tableOptions={{
                    data: instrumentsData,
                    columns: memoizedColumns as ColumnDef<RowData, any>[],
                    state: {
                        expanded,
                    },
                    onExpandedChange: setExpanded,
                    getSubRows: (row) =>
                        isInstrumentRow(row)
                            ? (row.counterparties as unknown as InstrumentTableData[])
                            : undefined,
                    getCoreRowModel: getCoreRowModel(),
                    getExpandedRowModel: getExpandedRowModel(),
                }}
                onRowClick={handleRowClick}
                isLoading={isLoading}
            />
        </StyledBottomFixTableContainer>
    );
};

export const TakerInstrumentsTable = memo(TakerInstrumentsTableView);
