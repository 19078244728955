import { H2, P, PSmall, TextSmall } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";
import { CounterpartyStatusType } from "../utils";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: max-content;

    ${P} {
        color: ${(p) => p.theme.colors.textBody1};
    }
`;

export const TitleBody = styled.div`
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: stretch;

    ${TextSmall} > span {
        color: ${(p) => p.theme.colors.ui100};
    }

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

export const TitleHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const TitleDescription = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Actions = styled.div`
    display: flex;
    gap: 8px;
    min-width: max-content;
`;

export const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 0 0 12px 0;
    gap: 10px;
    width: 100%;

    ${H2}, ${P} {
        margin: 0;
    }
`;

export const Body = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 12px;
    width: 100%;
    min-width: max-content;

    padding: 4px 0 0 0;

    ${P} {
        margin: 0;
    }

    @media (max-width: 1358px) {
        grid-template-columns: 1fr;
    }
`;

export const TitleIcon = styled.span<{ status: CounterpartyStatusType }>`
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;

    :after {
        content: "";
        display: inline-block;
        position: absolute;
        transform: translate(50%, 50%);
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    ${(p) => {
        switch (p.status) {
            case CounterpartyStatusType.NORMAL:
                return css`
                    :after {
                        background-color: ${p.theme.colors.positive100};
                    }

                    background-color: ${p.theme.colors.positive8};
                `;
            case CounterpartyStatusType.AWAITING_CP:
                return css`
                    :after {
                        border: 1px solid ${p.theme.colors.process100};
                    }

                    background-color: ${p.theme.colors.process8};
                `;
            case CounterpartyStatusType.MARGIN_CALL:
                return css`
                    :after {
                        background-color: ${p.theme.colors.attention100};
                    }

                    background-color: ${p.theme.colors.attention8};
                `;
            case CounterpartyStatusType.STOPPED:
            case CounterpartyStatusType.STOPPED_BY_CP:
                return css`
                    :after {
                        border: 1px solid ${p.theme.colors.ui72};
                    }

                    background-color: ${p.theme.colors.ui8};
                `;
            case CounterpartyStatusType.LOW_GROSS_FREE:
            case CounterpartyStatusType.RESTRICTED_TRADING:
            case CounterpartyStatusType.LIQUIDATION:
                return css`
                    :after {
                        border: 1px solid ${p.theme.colors.negative80};
                    }

                    background-color: ${p.theme.colors.negative4};
                `;
            default:
                return null;
        }
    }}
`;

export const Badge = styled(PSmall)<{ status: CounterpartyStatusType }>`
    display: flex;
    align-items: center;
    align-self: center;
    cursor: default;
    padding: 4px 8px;
    gap: 10px;
    font-weight: 600;
    border-radius: 40px;

    ${(p) => {
        switch (p.status) {
            case CounterpartyStatusType.NORMAL:
                return css`
                    color: ${p.theme.colors.positive100};
                    background-color: ${p.theme.colors.positive8};
                `;
            case CounterpartyStatusType.AWAITING_CP:
                return css`
                    color: ${p.theme.colors.process100};
                    background-color: ${p.theme.colors.process8};
                `;
            case CounterpartyStatusType.MARGIN_CALL:
                return css`
                    color: ${p.theme.colors.attention100};
                    background-color: ${p.theme.colors.attention8};
                `;
            case CounterpartyStatusType.STOPPED:
            case CounterpartyStatusType.STOPPED_BY_CP:
                return css`
                    color: ${p.theme.colors.ui72};
                    background-color: ${p.theme.colors.ui8};
                `;
            case CounterpartyStatusType.LOW_GROSS_FREE:
            case CounterpartyStatusType.RESTRICTED_TRADING:
            case CounterpartyStatusType.LIQUIDATION:
                return css`
                    color: ${p.theme.colors.negative80};
                    background-color: ${p.theme.colors.negative4};
                `;
            default:
                return null;
        }
    }}
`;

export const IndicatorContainer = styled.div<{
    status: CounterpartyStatusType;
    subStatus: CounterpartyStatusType;
}>`
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 407px;
    min-height: 102px;
    width: 100%;
    padding: 8px 12px;
    border-radius: 8px;

    ${(p) => {
        switch (p.subStatus) {
            case CounterpartyStatusType.NORMAL:
                return css`
                    :after {
                        background-color: ${p.theme.colors.positive100};
                    }

                    border: 1px solid ${p.theme.colors.ui8};
                    background-color: ${p.theme.colors.uiWhite100};
                `;
            case CounterpartyStatusType.MARGIN_CALL:
                return css`
                    :after {
                        background-color: ${p.theme.colors.attention100};
                    }

                    background-color: ${p.theme.colors.attention4};
                `;
            case CounterpartyStatusType.LOW_GROSS_FREE:
            case CounterpartyStatusType.RESTRICTED_TRADING:
            case CounterpartyStatusType.LIQUIDATION:
                return css`
                    :after {
                        border: 1px solid ${p.theme.colors.negative80};
                    }

                    background-color: ${p.theme.colors.negative4};
                `;
            default:
                return null;
        }
    }}

    ${(p) => {
        switch (p.status) {
            case CounterpartyStatusType.STOPPED:
            case CounterpartyStatusType.STOPPED_BY_CP:
            case CounterpartyStatusType.AWAITING_CP:
                return css`
                    border: 1px solid ${p.theme.colors.ui8};
                    background-color: transparent;
                `;
        }
    }}
}
`;

export const IndicatorWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
`;

export const ScrollContainer = styled.div`
    display: flex;
    width: 100%;
    overflow: auto;
`;

export const HintWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
