import { H3, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";
import { GroupContent, HintsContainer, HintsHeader } from "../styled";
import { OpenPosition } from "./OpenPosition";

const Group = styled(VStack)`
    flex: 1;
`;

export const OpenPositionHints = ({
    onAmountSelected,
    cpId,
    asset,
}: {
    onAmountSelected: (amount: bigint) => void;
    cpId: number;
    asset: string;
}) => {
    return (
        <HintsContainer>
            <Group>
                <HintsHeader>
                    <H3>Open position</H3>
                </HintsHeader>
                <GroupContent>
                    <OpenPosition onAmountSelected={onAmountSelected} cpId={cpId} asset={asset} />
                </GroupContent>
            </Group>
        </HintsContainer>
    );
};
