import { useFilteredLimits } from "hooks/useFilteredLimits";
import { useMemo } from "react";
import { CounterpartyLimit, SubaccountLimit } from "types";

// length of SubaccountLimit type tuple is 10. CounterpartyLimit length is 12
// so currently it is workable to check the type by array length
const RAW_SUBACCOUNT_LIMIT_TYPE_ARRAY_LENGTH = 10;
const isCounterpartyLimit = (
    data: CounterpartyLimit | SubaccountLimit,
): data is CounterpartyLimit => data.length > RAW_SUBACCOUNT_LIMIT_TYPE_ARRAY_LENGTH;

export const useDefaultMarkups = () => {
    const { data, isLoading } = useFilteredLimits();

    return useMemo(() => {
        return {
            defaultMarkups: (data ?? []).reduce<Record<number, number>>((acc, limit) => {
                const cpId = limit[0];
                const defaultMarkup = isCounterpartyLimit(limit) ? limit[10] : limit[8];

                return {
                    ...acc,
                    [cpId]: defaultMarkup,
                };
            }, {}),
            isLoading,
        };
    }, [data, isLoading]);
};
