import { HStack, ShadowCard } from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { useEffect, useMemo, VFC } from "react";
import { Subaccount, useSubaccounts } from "./api";
import { SubaccountCard, SubaccountPlaceholderCard } from "./SubaccountCard";
import { useFilterContext } from "./SubaccountsFilterContext";
import { TopBar } from "./TopBar";
import { useWizard } from "./wizard/useWizard";

const collator = new Intl.Collator();
const STATUS_WEIGHTS: { [Key in Subaccount["status"]]: number } = {
    active: 3,
    pending: 2,
    disabled: 1,
};
const SORT_FUNCTIONS = {
    type: ({ type: t1, info: { username: u1 } }: Subaccount, { type: t2, info: { username: u2 } }: Subaccount) => {
        if (t1 === t2) {
            return collator.compare(u1, u2);
        }
        return collator.compare(t1, t2);
    },
    status: (
        { status: s1, info: { username: u1 } }: Subaccount,
        { status: s2, info: { username: u2 } }: Subaccount,
    ) => {
        if (s1 === s2) {
            return collator.compare(u1, u2);
        }
        return STATUS_WEIGHTS[s2] - STATUS_WEIGHTS[s1];
    },
};

export const Subaccounts: VFC<{
    onSubaccountSelect: (subaccount: Subaccount) => void;
}> = ({ onSubaccountSelect }) => {
    const { wizard, createSubaccount } = useWizard();
    const { subaccounts, isLoading } = useSubaccounts();
    const { filters } = useFilterContext();

    useEffect(() => {
        if (!isLoading && subaccounts.length === 0) {
            createSubaccount();
        }
    }, [subaccounts, isLoading]);

    const filteredAndSortedSubaccounts = useMemo(() => {
        if (!filters) {
            return [];
        }

        const normalizedSearchQuery = filters.searchQuery.toLocaleLowerCase();
        const filteredSubaccounts = subaccounts.filter((subaccount) => {
            const searchMatched = [
                String(subaccount.info.clientId ?? ""),
                subaccount.info.email,
                subaccount.info.username,
                subaccount.type,
            ]
                .map((str) => str.toLocaleLowerCase())
                .some((str) => str.includes(normalizedSearchQuery));
            const typeMatched = filters.types.length ? filters.types.includes(subaccount.type) : true;
            const statusMatched = filters.status.length ? filters.status.includes(subaccount.status) : true;

            return searchMatched && typeMatched && statusMatched;
        });

        return filteredSubaccounts.sort(SORT_FUNCTIONS[filters.sort]);
    }, [filters, subaccounts]);

    if (isLoading) {
        return (
            <ShadowCard>
                {[...Array(3).keys()].map((key) => (
                    <SubaccountPlaceholderCard key={key} />
                ))}
            </ShadowCard>
        );
    }

    const isEmptySearch = filteredAndSortedSubaccounts.length === 0;

    return (
        <>
            {wizard}
            <TopBar createSubaccount={createSubaccount} />
            <div style={{ flex: 1, overflow: "auto" }}>
                <ShadowCard isDefault>
                    {isEmptySearch ? (
                        <HStack padding={16}>
                            <EmptySearch />
                        </HStack>
                    ) : (
                        filteredAndSortedSubaccounts.map((subaccount) => (
                            <SubaccountCard
                                searchQuery={filters.searchQuery}
                                key={subaccount.createdAt}
                                subaccount={subaccount}
                                onSubaccountSelect={() => onSubaccountSelect(subaccount)}
                            />
                        ))
                    )}
                </ShadowCard>
            </div>
        </>
    );
};
