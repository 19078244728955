import { all, call, put, spawn, takeEvery } from "@redux-saga/core/effects";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { wsNotificationsServiceSaga } from "feature/inboxPopover/wsNotifications.saga";
import createSagaMiddleware from "redux-saga";
import { appSlice } from "../feature/app";
import { wsSlice } from "../feature/app/ws";
import { wsServiceSaga } from "../feature/app/ws.saga";
import { authSlice, logout } from "../feature/auth";
import { authSaga } from "../feature/auth/auth.saga";

import { wsNotificationsSlice } from "feature/inboxPopover/wsNotifications";
import { appSaga } from "../feature/app/store/saga";
import { onboardingSaga } from "../feature/onboarding/onboarding.saga";
import { sagaSettings, settingSlice } from "../feature/settings";
import { actionChannel } from "./actionChannel";
import { counterpartiesSlice } from "./counterpartiesSlice";
import { onboardingSlice } from "./onboardingSlice";

export type IRootState = ReturnType<typeof appReducer>;

export function* actionPortal(action: any) {
    yield put(action);
}

function* actionSaga() {
    yield takeEvery(actionChannel, actionPortal);
}

export function* rootSaga() {
    const sagas = [
        actionSaga,
        authSaga,
        onboardingSaga,
        appSaga,
        sagaSettings,
        wsServiceSaga,
        wsNotificationsServiceSaga,
    ];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                let count = 0;
                while (count < 5) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.error(e);
                        count += 1;
                    }
                }
            }),
        ),
    );
}

const saga = createSagaMiddleware();
const appReducer = combineReducers({
    app: appSlice.reducer,
    auth: authSlice.reducer,
    settings: settingSlice.reducer,
    ws: wsSlice.reducer,
    wsNotifications: wsNotificationsSlice.reducer,
    counterparties: counterpartiesSlice.reducer,
    onboarding: onboardingSlice.reducer,
});
const rootReducer: typeof appReducer = (state, action) => {
    if (action.type === logout.toString()) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
export const store = configureStore({
    reducer: rootReducer,
    middleware: [saga],
});
saga.run(rootSaga);
export type AppDispatch = typeof store.dispatch;

export default store;
