import { FormInfo, TextSmall, VStack } from "@fm-frontend/uikit";
import { NumberedList } from "components/NumberedList";
import React from "react";
import styled from "styled-components";

const InfoText = styled(TextSmall)`
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    letter-spacing: -0.005em;

    color: ${(p) => p.theme.colors.ui52};
`;

export const TermsAndConditionsContent: React.FC<
    Partial<{
        companyName: string;
        registrationNumber: string;
        address: string;
        country: string;
    }>
> = ({ country, address, registrationNumber, companyName }) => {
    return (
        <VStack spacing={16} margin={[0, 0, 24, 0]}>
            <FormInfo>These Terms and Conditions ("T&C") are a contract between</FormInfo>
            <FormInfo>
                You, <strong>{companyName}</strong>, registered number <strong>{registrationNumber}</strong>, with a
                registered office in <strong>{address}</strong>, <strong>{country}</strong>, who uses one or more Finery
                Markets products and services under these T&C, including through Finery Markets software, websites, or
                applications (hereinafter referred to as "User") and
            </FormInfo>
            <FormInfo>
                <strong>Finery Markets (BVI) Limited</strong>, registered number 2079689, with a registered office in
                Trinity Chambers, PO Box 4301, Road Town, Tortola, British Virgin Islands (hereinafter referred to as
                "Finery Markets"), and
            </FormInfo>
            <FormInfo>(each a “Party” and together the “Parties”).</FormInfo>
            <FormInfo>
                Finery Markets gives a User access to its software application (“Finery Markets software”) that is
                described in the Operating rules. By accessing and using the Finery Markets software, the User agrees to
                be bound by the T&C set out herein. If the User does not agree to these T&C, it must discontinue the use
                and may not access, display, use, download, and/or otherwise copy or distribute from Finery.
            </FormInfo>
            <FormInfo>
                These T&C, together with the Operating rules, Risk Disclosure, Tariff Policy and Privacy Policy, shall
                form a single agreement between a User and Finery Markets.
            </FormInfo>
            <NumberedList
                header={
                    <>
                        <a id="_xejcm5kjyu2y" />
                        GENERAL PROVISIONS
                    </>
                }
                start={1}
            >
                {[
                    <>
                        Finery Markets agrees to provide the User with access to and User agrees to use Finery Markets
                        software in strict accordance with the Operating rules.
                    </>,
                    <>
                        "Users will be notified of updates to these T&C at the email address associated with their
                        account.
                    </>,
                    <>
                        Updates to the Operating Rules, Risk Disclosure, Tariff Policy and Privacy Policy will be made
                        available on our website at{" "}
                        <a href="https://finerymarkets.com" target="_blank" rel="noreferrer">
                            finerymarkets.com
                        </a>{" "}
                        ("Website").
                    </>,
                    <>Use of Finery Markets software is subject to the License fee as described in Section 4.</>,
                    <>Finery Markets may store crypto addresses and bank credentials of the Users.</>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <NumberedList
                header={
                    <>
                        <a id="_ts97u0qimt4p" />
                        LIMITATION OF LIABILITIES
                    </>
                }
                start={2}
            >
                {[
                    <>
                        FINERY MARKETS, OUR OWNERS, OFFICERS, DIRECTORS, EMPLOYEES AND CONSULTANTS SHALL NOT BE LIABLE
                        TO USER OR ANYONE CLAIMING THROUGH USER FOR ANY INCIDENTAL, DIRECT OR INDIRECT, SPECIAL OR
                        CONSEQUENTIAL DAMAGES OR LOSSES WHATSOEVER INCURRED IN RELATION TO THESE T&C INCLUDING BUT NOT
                        LIMITED TO LOSS OF PROFITS OR ANTICIPATED SAVINGS, LOSS OF REVENUE OR INCOME, LOSS OF USE OF
                        PRODUCTION, LOSS OF BUSINESS, CONTRACTS AND OPPORTUNITIES, OR ANY OTHER SPECIAL, INDIRECT, OR
                        CONSEQUENTIAL LOSS OR DAMAGE UNLESS SUCH DAMAGE IS CAUSED BY OUR (OR ANY OF OUR EMPLOYEES,
                        AGENTS OR REPRESENTATIVES) WILLFUL MISCONDUCT, GROSS NEGLIGENCE, OR VIOLATION OF APPLICABLE LAW.
                    </>,
                    <>
                        USER IS SOLELY RESPONSIBLE FOR MAINTAINING THE ACCURACY AND INTEGRITY OF ITS OWN DATA OR LOSS OF
                        ANY DATA, ANY UNAUTHORIZED ACCESS TO, ALTERATION OF ITS DATA, PROGRAMS OR PROCUREMENT OF
                        SUBSTITUTE GOODS OR SERVICES, FOR LOST OR CORRUPT TRANSACTION OR DATA BY WHATEVER MEANS IN
                        WHATEVER FORMS. FINERY MARKETS, OUR OWNERS, OFFICERS, DIRECTORS, EMPLOYEES AND CONSULTANTS SHALL
                        NOT BE LIABLE FOR ANY DAMAGES WHATSOEVER DUE TO ITS ACTS OR OMISSIONS.
                    </>,
                    <>
                        TO THE EXTENT PERMITTED BY APPLICABLE LAW, FINERY MARKETS ARE NOT RESPONSIBLE FOR ANY DECISIONS
                        MADE UPON CONFIRMATIONS OR ANY OTHER DATA PROVIDED BY FINERY MARKETS TO USER.
                    </>,
                    <>NEITHER PARTY SHALL BE LIABLE FOR ANY PERSONAL INJURY OR LOSS OF PROFITS.</>,
                    <>
                        Finery Markets shall not be liable to User for any unperformed transactions based on missed or
                        incorrect crypto addresses or bank credentials of the Users that were reflected on the Platform.
                    </>,
                    <>
                        The Parties agree that the terms in this limitation of liability represent a reasonable
                        allocation of risk. In no event shall the one Party’s liability to the other Party exceed 50 000
                        EUR (fifty thousand euros).
                    </>,
                    <>
                        Finery Markets’ liability is strictly limited to Finery Markets’ own activities. Finery Markets
                        is in no way liable for any actions, including those of gross negligence, willful misconduct or
                        violation of applicable law, which are the responsibility of any counterparties or any other
                        users including but not limited to those of Finery Markets software.
                    </>,
                    <>
                        No action or claim relating to these T&C may be instituted more than one (1) year after the
                        event giving rise to such action or claim.
                    </>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <NumberedList
                header={
                    <>
                        <a id="_pndjocykrsae" />
                        CONFIDENTIAL INFORMATION AND NON-CIRCUMVENTION
                    </>
                }
                start={3}
            >
                {[
                    <>
                        Confidential Information for the purposes of these T&C shall mean the information such as
                        characteristics and functionality of Finery Markets software applications, Finery Markets’
                        trading and order execution methods and their software implementations, operational and
                        settlement processes, designs, product information and other know-how related to Finery Markets
                        software applications, API and other technical information.
                    </>,
                    <>
                        Each Party shall at all times keep confidential and shall not disclose to any third party any
                        confidential information acquired in connection with these T&C, any means of communication,
                        business processes or an API specification, except:
                        <NumberedList start={3.2}>
                            {[
                                <>
                                    as required by Applicable Regulation or under the compulsion of law or by request of
                                    any regulatory, government or law enforcement agencies in any jurisdiction; or
                                </>,
                                <>
                                    the cases where such disclosure is necessary in order to facilitate the fulfillment
                                    of the obligations under these T&C and is required by a third party such as an
                                    intermediary.
                                </>,
                            ].map((element, index) => ({ ...element, key: index }))}
                        </NumberedList>
                    </>,
                    <>
                        The cases where such disclosure is necessary in order to facilitate the fulfillment of the
                        obligations under these T&C and are required by a third party such as an intermediary.
                    </>,
                    <>
                        Users of Finery Markets software agree that themselves, their officers, directors, agents,
                        associates and any related parties, will not directly or indirectly contact, deal with,
                        transact, or otherwise be involved for the period of 24 months from the date of introduction
                        with any corporation, partnership, proprietorship, trust, or other entities who are Users of
                        Finery Markets software if the initial contact between the parties (introduction) was through
                        the Finery Markets software or officers, directors, agents or other Finery Markets’
                        representatives, unless (i) the parties use Finery Markets software to deal or transact with one
                        another to the extent allowed by the functionality of Finery Markets software or (ii) the
                        approval to do so is granted by Finery Markets in writing on a case by case basis. In case of
                        detection of the fact of contacting, dealing with, transacting between Users that were
                        introduced to each other via FM Platform, Finery Markets is entitled to charge a standard
                        referral fee from the User based on the Finery Markets Tariff Policy.
                    </>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <NumberedList
                header={
                    <>
                        <a id="_glf7qzru6qdw" />
                        FEES AND PAYMENT PROCEDURES
                    </>
                }
                start={4}
            >
                {[
                    <>
                        <a id="_1sdooxhsh31o" />
                        The Parties have agreed on a License fee that shall be paid on a monthly basis by User to Finery
                        Markets.
                    </>,
                    <>
                        <a id="_wgfupeyl3xi8" />
                        The License fee rate is shown in the Tariff Policy. The License fee does not include VAT
                    </>,
                    <>
                        Payment terms and conditions between the Licensor and the Licensee:
                        <NumberedList start={4.3}>
                            {[
                                <>
                                    <a id="_fh8ifr7ysdkx" />
                                    The Licensor issues an invoice at the beginning of the month that follows the month
                                    for which the License was provided. The License Fee invoice shall be paid within 20
                                    (twenty) working days.
                                </>,
                                <>
                                    <a id="_n80q03l3qpm6" />
                                    If the invoice is not settled in the due time then the Licensor has the right to
                                    refuse access to Finery Markets software and/or terminate the Agreement with
                                    immediate effect.
                                </>,
                                <>
                                    <a id="_n3bsuj4zixx0" />
                                    Each Party shall be liable for its own tax obligations and shall bear responsibility
                                    for payment of its own taxes in accordance with the applicable tax rules.
                                </>,
                                <>
                                    <a id="_3citank4hqir" />
                                    The Licensor shall have the right to impose a late payment charge of 0.1% of the
                                    amount due per every day of delay. The settlement day is the day on which the
                                    appropriate amounts are credited to the Licensor’s bank account.
                                </>,
                                <>
                                    <a id="_1l1knk3hq17w" />
                                    Payments shall be made to the payment details provided in the applicable invoice.
                                </>,
                            ].map((element, index) => ({ ...element, key: index }))}
                        </NumberedList>
                    </>,
                    <>
                        Finery Markets uses referral mechanics for its Users and non-users. The conditions and
                        procedures for cooperation in the area of referral are described in the Finery Markets Referral
                        Program.
                    </>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <NumberedList
                header={
                    <>
                        <a id="_fjox1fy11111" />
                        WHITE LABEL PROVISIONS
                    </>
                }
                start={5}
            >
                {[
                    <>
                        In case of using the Platform as a software for the purposes of white label the User shall have
                        the right to replace Finery Market's trademarks, logos, and other branding elements with their
                        own, subject to compliance with applicable laws and regulations.
                    </>,
                    <>The User is not entitled to make any modifications to the FM platform.</>,
                    <>
                        The User agrees to maintain the quality and standards of the platform with industry best
                        practices and Finery Market's guidelines.
                    </>,
                    <>
                        The User shall not engage in any activities that may tarnish the reputation or goodwill
                        associated with the platform or that may lead to confusion or misrepresentation regarding the
                        ownership or origin of the platform.
                    </>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <NumberedList
                header={
                    <>
                        <a id="_fjox1fyos8fs" />
                        TERMINATION AND AMENDMENTS
                    </>
                }
                start={6}
            >
                {[
                    <>These T&C shall become effective on the same date when it is signed by both Parties.</>,
                    <>
                        Each Party has the right to terminate the Agreement at any time by providing written notice to
                        the other Party, through the agreed means of communication stated under the "Communication"
                        section of these T&C, including fourteen (14) day notice period.
                    </>,
                    <>
                        Any Party may terminate these T&C immediately by giving written notice of termination to the
                        other Party if the other Party commits a material breach of these T&C.
                    </>,
                    <>
                        Any Party may terminate these T&C immediately by giving written notice of termination to the
                        other Party if:
                        <NumberedList start={6.4}>
                            {[
                                <>
                                    the other Party: is dissolved, ceases to conduct all (or substantially all) of its
                                    business, is or becomes unable to pay its debts as they fall due, is or becomes
                                    insolvent or is declared insolvent; or convenes a meeting or makes or proposes to
                                    make any arrangement or composition with its creditors;
                                </>,
                                <>
                                    an administrator, administrative receiver, liquidator, receiver, trustee, manager or
                                    similar is appointed over any of the assets of the other Party;
                                </>,
                                <>
                                    an order is made for the winding up of the other Party, or the other Party passes a
                                    resolution for its winding up (other than for the purpose of a solvent company
                                    reorganization where the resulting entity will assume all the obligations of the
                                    other Party under the Agreement);
                                </>,
                            ].map((element, index) => ({ ...element, key: index }))}
                        </NumberedList>
                    </>,
                    <>
                        Notwithstanding anything to the contrary in these T&C, the obligations contained in Sections 2,
                        3, 7, 8, 9, and 10 shall survive the termination of these T&C.
                    </>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <NumberedList header={<>WARRANTIES</>} start={7}>
                {[
                    <>
                        The Parties warrant that they have the legal right and authority to enter into these T&C and to
                        perform their obligations under the Agreement.
                    </>,
                    <>
                        All of the Parties' warranties and representations in respect of the subject matter of these T&C
                        are expressly set out in these T&C.
                    </>,
                    <>
                        To the maximum extent permitted by applicable law, no other warranties or representations
                        concerning the subject matter of these T&C will be implied into the Agreement or any related
                        contract.
                    </>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <NumberedList header={<>INDEMNIFICATION</>} start={8}>
                {[
                    <>
                        Each Party agrees to indemnify and hold harmless the other Party, and its respective directors,
                        shareholders, affiliates, officers, agents, employees, and permitted successors and assigns
                        against any and all claims, losses, damages, liabilities, penalties, punitive damages, expenses,
                        reasonable legal fees and costs of any kind or amount whatsoever, which result from or arise out
                        of any act or omission of the indemnifying Party, its respective directors, shareholders,
                        affiliates, officers, agents, employees, and permitted successors and assigns that occurs in
                        connection with these T&C.
                    </>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <NumberedList
                header={
                    <>
                        <a id="_2mmbtrvmzfwa" />
                        COMMUNICATION
                    </>
                }
                start={9}
            >
                {[
                    <>
                        Any notice or other communication required or authorized to be given under these T&C shall be
                        done in writing.
                    </>,
                    <>
                        Any notice or other communication may be sent by email to the Parties' addresses. Finery
                        Market’s address for notice or communication is legal@finerymarkets.com. User’s address for
                        notice of communication will be the address associated with their account.
                    </>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <NumberedList
                header={
                    <>
                        <a id="_8upr46s8dddc" />
                        MISCELLANEOUS
                    </>
                }
                start={10}
            >
                {[
                    <>
                        These T&C supersedes any previous agreement(s) between the Parties and constitutes the entire
                        agreement between the Parties relating to the subject matter of these T&C.
                    </>,
                    <>
                        If there is any inconsistency between the provisions of these T&C and the Operating Rules, the
                        Operating rules shall prevail.
                    </>,
                    <>
                        To the extent that any provision of these T&C is found by any court or competent authority to be
                        invalid, unlawful or unenforceable in any jurisdiction, that provision shall be deemed not to be
                        a part of these T&C and shall not affect the enforceability of the remainder of these T&C nor
                        shall it affect the validity, lawfulness or enforceability of that provision in any other
                        jurisdiction.
                    </>,
                    <>
                        The Parties agree that each Party has a right but not an obligation to make the information of
                        User’s using Finery Markets software public by means of a press release or alike.
                    </>,
                    <>
                        User’s consent to the collection, storage, and disclosure by Finery Markets and our agents of
                        any confidential information to governmental authorities as required by law or other agreement
                        by or between governments. Confidential information includes personal data, account details,
                        transactional information, and any other information that a reasonable person would consider
                        being of a confidential or proprietary nature.
                    </>,
                    <>
                        The captions are for convenience only and in no way define, limit or enlarge the scope of these
                        T&C or any of its Sections.
                    </>,
                    <>
                        These T&C and the rights and obligations of the Parties hereunder shall be governed by the laws
                        of England and Wales.
                    </>,
                    <>
                        The Parties hereby agree that the Arbitration Service of the Cyprus Chamber of Commerce and
                        Industry (CCCI) shall have exclusive jurisdiction over any issues regarding these T&C. The
                        language of the arbitration shall be English. All differences of any nature that may arise in
                        relation to this contract between the contracting Parties, shall be referred to arbitration by a
                        single Arbitrator in accordance with the provisions of the Arbitration Law of Cyprus, Cap 4. The
                        Arbitrator will be proposed by the Arbitration Service of the Cyprus Chamber of Commerce and
                        Industry (CCCI) and must be approved by the contracting Parties. In case of disagreement between
                        the contracting Parties on the proposed Arbitrator, the Arbitrator will be appointed in
                        accordance with the provisions of the Arbitration Law of Cyprus, Cap 4.
                    </>,
                ].map((element, index) => ({ ...element, key: index }))}
            </NumberedList>
            <InfoText>
                EACH PARTY FURTHER ACKNOWLEDGES THAT IT HAS READ THESE T&C, UNDERSTANDS THEM, AND AGREES TO BE BOUND BY
                THEM.
            </InfoText>
            <InfoText>IN WITNESS WHEREOF, the Parties hereto have duly executed these T&C.</InfoText>
        </VStack>
    );
};
