export enum ChallengeName {
    LOGIN = "login",
    SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
    USER_SRP_AUTH = "USER_SRP_AUTH",
    MFA_SETUP = "MFA_SETUP",
    RESET_REQUIRED = "RESET_REQUIRED",
    NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
    USER_PASSWORD_AUTH = "USER_PASSWORD_AUTH",
}

export interface UserCognito {
    authenticationFlowType?: string;
    signInUserSession?: any;
    attributes?: {
        email?: string;
        email_verified?: boolean;
    };
    [key: string]: any;
}

export interface Token {
    token: string;
}

export enum MfaSteps {
    setupAuthenticator,
    setupMfaCode,
}
