import { useEffect, useState } from "react";
import { parse, stringify } from "../utils/json";
import { useSelector } from "./useSelector";

export const enum LS_VARIABLES {
    LIMITS_MAKERS_DASH_MESSAGE_DISMISSED = "limits-makers-dash-message-dismissed",
    TRADES_GROUPED_BY = "trades-grouped-by",
    POSITIONS_GROUP_BY = "positions-group-by",
    TESTING_TOOLS = "testing-tools",
    TRADING_RECENT = "trading-recent",
    API_INTEGRATION_REQUIRED = "api-integration-required",
    TnC_DELEGATED = "termsAndConditionsDelegated",
}

/**
 * Like useState, but saves the changes to localStorage
 * @param key localStorage key to write the data to
 * @param defaultValue value that's used if localStorage doesn't contain key yet
 * @return same signature as useState<V>(defaultValue)
 */
export function useLSState<V>(key: LS_VARIABLES, defaultValue: V) {
    const stateGetSet = useState<V>(() => {
        const value = getLSValue<V>(key);
        if (value === null) return defaultValue;
        return value;
    });
    // store to ls after updating
    useEffect(() => {
        setLSValue(key, stateGetSet[0]);
    }, [stateGetSet[0]]);
    // pull from ls on regaining focus
    const isActive = useSelector((state) => state.app.isActive);
    useEffect(() => {
        if (!isActive) return;
        const value = getLSValue<V>(key);
        stateGetSet[1](value === null ? defaultValue : value);
    }, [isActive]);

    return stateGetSet;
}

/**
 * Get key with prefix for localStorage value
 * @param key non-namespaced key
 * @returns namespaced key
 */
export function getLSKey(key: string) {
    return `efx-value-${key}`;
}
/**
 * Read and parse value from localStorage
 * @param key non-namespaced key
 * @returns parsed value or null
 */
export function getLSValue<T>(key: string): T | null {
    const lsValue = localStorage.getItem(getLSKey(key));
    if (lsValue === null) {
        return null;
    }
    try {
        return parse(atob(lsValue));
    } catch {
        localStorage.removeItem(key);
    }
    try {
        const value = parse(lsValue);
        setLSValue(key, value);
        return value;
    } catch {
        return null;
    }
}
/**
 * Encode and write value to localStorage
 * @param key non-namespaced key
 * @param value any js value
 * @returns @void
 */
export function setLSValue(key: string, value: unknown) {
    localStorage.setItem(getLSKey(key), btoa(stringify(value)));
}
/**
 * Clear localStorage value by key
 * @param key non-namespaced key
 * @returns @void
 */
export function removeLSValue(key: string) {
    localStorage.removeItem(getLSKey(key));
}
