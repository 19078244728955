import { HStack, IconButton, Icons, PrimaryButton, Search, Tooltip } from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { onMobile } from "const";
import { isEqual } from "lodash";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { DEFAULT_FILTER_INPUTS, FilterInputs, useFilterContext } from "./SubaccountsFilterContext";

const Container = styled.form`
    display: flex;
    width: 100%;
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 12px;
    padding-left: 12px;
    gap: 6px;

    button {
        gap: 2px;
    }

    ${onMobile} {
        padding-bottom: 8px;
    }
`;

const FiltersContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 6px;
`;

const TYPE_OPTIONS: DropdownOption[] = [
    { value: "taker", text: "Taker" },
    { value: "maker", text: "Maker" },
];
const STATUS_OPTIONS: DropdownOption[] = [
    { value: "active", text: "Active" },
    { value: "pending", text: "Pending" },
    { value: "disabled", text: "Disabled" },
];
const SORT_OPTIONS: DropdownOption[] = [
    { value: "status", text: "Status" },
    { value: "type", text: "Type" },
];
export const TopBar: React.VFC<{
    createSubaccount: () => void;
}> = ({ createSubaccount }) => {
    const { filters, setFilters } = useFilterContext();

    const { control, watch, reset } = useForm<FilterInputs>({
        defaultValues: filters,
        mode: "onChange",
    });

    const { sort: sortFilter, ...filterToCompare } = filters;
    const { sort: sortDefault, ...defaultFilterToCompare } = DEFAULT_FILTER_INPUTS;
    const isClearButtonVisible = !isEqual(filterToCompare, defaultFilterToCompare);

    const resetFilter = () => {
        reset({ ...DEFAULT_FILTER_INPUTS });
    };

    useEffect(() => {
        const subscription = watch((values) => {
            setFilters({
                ...values,
            } as FilterInputs);
        });

        return () => subscription.unsubscribe();
    }, [watch, setFilters]);

    return (
        <Container>
            <FiltersContainer>
                <div style={{ width: "150px" }}>
                    <Controller
                        control={control}
                        render={({ field }) => <Search size="small" query={field.value} onChange={field.onChange} />}
                        name="searchQuery"
                    />
                </div>
                <Controller
                    control={control}
                    render={({ field }) => (
                        <SingleDropdown
                            value={field.value}
                            onChange={field.onChange}
                            renderTrigger={(trigger) => (
                                <SingleDropdown.Trigger {...trigger} size="small" variant="simple">
                                    <SingleDropdown.TriggerEssence
                                        {...trigger}
                                        option={trigger.selectedOption}
                                        size="small"
                                    />
                                </SingleDropdown.Trigger>
                            )}
                            options={SORT_OPTIONS}
                            caption="Sort"
                        >
                            <SingleDropdown.BasicSheet size="small" options={SORT_OPTIONS} />
                        </SingleDropdown>
                    )}
                    name="sort"
                />
                <Controller
                    control={control}
                    render={({ field }) => (
                        <MultipleDropdown
                            values={field.value}
                            onChange={field.onChange}
                            renderTrigger={(trigger) => (
                                <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                                    <MultipleDropdown.TriggerEssence
                                        {...trigger}
                                        option={trigger.selectedOptions}
                                        size="small"
                                    />
                                </MultipleDropdown.Trigger>
                            )}
                            options={TYPE_OPTIONS}
                            caption="Type"
                            asFilter
                        >
                            <MultipleDropdown.BasicSheet size="small" options={TYPE_OPTIONS} />
                        </MultipleDropdown>
                    )}
                    name="types"
                />

                <HStack spacing={6}>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <MultipleDropdown
                                values={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                                        <MultipleDropdown.TriggerEssence
                                            {...trigger}
                                            option={trigger.selectedOptions}
                                            size="small"
                                        />
                                    </MultipleDropdown.Trigger>
                                )}
                                options={STATUS_OPTIONS}
                                caption="Status"
                                asFilter
                            >
                                <MultipleDropdown.BasicSheet size="small" options={STATUS_OPTIONS} />
                            </MultipleDropdown>
                        )}
                        name="status"
                    />
                    {isClearButtonVisible && (
                        <Tooltip content="Reset filter" align="center">
                            <IconButton variant="plain" type="button" Icon={Icons.Recent} onClick={resetFilter} />
                        </Tooltip>
                    )}
                </HStack>
            </FiltersContainer>
            <PrimaryButton type="button" size="small" onClick={createSubaccount}>
                <Icons.Plus />
                Invite new
            </PrimaryButton>
        </Container>
    );
};
