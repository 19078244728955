import { post } from "api";
import { useMemo } from "react";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType, useUserType } from "store/hooks";
import useSWR from "swr";
import { CpId, Overnight } from "types";

interface OvernightData {
    own: Overnight[];
    against: Overnight[];
}

export const isOvernightData = (value: OvernightData | Overnight[]): value is OvernightData => {
    return (
        (value as OvernightData)?.own !== undefined ||
        (value as OvernightData)?.against !== undefined
    );
};

export const useOvernightFeesSWRKey = () => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const side = isPrimeBrokerUser ? primeBrokerViewType : "counterparties";

    return side === "subaccounts"
        ? "settings/getSubaccountsOvernightRates"
        : "settings/getOvernightRates";
};

export const useOvernightFees = () => {
    const fetcher = (url: string) => post(url, {});
    const userType = useUserType();
    const apiMethod = useOvernightFeesSWRKey();
    const { data: rawData, isLoading } = useSWR(apiMethod, fetcher, { revalidateOnFocus: false });

    const overnightFees = useMemo(() => {
        if (!rawData) {
            return {
                own: [],
                against: [],
            };
        }
        const data: Overnight[] | OvernightData = rawData;

        if (isOvernightData(data)) {
            return {
                ...data,
            };
        }

        if (userType === "maker") {
            return {
                own: [...data],
                against: [],
            };
        }

        return {
            own: [],
            against: [...data],
        };
    }, [rawData]);

    const groupedOvernightFees: Record<CpId, Overnight[]> = useMemo(() => {
        return [...overnightFees.own, ...overnightFees.against].reduce<Record<CpId, Overnight[]>>(
            (total, item) => {
                total[item.counterparty] ??= [];
                total[item.counterparty].push(item);

                return total;
            },
            {},
        );
    }, [overnightFees]);

    return {
        overnightFees,
        groupedOvernightFees,
        isLoading,
    };
};
