import { ConfirmationModalProps, Notification } from "@fm-frontend/uikit";
import { createAction, createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { ClientType } from "types";

export type CreateNotificationPayload = {
    type: "error" | "success" | "inbox";
    content: string | JSX.Element;
    timeout?: number | false;
};

export type ConfirmationModalContent = Omit<ConfirmationModalProps, "onClose">;

export enum Status {
    Fetching = -1,
    Error = -2,
}

export type CpInfo = {
    username?: string;
    subaccount?: boolean;
    clientType: ClientType;
};

export type State = {
    notifications: Array<Notification>;
    needs2fa: boolean;
    wsOnline: boolean;
    isActive: boolean;
    isMobile: boolean;
    isLoading: boolean;
    loadings: Record<string, boolean>;
    isLoadingApp: boolean;
    isMobileMenuOpen: boolean;
    isPermissionsLackErrorOccured: boolean;
    confirmModalContent: ConfirmationModalContent | null;
    allAvailableCounterpartiesInfo: Record<number, CpInfo>;
};

const initialState: State = {
    notifications: [],
    needs2fa: false,
    wsOnline: false,
    isActive: true,
    isLoading: false,
    isLoadingApp: false,
    loadings: {},
    isMobile: false,
    isMobileMenuOpen: false,
    isPermissionsLackErrorOccured: false,
    confirmModalContent: null,
    allAvailableCounterpartiesInfo: {},
};
export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        addNotification(state, { payload }: PayloadAction<Notification>) {
            state.notifications.push(payload);
        },
        closeNotification(state, { payload }: PayloadAction<string>) {
            return {
                ...state,
                notifications: state.notifications.filter(({ id }) => id !== payload),
            };
        },
        setNeeds2fa(state, { payload: needs2fa }: PayloadAction<boolean>) {
            state.needs2fa = needs2fa;
        },
        setIsActive(state, { payload: isActive }: PayloadAction<boolean>) {
            state.isActive = isActive;
        },
        setIsLoading(state, { payload: isLoading }: PayloadAction<boolean>) {
            state.isLoading = isLoading;
        },
        setIsLoadingApp(state, { payload: loading }: PayloadAction<{ isLoading: boolean; key: string }>) {
            const loadigns = { ...state.loadings };
            loadigns[loading.key] = loading.isLoading;

            state.loadings = loadigns;
            state.isLoadingApp = Object.values(loadigns).some((l) => l);
        },
        setIsMobile(state, { payload: isMobile }: PayloadAction<boolean>) {
            state.isMobile = isMobile;
        },
        setIsMobileMenuOpen(state, { payload: isMobileMenuOpen }: PayloadAction<boolean>) {
            state.isMobileMenuOpen = isMobileMenuOpen;
        },
        setIsPermissionsLackErrorOccured(state, { payload: isPermissionsLackErrorOccured }: PayloadAction<boolean>) {
            state.isPermissionsLackErrorOccured = isPermissionsLackErrorOccured;
        },
        setWsOnline(state, { payload: wsOnline }: PayloadAction<boolean>) {
            state.wsOnline = wsOnline;
        },
        setConfirmModalContent(state, { payload }: PayloadAction<ConfirmationModalContent | null>) {
            state.confirmModalContent = payload;
        },
        setAllAvailableCounterpartiesInfo(
            state,
            { payload: cpsInfo }: PayloadAction<State["allAvailableCounterpartiesInfo"]>,
        ) {
            return {
                ...state,
                allAvailableCounterpartiesInfo: {
                    ...state.allAvailableCounterpartiesInfo,
                    ...cpsInfo,
                },
            };
        },
    },
});

export const {
    actions: {
        addNotification,
        closeNotification,
        setNeeds2fa,
        setWsOnline,
        setConfirmModalContent,
        setIsActive,
        setIsLoading,
        setIsLoadingApp,
        setIsMobile,
        setIsMobileMenuOpen,
        setIsPermissionsLackErrorOccured,
        setAllAvailableCounterpartiesInfo,
    },
    reducer,
} = appSlice;

export const initApp = createAction("app/init");
export const shutdownApp = createAction("app/shutdown");
export const appendLogEntry = createAction("app/appendLogEntry", (payload: string) => ({ payload }));
export const createNotification = createAction("createNotification", (payload: CreateNotificationPayload) => ({
    payload: { ...payload, id: nanoid() },
}));
export const broadcastEvent = createAction("app/broadcastMessage", (payload: Record<string, unknown>) => ({ payload }));
export const requestDataExport = createAction(
    "app/requestDataExport",
    (payload: {
        from?: string;
        to?: string;
        includeHeaders?: boolean;
        filename?: string;
        url: string;
        columns: any;
        itemIdColumn: number;
        urlParams: { [key: string]: any };
    }) => ({ payload }),
);
export const updateIsMobile = createAction("app/updateIsMobile");
