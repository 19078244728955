import { getNextReqId } from "api/methods/postViaWS";
import { sendRESTRequestViaWS } from "feature/app/ws";
import { subscribeToWsRestResponses } from "feature/app/ws.saga";
import { actionChannel } from "store/actionChannel";
import { AssetsBulkPreparedDataType } from "./utils";

const UPDATE_REQUESTS_IN_CHUCK = 5;

export type AssetsBulkEditRequestBodyType =
    | UpdateAssetLimitRequestBody
    | UpdateShortSalesBanRequestBody;

export type BulkUpdateResponse = {
    errorCode?: number;
    requestBody: BulkUpdateRequest;
};
export type BulkUpdateRequest = {
    apiMethod: string;
    body: AssetsBulkEditRequestBodyType;
};
const waitBulkOutput = (
    requestsBodiesWithReqIds: {
        reqId: number;
        requestBody: BulkUpdateRequest;
    }[],
) =>
    new Promise<BulkUpdateResponse[]>((resolve) => {
        const requestsObj = requestsBodiesWithReqIds.reduce<Record<number, BulkUpdateRequest>>(
            (acc, { reqId, requestBody }) => {
                acc[reqId] = requestBody;
                return acc;
            },
            {},
        );
        const reqIdsWithoutResponse = new Set(Object.keys(requestsObj).map(Number));
        let failedReqIds: Record<number, number> = {};

        const unsubscribe = subscribeToWsRestResponses((resp) => {
            const { reqId, error } = resp;
            reqIdsWithoutResponse.delete(reqId);
            if (error !== undefined) {
                failedReqIds[reqId] = error;
            }
            if (reqIdsWithoutResponse.size === 0) {
                unsubscribe();
                const failedRequestResponses: BulkUpdateResponse[] = Object.entries(
                    failedReqIds,
                ).map(([reqId, errorCode]) => ({
                    requestBody: requestsObj[Number(reqId)],
                    errorCode,
                }));
                const succededRequestResponses: BulkUpdateResponse[] = Object.entries(requestsObj)
                    .filter(([reqId]) => !failedReqIds[Number(reqId)])
                    .map(([, requestBody]) => ({
                        requestBody,
                    }));

                const result: BulkUpdateResponse[] = [
                    ...failedRequestResponses,
                    ...succededRequestResponses,
                ];

                resolve(result);
            }
        });
    });

type UpdateShortSalesBanRequestBody = {
    counterpartyId: number;
    currency: string;
    shortSales: boolean;
};
type UpdateAssetLimitRequestBody = {
    counterpartyId: number;
    asset: string;
    grossLimit?: bigint;
    limitCurrency?: string | null;
};

const mapBulkDataToShortSalesRequest = (
    bulkDataItem: AssetsBulkPreparedDataType,
): BulkUpdateRequest => {
    return {
        body: {
            shortSales: bulkDataItem.shortSales,
            counterpartyId: bulkDataItem.cpId,
            currency: bulkDataItem.asset,
        },
        apiMethod: bulkDataItem.shortSales ? "delShortSalesBan" : "setShortSalesBan",
    };
};
const mapBulkDataToAssetLimitRequest = (
    bulkDataItem: AssetsBulkPreparedDataType,
): BulkUpdateRequest => {
    return {
        body: {
            counterpartyId: bulkDataItem.cpId,
            asset: bulkDataItem.asset,
            grossLimit: bulkDataItem.limitAmount,
            limitCurrency: bulkDataItem.limitCurrency,
        },
        apiMethod: bulkDataItem.limitAmount === undefined ? "delCAssetLimit" : "setCAssetLimit",
    };
};

const bulkUpdateAssets = async (preparedRequests: BulkUpdateRequest[]) => {
    const requestsBodiesWithReqIds = preparedRequests.map((requestData) => ({
        reqId: getNextReqId(),
        requestBody: requestData,
    }));
    let startIndex = 0;
    let endIndex = Math.min(requestsBodiesWithReqIds.length, UPDATE_REQUESTS_IN_CHUCK);

    const send = () =>
        setTimeout(() => {
            for (let i = startIndex; i < endIndex; i++) {
                const { reqId, requestBody } = requestsBodiesWithReqIds[i];
                actionChannel.put(
                    sendRESTRequestViaWS({
                        method: requestBody.apiMethod,
                        reqId,
                        content: requestBody.body,
                    }),
                );
            }

            if (endIndex === requestsBodiesWithReqIds.length) {
                return;
            }

            startIndex = endIndex;
            endIndex = Math.min(
                requestsBodiesWithReqIds.length,
                endIndex + UPDATE_REQUESTS_IN_CHUCK,
            );
            send();
        }, 50);
    send();

    return {
        response: await waitBulkOutput(requestsBodiesWithReqIds),
    };
};

export const BulkService = {
    mapBulkDataToShortSalesRequest,
    mapBulkDataToAssetLimitRequest,
    bulkUpdateAssets,
};
