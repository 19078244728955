import { FieldGroup, HStack, Search, TextSmall } from "@fm-frontend/uikit";
import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useFormShouldCloseConfirm } from "@fm-frontend/utils";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { AssetsModal } from "feature/assetsControl/components/AssetsTabContent/AssetsModal";
import {
    AssetsGroupType,
    DEFAULT_ASSETS_OPTION,
    DEFAULT_MODAL_CONFIG,
    ModalConfig,
} from "feature/assetsControl/types";
import {
    useGetOvernightFeesAllowedForCP,
    useIsShortSalesBanAllowed,
} from "feature/assetsControl/useIsAssetsControlActionAllowed";
import { ASSET_CONTROL_MODAL_KEY } from "feature/assetsControl/utils";
import { parseLimit } from "feature/counterparties/utils";
import { useLimitsByAsset } from "hooks/useLimitsByAsset";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { useOvernightFees } from "hooks/useOvernightFees";
import { useShortSalesBan } from "hooks/useShortSalesBan";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCurrencies } from "store/useCurrencies";
import { CounterpartyLimit } from "types";
import { Asset } from "./Asset";
import * as Styled from "./styled";
import { mapLimitsByAssetsToCurrency, searchByAsset } from "./utils";

const NoAssetsBanner = (
    <>
        <TextSmall>
            <b>Asset code not found</b>
        </TextSmall>
        <TextSmall>Please use asset code in the following format: BTC or ETH.</TextSmall>
    </>
);

const ALL_ASSETS_OPTIONS: DropdownOption<AssetsGroupType>[] = [
    { text: "All Assets", value: AssetsGroupType.All },
    { text: "All Crypto", value: AssetsGroupType.Crypto },
    { text: "All Stablecoins", value: AssetsGroupType.Stablecoin },
    { text: "All Fiat", value: AssetsGroupType.Fiat },
];

export const LimitsByAsset: React.FC<{
    limit: CounterpartyLimit;
    cpName: string;
    isSubaccount: boolean;
    isTakerCp: boolean;
}> = (props) => {
    const { limit, cpName, isSubaccount, isTakerCp } = props;
    const { counterpartyId } = useMemo(() => parseLimit(limit), [limit]);
    const { limitsByAssets } = useLimitsByAsset(isSubaccount, counterpartyId);
    const { groupedOvernightFees } = useOvernightFees();
    const isShortSalesBanAllowed = useIsShortSalesBanAllowed();
    const getOvernightFeesAllowedForCP = useGetOvernightFeesAllowedForCP();
    const isOvernightFeesAllowed = getOvernightFeesAllowedForCP(isTakerCp);
    const isAdditionalAssetsOptionAllowed = isShortSalesBanAllowed || isOvernightFeesAllowed;
    const currencies = useCurrencies();
    const mappedLimitsByAssets = useMemo(
        () => mapLimitsByAssetsToCurrency(currencies, limitsByAssets),
        [limitsByAssets, currencies],
    );
    const { shortSalesBans } = useShortSalesBan();
    const [query, setQuery] = useState("");
    const {
        control,
        watch,
        formState: { errors },
    } = useForm<{ assets: string }>({
        defaultValues: {
            assets: DEFAULT_ASSETS_OPTION,
        },
    });

    const [isOpened, setIsOpened] = useState(false);
    const closeModal = () => setIsOpened(false);

    const shouldConfirmClose = useFormShouldCloseConfirm(ASSET_CONTROL_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldConfirmClose, closeModal);

    const assetsWatch = watch("assets");

    const searchResults = useMemo(() => {
        const searchResult = searchByAsset(query, mappedLimitsByAssets);

        if (!assetsWatch || !isAdditionalAssetsOptionAllowed) {
            return searchResult;
        }

        const assetsOption = {
            asset: assetsWatch,
            title: ALL_ASSETS_OPTIONS.find((ao) => ao.value === assetsWatch)?.text ?? "",
            grossLimit: null,
            match: null,
        };

        return [assetsOption, ...searchResult];
    }, [query, mappedLimitsByAssets, assetsWatch, isAdditionalAssetsOptionAllowed]);

    const [modalConfig, setModalConfig] = useState<ModalConfig>({ ...DEFAULT_MODAL_CONFIG });

    const handleModalOpen = (assets: string) => {
        setModalConfig((config) => {
            const { negativeRate, positiveRate } = groupedOvernightFees[counterpartyId]?.find(
                (o) => o.currency === assets,
            ) ?? {
                negativeRate: 0,
                positiveRate: 0,
            };

            const assetLimit = mappedLimitsByAssets?.find(({ asset }) => asset === assets);

            return {
                ...config,
                assets: assets,
                cp: String(counterpartyId),
                cpName,
                grossLimit: assetLimit?.grossLimit,
                limitCurrency: assetLimit?.limitCurrency,
                negativeRate,
                positiveRate,
                shortSalesBans: shortSalesBans,
                isTakerCp,
            };
        });
        setIsOpened(true);
    };

    return (
        <>
            <Styled.Container>
                <HStack spacing={8}>
                    <Search
                        size="small"
                        placeholder="Search"
                        query={query}
                        onChange={(value) => setQuery(value)}
                    />
                    {isAdditionalAssetsOptionAllowed && (
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <SingleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="small"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={ALL_ASSETS_OPTIONS}
                                    align="end"
                                    error={errors.assets?.message}
                                    caption="Type"
                                >
                                    <SingleDropdown.BasicSheet
                                        size="small"
                                        options={ALL_ASSETS_OPTIONS}
                                    />
                                </SingleDropdown>
                            )}
                            name="assets"
                        />
                    )}
                </HStack>
                <FieldGroup direction="column">
                    {searchResults?.length
                        ? searchResults?.map(({ title, asset, match }) => {
                              return (
                                  <Asset
                                      key={asset}
                                      asset={asset}
                                      text={title}
                                      match={match}
                                      onSelect={handleModalOpen}
                                  />
                              );
                          })
                        : NoAssetsBanner}
                </FieldGroup>
            </Styled.Container>
            <AssetsModal
                modalConfig={modalConfig}
                counterparties={EMPTY_ARRAY}
                isOpened={isOpened}
                onSubmit={closeModal}
                onClose={closeModalWithConfirm}
            />
        </>
    );
};
