import { fork, put, take, takeEvery, takeLatest } from "@redux-saga/core/effects";
import { nanoid } from "@reduxjs/toolkit";
import {
    broadcastEvent,
    createNotification,
    initApp,
    setIsMobile,
    setIsMobileMenuOpen,
    setWsOnline,
    shutdownApp,
    updateIsMobile,
} from "..";
import { broadcastChannelId, mobileQuery } from "../../../../const";
import { actionChannel } from "../../../../store/actionChannel";
import { logout } from "../../../auth";
import { sagaCounterpartiesInfo } from "../counterpartiesInfoFetcher";
import { sagaEventNotifications } from "./eventNotifications";
import { sagaExporter } from "./exportData";
import { sagaCreateNotification } from "./notifications";

export function* appSaga() {
    yield put(initApp());
    yield takeEvery(createNotification, sagaCreateNotification);
    document.addEventListener("unload", () => actionChannel.put(shutdownApp()));
    yield fork(sagaBroadcastChannel);
    yield fork(sagaMobile);
    yield takeEvery(updateIsMobile, sagaMobile);
    yield takeLatest(setWsOnline, function* ({ payload }: ReturnType<typeof setWsOnline>) {
        if (payload) yield fork(sagaEventNotifications);
    });
    yield fork(sagaCounterpartiesInfo);
    yield fork(sagaExporter);
}

function* sagaBroadcastChannel() {
    if (!("BroadcastChannel" in window)) return;
    const channel = new BroadcastChannel(broadcastChannelId);
    const clientId = nanoid();
    channel.addEventListener("message", ({ data, origin }) => {
        const { type, channel, author } = data;
        if (document.location.origin === origin && broadcastChannelId === channel && author !== clientId) {
            // putting anything else here might be a security risk
            switch (type) {
                case logout.toString(): {
                    actionChannel.put(logout());
                    break;
                }
            }
        }
    });
    yield takeEvery(broadcastEvent, ({ payload: message }) =>
        channel.postMessage({
            channel: broadcastChannelId,
            author: clientId,
            ...message,
        }),
    );
}

function* sagaMobile() {
    const match = window.matchMedia(`(${mobileQuery})`);
    yield put(setIsMobile(match.matches));
    const listener = (state: MediaQueryListEvent) => {
        actionChannel.put(setIsMobile(state.matches));
        if (!state.matches) actionChannel.put(setIsMobileMenuOpen(false));
    };
    match.addEventListener("change", listener);
    yield take(shutdownApp);
    match.removeEventListener("change", listener);
}
