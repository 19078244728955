import { post } from "api";
import { EMPTY_ARRAY } from "const";
import { useCallback, useMemo } from "react";
import useSWR from "swr";
import { CounterpartyLimitByAsset } from "types";
import { displayError } from "utils";

export const useLimitsByAsset = (isSubaccount: boolean, counterpartyId?: number) => {
    const apiMethod = isSubaccount ? "subaccountsAssetLimits" : "cAssetLimits";
    const fetcher = useCallback(() => post(apiMethod, {}), []);
    const { data, mutate, isLoading } = useSWR(apiMethod, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY,
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    const limitsByAssets: CounterpartyLimitByAsset[] = useMemo(() => {
        if (Array.isArray(data) && counterpartyId) {
            return data.filter(([cpId]) => cpId === counterpartyId);
        }

        return data;
    }, [data, counterpartyId]);

    return {
        limitsByAssets,
        isLoading,
        mutate,
    };
};
