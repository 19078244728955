import { H2, HStack } from "@fm-frontend/uikit";
import { SEARCH_PARAMS } from "const";
import { useSearchParamState } from "hooks/useSearchParamState";
import { createContext, FC, useContext } from "react";
import styled from "styled-components";
import { noop } from "utils";

type MenuValue = "assets" | "instruments";

const Tab = styled(H2)<{ isActive?: boolean }>`
    cursor: pointer;
    color: ${(p) => (p.isActive ? p.theme.colors.ui100 : p.theme.colors.ui32)};

    :hover {
        color: ${(p) => p.theme.colors.ui100};
    }
`;

const AssetControlMenuContext = createContext<{ selectedTab: MenuValue; setSelectedTab: (tab: MenuValue) => void }>({
    selectedTab: "assets",
    setSelectedTab: noop,
});

export const useSelectedAssetControlPage = () => useContext(AssetControlMenuContext).selectedTab;

export const MainTabsContextProvider: FC = ({ children }) => {
    const [selectedTab, setSelectedTab] = useSearchParamState<MenuValue>(
        SEARCH_PARAMS.assetsAndInstrumentsTab,
        "assets",
    );

    return (
        <AssetControlMenuContext.Provider
            value={{
                selectedTab,
                setSelectedTab,
            }}
        >
            {children}
        </AssetControlMenuContext.Provider>
    );
};

export const AssetControlMenu = ({ onAfterChange }: { onAfterChange?: () => void }) => {
    const { selectedTab, setSelectedTab } = useContext(AssetControlMenuContext);

    const handleChange = (tab: MenuValue) => {
        setSelectedTab(tab);
        onAfterChange?.();
    };

    return (
        <HStack flex={1} spacing={16}>
            <Tab isActive={selectedTab === "assets"} key="assets" onClick={() => handleChange("assets")}>
                Assets
            </Tab>
            <Tab isActive={selectedTab === "instruments"} key="instruments" onClick={() => handleChange("instruments")}>
                Instruments
            </Tab>
        </HStack>
    );
};
