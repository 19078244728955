declare global {
    interface Window {
        ENV: {
            PROJECT_NAME: string;
            REACT_APP_REGION: string;
            REACT_APP_IDENTITY_POOL_REGION: string;
            REACT_APP_USER_POOL_ID: string;
            REACT_APP_USER_POOL_WEBCLIENT_ID: string;
            REACT_APP_API_URL: string;
            REACT_APP_TEST_ENV: string;
            REACT_APP_ONBOARDING_ACTIVE: string;
            REACT_APP_GA_MEASUREMENT_ID: string;
            HRP_MASTER_ID: number;
            HRP_CLEARING_TIME: string;
        };
    }
}

export type Message<T = unknown> = [string, number, string, T];

export type SubaccountLimit = [
    subaccountId: number,
    currencyName: string,
    grossLimit: bigint,
    grossLimitUtilization: bigint,
    maintenanceMargin: number,
    marginCall: number,
    initialMargin: number,
    equity: bigint,
    markup: number,
    tradingDisabled: boolean,
];

export type CounterpartyLimit = [
    counterpartyId: number,
    currencyName: string,
    grossLimit: bigint,
    mutualLimitCurrencyName: string | null,
    mutualGrossLimit: bigint | null,
    grossLimitUtilization: bigint,
    maintenanceMargin: number,
    marginCall: number,
    initialMargin: number,
    equity: bigint,
    takerMarkup: number,
    tradingDisable: number,
];

export enum CounterpartyLimitIndex {
    counterpartyId,
    currencyName,
    grossLimit,
    mutualLimitCurrencyName,
    mutualGrossLimit,
    grossLimitUtilization,
    maintenanceMargin,
    marginCall,
    initialMargin,
    equity,
    takerMarkup,
    tradingDisable,
}

export type CounterpartyLimitByAsset = [
    counterpartyId: number,
    asset: string,
    grossLimit: bigint | null,
    limitCurrency: string | null,
    cpGrossLimit: bigint | null,
    cpLimitCurrency: string | null,
];

export type Level = [price: number | bigint, size: number | bigint];
export type Book = [bidLevels: Level[], askLevels: Level[]];

export type Transaction = [
    counterpartyId: number,
    currencyName: string,
    amount: number,
    settlementOrderId: number,
    comment: string,
    createdAt: string,
    txId: string,
    sentAt: string,
    flags: number,
    transactionMoment: string,
    transaction: number,
    fee: number,
    network: string,
];

export type Key = [key: string, flags: number, createdAt: number, tag: string];

export const ORDER_TYPES = [
    "limit",
    "postOnly",
    "limitIOC",
    "limitFOK",
    "marketIOC",
    "marketFOK",
    "voiceTrade",
    "liquidation",
] as const;
export enum DealSide {
    Buy, //Bid
    Sell, // Ask
}
export type Deal = [
    instrument: string,
    orderType: number,
    side: DealSide,
    cancelReason: number,
    orderId: number,
    clientOrderId: number,
    orderPrice: bigint,
    initialSizeOrVolume: bigint,
    remainingSizeOrVolume: bigint,
    orderCreatedAt: number,
    dealMoment: number,
    dealId: number,
    agressorSide: number,
    price: bigint,
    size: bigint,
    volume: bigint,
    delta: bigint,
    counterpartyId: number,
    bySizeOrByVolume: bigint,
    counterpartySubaccountId: number,
    linkedDealId: number,
];

export const prepareDeal = (deal: Deal) => ({
    instrument: deal[0],
    orderType: deal[1],
    side: deal[2],
    cancelReason: deal[3],
    orderId: deal[4],
    clientOrderId: deal[5],
    orderPrice: BigInt(deal[6] ?? 0n),
    initialSizeOrVolume: BigInt(deal[7] ?? 0n),
    remainingSizeOrVolume: BigInt(deal[8] ?? 0n),
    orderCreatedAt: deal[9],
    dealMoment: deal[10],
    dealId: deal[11],
    aggressorSide: deal[12],
    price: BigInt(deal[13] ?? 0n),
    size: BigInt(deal[14] ?? 0n),
    volume: BigInt(deal[15] ?? 0n),
    delta: BigInt(deal[16] ?? 0n),
    counterpartyId: deal[17],
    bySizeOrByVolume: BigInt(deal[18] ?? 0n),
    counterpartySubaccountId: deal[19],
    linkedDealId: deal[20],
});

export type PreparedDeal = ReturnType<typeof prepareDeal>;

export type Asset = {
    name: string;
    id: number;
    price: number | bigint;
};

export type SettlementRequest = [
    counterpartyId: number,
    currencyName: string,
    flags: number,
    amount: number,
    comment: string,
    expirationTimestamp: number,
    network: string,
    id: number,
    creationTimestamp: number,
];

export const prepareSettlementRequest = (settlementRequest: SettlementRequest) => ({
    counterpartyId: settlementRequest[0],
    currencyName: settlementRequest[1],
    flags: settlementRequest[2],
    amount: BigInt(settlementRequest[3] ?? 0),
    comment: settlementRequest[4],
    expirationTimestamp: settlementRequest[5],
    network: settlementRequest[6],
    id: settlementRequest[7],
    creationTimestamp: settlementRequest[8],
});

export type SettlementTransaction = [
    counterpartyId: number,
    currencyName: string,
    amount: number,
    settlementOrderId: number,
    comment: string,
    createdAt: number,
    txId: string,
    sentAt: number,
    transactionFlags: number,
    transactionMoment: number,
    transaction: number,
    networkFee: number,
    network: string,
];

export type Order = [
    instrumentName: string,
    orderType: number,
    orderSide: number,
    orderCancelReason: number,
    orderId: number,
    clientOrderId: number,
    orderPrice: number,
    initialSize: number,
    sizeLeft: number,
    createdAt: number,
    bySizeOrByVolume: number,
];

export type SettlementOrderBase = {
    settlementOrderId: number | bigint;
    clientId: number;
    counterpartyId: number;
    createdAt: number;
    comment: string;
    flags: number;
    currency1: string;
    size1: number | bigint;
    network1?: string;
    currency2?: string;
    size2?: number | bigint;
    network2?: string;
};

export type SettlementOrderCommitted = SettlementOrderBase & {
    settlementId: number | bigint;
    committedAt: number;
};

export type SettlementOrder = SettlementOrderBase | SettlementOrderCommitted;

export type Position = [currencyName: string, position: bigint, counterpartyId: number];

export const preparePosition = (position: Position) => ({
    currencyName: position[0],
    value: position[1],
    counterpartyId: position[2],
});

export type PositionRequest = Position[] | null;

export interface Overnight {
    currency: string;
    counterparty: number;
    name: string;
    negativeRate: number;
    positiveRate: number;
}

export type Notifications = {
    NotificationRiskManagementInbox: boolean;
    NotificationRiskManagementEmail: boolean;
    NotificationSettlementsInbox: boolean;
    NotificationSettlementsEmail: boolean;
    NotificationSystemInbox: boolean;
    NotificationSystemEmail: boolean;

    ShowRealTimeNotification: boolean;
    EnableSound: boolean;
};

export type FavoriteInstruments = {
    favoriteInstruments: string[];
};

export type TradingAsset = {
    asset: string;
    isReversed: boolean;
};

export type ClientData = {
    city: string;
    clientId: number;
    address: string;
    companyName: string;
    country: string;
    fineryShare: number;
    clientType: "maker" | "taker" | "primeBroker";
    primeBrokerId?: number;
    maker: boolean;
    makerFee: number;
    needAgreement: boolean;
    registrationNumber: string;
    username: string;
    zipCode: string;
    signTnc: boolean;
    subsSignTnc: boolean;
};

export type InviteToken = {
    id: string;
    email: string;
    name: string;
    exp: number;
    type?: string;
    primeBrokerId?: number;
    signTnc: boolean;
    allowChangeInvitedUsername: boolean;
};

export type EmailType = {
    email: string;
    is_copy: boolean;
};

export type BankType = {
    id: number;
    name: string;
};

export type JurisdictionType = {
    id: number;
    name: string;
};

export type OnboardingCurrencyType = {
    id: number;
    name: string;
    venue: string;
    is_fiat: boolean;
    currency_group: {
        name: string;
    };
};

export type TakerInfoType = {
    business_type: string | null;
    monthly_turnover: number | null;
    trade_frequency: string | null;
    settle_frequency: string | null;
    fiat_settlements: boolean | null;
    website?: string | null;
    telegram?: string | null;
    currency: Array<OnboardingCurrencyType>;
    bank: Array<BankType>;
};

export type TakerInfoRequest = {
    business_type: string | null;
    monthly_turnover: number | null;
    trade_frequency: string | null;
    settle_frequency: string | null;
    fiat_settlements: boolean | null;
    website?: string | null;
    telegram?: string | null;
    currency_id: Array<number>;
    bank_id: Array<number>;
};

export type MakerInfo = {
    id: number;
    bank: Array<BankType>;
    bank_request: boolean;
    currency: Array<OnboardingCurrencyType>;
    currency_request: boolean;
    fastest_time: string;
    jurisdiction: Array<JurisdictionType>;
    legal_name: string;
    name: string;
    overnight_allowed: boolean;
    regulation?: string;
    required_time: string;
    settlements: string;
    settlements_auto: boolean;
};

export type BrokerViewType = "counterparties" | "subaccounts";

export type CpId = number;

export type ClientType = "taker" | "maker" | "subaccountMaker" | "subaccountTaker" | "primeBroker";
export enum ClientTypeEnum {
    Taker = "taker",
    Maker = "maker",
    SubaccountMaker = "subaccountMaker",
    SubaccountTaker = "subaccountTaker",
    PrimeBroker = "primeBroker",
}
