import {
    BasicButton,
    CardItem,
    ClientId,
    Flex,
    FormProgress,
    H2,
    Header,
    HeaderTitle,
    HStack,
    Icons,
    PBold,
    ShadowCard,
    ShadowItem,
    Tooltip,
    TooltipContainer,
} from "@fm-frontend/uikit";
import { onMobile } from "@fm-frontend/uikit/src/const";
import { EmDash } from "@fm-frontend/utils";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { setPreopenedCpIdLimit } from "store/preopenedCpIdLimit";
import styled, { useTheme } from "styled-components";
import { toLongDateString } from "utils/format";
import { Subaccount, useDisableSubaccount } from "./api";
import { SUBACCOUNT_TYPE_TITLES } from "./consts";
import { Status } from "./Status";

const FormProgressContainer = styled.div`
    margin-bottom: 16px;
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px 0;

    ${TooltipContainer} {
        overflow: hidden;
        & > ${PBold} {
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 4px;
        }
    }

    ${onMobile} {
        flex-direction: column;
        padding: unset;
        width: 100%;
        gap: 24px;
    }
`;

export const SubaccountDetails: React.VFC<{
    subaccount: Subaccount;
    onBackClick: () => void;
}> = ({ subaccount, onBackClick }) => {
    const { colors } = useTheme();
    const history = useHistory();
    const [status, setStatus] = useState(subaccount.status);
    const [isDisabling, setIsDisabling] = useState(false);
    const deactivateSubaccount = useDisableSubaccount();

    const onDisableSubaccount = (deactivating: boolean, successful: boolean) => {
        setIsDisabling(deactivating);
        if (successful) {
            setStatus("disabled");
        }
    };

    return (
        <div>
            <FormProgressContainer>
                <FormProgress onBackClick={onBackClick}></FormProgress>
            </FormProgressContainer>
            <ShadowCard isDefault>
                <ShadowItem padding="16px 16px 0 16px" isUnderlined>
                    <Header>
                        <HeaderTitle
                            title={
                                <Flex spacing={8} alignItems="center" width="100%">
                                    <H2>{subaccount.info.username}</H2>
                                    {status !== "pending" && <ClientId id={subaccount.info.clientId} />}
                                </Flex>
                            }
                        />
                        <Status variant={status} />
                    </Header>
                </ShadowItem>
                <ShadowItem padding="16px">
                    <CardsContainer>
                        <CardItem width="50%" title="Display name">
                            <PBold>{subaccount.info.username}</PBold>
                        </CardItem>
                        <CardItem width="50%" title="Legal name">
                            <PBold>{subaccount.info.legalName ? subaccount.info.legalName : EmDash}</PBold>
                        </CardItem>
                        <CardItem width="50%" title="Email">
                            <Tooltip content={subaccount.info.email}>
                                <PBold>{subaccount.info.email}</PBold>
                            </Tooltip>
                        </CardItem>
                        {status === "pending" && (
                            <CardItem width="50%" title="Invited">
                                <PBold>{toLongDateString(subaccount.createdAt)}</PBold>
                            </CardItem>
                        )}
                        <CardItem width="50%" title="Type">
                            <PBold>{SUBACCOUNT_TYPE_TITLES[subaccount.type]}</PBold>
                        </CardItem>
                        {subaccount.info.activatedAt && (
                            <CardItem width="50%" title="Activated">
                                <PBold>{toLongDateString(subaccount.info.activatedAt)}</PBold>
                            </CardItem>
                        )}
                        {status === "active" && (
                            <CardItem width="50%" title="Last trade">
                                <PBold>
                                    {subaccount.info.lastTradeAt
                                        ? toLongDateString(subaccount.info.lastTradeAt)
                                        : EmDash}
                                </PBold>
                            </CardItem>
                        )}
                    </CardsContainer>
                </ShadowItem>
                {status === "active" && (
                    <ShadowItem padding="16px">
                        <HStack justifyContent="space-between">
                            <BasicButton
                                size="small"
                                onClick={() => {
                                    setPreopenedCpIdLimit(subaccount.info.clientId!);
                                    history.push("/riskmanagement?viewType=subaccounts");
                                }}
                            >
                                Configure risk profile
                            </BasicButton>
                            <BasicButton
                                size="small"
                                loading={isDisabling}
                                onClick={() =>
                                    deactivateSubaccount(
                                        subaccount.info.clientId,
                                        subaccount.info.username,
                                        onDisableSubaccount,
                                    )
                                }
                            >
                                <Icons.Disabled color={colors.ui52} />
                                Disable account
                            </BasicButton>
                        </HStack>
                    </ShadowItem>
                )}
            </ShadowCard>
            {(subaccount.info.signTnc || subaccount.info.signed) && (
                <ShadowCard isDefault>
                    <ShadowItem padding="16px 16px 0 16px" isUnderlined>
                        <Header>
                            <HeaderTitle
                                title={
                                    <Flex spacing={8} alignItems="center" width="100%">
                                        <H2>Terms and conditions</H2>
                                    </Flex>
                                }
                            />
                        </Header>
                    </ShadowItem>
                    <ShadowItem padding="16px">
                        <CardsContainer>
                            <CardItem width="50%" title="Status">
                                <PBold>{subaccount.info.signed ? "Signed" : "Not signed"}</PBold>
                            </CardItem>
                            <CardItem width="50%" title="Date and time">
                                <PBold>
                                    {subaccount.info.signedAt ? toLongDateString(subaccount.info.signedAt) : EmDash}
                                </PBold>
                            </CardItem>
                            <CardItem width="50%" title="Signatory name">
                                <PBold>{subaccount.info.signatoryName ? subaccount.info.signatoryName : EmDash}</PBold>
                            </CardItem>
                            <CardItem width="50%" title="Position">
                                <PBold>
                                    {subaccount.info.signatoryPosition ? subaccount.info.signatoryPosition : EmDash}
                                </PBold>
                            </CardItem>
                        </CardsContainer>
                    </ShadowItem>
                </ShadowCard>
            )}
        </div>
    );
};
