import { Flex, VStack } from "@fm-frontend/uikit";
import { memo } from "react";
import styled from "styled-components";
import { AssetControlMenu } from "../../AssetControlMenu";
import { useWhitelistingManager } from "../useWhitelistingManager";
import { WhitelistingProvider } from "../whitelistingContext";
import { WhitelistingSwitch } from "../WhitelistingSwitch";
import { SubaccountInstrumentsTable } from "./SubaccountInstrumentsTable";
import { useTableData } from "./SubaccountInstrumentsTable/hooks";

const Container = styled(Flex)`
    overflow: auto;
    min-height: calc(100vh - 87px);

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const SubaccountInstrumentsTabContentView = () => {
    const { isLoading: isInstrumentsDataLoading, instrumentsData } = useTableData();
    const whitelistManager = useWhitelistingManager();

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <CardContainer flex={1} asCard minWidth="445px">
                <WhitelistingProvider value={whitelistManager}>
                    <Flex padding={12} paddingBottom={20} alignItems="flex-start">
                        <AssetControlMenu />
                        <WhitelistingSwitch />
                    </Flex>

                    <SubaccountInstrumentsTable
                        instrumentsData={instrumentsData}
                        isLoading={isInstrumentsDataLoading}
                    />
                </WhitelistingProvider>
            </CardContainer>
        </Container>
    );
};

export const SubaccountInstrumentsTabContent = memo(SubaccountInstrumentsTabContentView);
