import { BITMASK_UTILS } from "@fm-frontend/utils";
import { post } from "api";
import { EMPTY_ARRAY } from "const";
import { useMemo } from "react";
import useSWR from "swr";
import { displayError } from "utils";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "../store/hooks";

const { isBitKeyApplied } = BITMASK_UTILS;

export const useShortSalesBanSWRKey = () => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const side = isPrimeBrokerUser ? primeBrokerViewType : "counterparties";

    return side === "subaccounts" ? "cSubaccountsShortSalesBan" : "cShortSalesBan";
};

type ShortSalesBanInfo = {
    asset: string;
    isUserSetBan: boolean;
    isCpSetBan: boolean;
};
export const useShortSalesBan = (counterpartyId?: number) => {
    const fetcher = (url: string) => post(url, {});
    const apiMethod = useShortSalesBanSWRKey();
    const { data, mutate, isLoading } = useSWR(apiMethod, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY,
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    const shortSalesBans: Record<number, ShortSalesBanInfo[]> = useMemo(() => {
        if (Array.isArray(data)) {
            return data.reduce((acc, [cpId, asset, flags]) => {
                const isUserSetBan = isBitKeyApplied(flags, 1);
                const isCpSetBan = isBitKeyApplied(flags, 1 << 1);

                if (cpId in acc) {
                    acc[cpId].push({ asset, isUserSetBan, isCpSetBan });
                } else {
                    acc[cpId] = [{ asset, isUserSetBan, isCpSetBan }];
                }

                return acc;
            }, {} as Record<number, ShortSalesBanInfo[]>);
        }

        return {};
    }, [data, counterpartyId]);

    return {
        shortSalesBans,
        isLoading,
        mutate,
    };
};
