import {
    ACTIONS_TABLE_COLUMN_KEY,
    Badge,
    BadgeVariant,
    ClientId,
    HStack,
    PBold,
    TruncatedText,
} from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { CopyToClipboard } from "components/CopyToClipboard";
import { EmDash } from "const";
import { fmt } from "utils/format";
import { ReferralsAction } from "./ReferralsAction";
import { AliasStatus, InviteStatus, MakerInvitesType, OnboardingStatus } from "./types";

export const REFERRALS_MODAL_KEY = Symbol('REFERRALS_MODAL_KEY');

const VARIANT_MAP: Record<InviteStatus | OnboardingStatus, BadgeVariant> = {
    Accepted: "process",
    Sent: "attention",
    Expired: "info",
    "LP choosing": "process",
    "LP verification": "process",
    Joined: "positive",
};

const INVITE_STATUS_ALIAS = {
    Sent: "Link sent",
    Accepted: "Data filling",
};
const getStatusAlias = (status: string): string | undefined => {
    return status in INVITE_STATUS_ALIAS ? INVITE_STATUS_ALIAS[status as AliasStatus] : undefined;
};

export const referralsColumnHelper = createColumnHelper<MakerInvitesType>();
export const referralsTableColumns = [
    referralsColumnHelper.accessor((row) => row.email, {
        id: "email",
        cell: (subject) => {
            const email = subject.getValue();
            return (
                <CopyToClipboard value={email}>
                    <TruncatedText text={email} maxLength={28} />
                </CopyToClipboard>
            );
        },
        header: () => "Email",
        meta: {
            cellStyleProps: {
                padding: 0,
            },
            headerStyleProps: {
                padding: [0, 0, 8, 0],
            },
        },
    }),
    referralsColumnHelper.accessor((row) => row, {
        id: "cp",
        cell: (subject) => {
            const { userId, name: username } = subject.getValue();
            const text = username || EmDash;
            return (
                <HStack spacing={4}>
                    <TruncatedText text={text} renderWrapper={(value) => <PBold>{value}</PBold>} />
                    {text !== EmDash && <ClientId id={userId} />}
                </HStack>
            );
        },
        header: () => "CP",
    }),
    referralsColumnHelper.accessor((row) => row.onboardingStatus ?? row.status, {
        id: "status",
        cell: (subject) => {
            const status = subject.getValue();

            return <Badge variant={VARIANT_MAP[status]} value={getStatusAlias(status) ?? status} />;
        },
        header: () => "Status",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    referralsColumnHelper.accessor((row) => row.invitation, {
        id: "invitation",
        cell: (subject) => {
            const invitation = subject.getValue();
            return fmt(invitation, { type: "timems" }).formattedValue;
        },
        header: () => "Invitation",
        meta: {
            cellStyleProps: {
                padding: [0, 0, 0, 12],
                textAlign: "right",
            },
            headerStyleProps: {
                padding: [0, 0, 8, 12],
                textAlign: "right",
            },
        },
    }),
    referralsColumnHelper.display({
        id: ACTIONS_TABLE_COLUMN_KEY,
        header: "",
        cell: (info) => (
            <ReferralsAction
                status={info.row.original.status}
                userId={info.row.original.userId}
                referralEmail={info.row.original.email}
            />
        ),
        meta: {
            cellStyleProps: {
                width: "0",
                padding: [0, 0, 0, 12],
            },
        },
    }),
];
