import {
    useIsPrimeBrokerUser,
    useIsSubaccountUser,
    useIsTakerUser,
    usePrimeBrokerViewType,
} from "store/hooks";

export const useIsShortSalesBanAllowed = () => {
    const isSubaccountUser = useIsSubaccountUser();
    const isTakerUser = useIsTakerUser();

    return !isSubaccountUser && !isTakerUser;
};

export const useGetOvernightFeesAllowedForCP = () => {
    const isSubaccountUser = useIsSubaccountUser();
    const isTakerUser = useIsTakerUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    return (isTakerCp: boolean) => {
        if (
            isSubaccountUser ||
            isTakerUser ||
            (isPrimeBrokerUser && primeBrokerViewType === "counterparties" && !isTakerCp)
        ) {
            return false;
        }

        return true;
    };
};
