import { H1, ShadowCard } from "@fm-frontend/uikit";
import { onTablet } from "const";
import React, { useState } from "react";
import styled from "styled-components";
import { Subaccount } from "./api";
import { SubaccountDetails } from "./SubaccountDetails";
import { Subaccounts } from "./Subaccounts";
import { FilterContextProvider } from "./SubaccountsFilterContext";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
    margin-top: 18px;
    padding: 0 12px;

    ${H1} {
        line-height: 26px;
    }

    ${onTablet} {
        position: relative;
        left: unset;
    }
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 600px;

    ${ShadowCard} {
        margin-bottom: 12px;
    }
`;

export const AbsolutePositionedTitle = styled(H1)`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    ${onTablet} {
        position: relative;
        left: unset;
        transform: unset;
    }
`;

const ShifterContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const SubaccountsPageContent: React.FC = () => {
    const [selectedSubaccount, setSelectedSubaccount] = useState<Subaccount | null>(null);

    return (
        <Container>
            <ShifterContainer>
                <Content>
                    <FilterContextProvider>
                        {selectedSubaccount ? (
                            <SubaccountDetails
                                subaccount={selectedSubaccount}
                                onBackClick={() => setSelectedSubaccount(null)}
                            />
                        ) : (
                            <Subaccounts onSubaccountSelect={setSelectedSubaccount} />
                        )}
                    </FilterContextProvider>
                </Content>
            </ShifterContainer>
        </Container>
    );
};
