import { HStack, PBold } from "@fm-frontend/uikit";
import { InstrumentWhitelistingSwitch } from "feature/assetsControl/components/InstrumentsTabContent/InstrumentWhitelistingSwitch";
import { useWhitelistingContext } from "feature/assetsControl/components/InstrumentsTabContent/whitelistingContext";
import { getLabelWithHighlightedSearch } from "feature/settlements";
import { Instrument } from "hooks/useInstruments";
import styled from "styled-components";
import { InstrumentIcon } from "../../../InstrumentIcon";
import { useSearchQuery } from "../../../searchQueryContext";

const Container = styled(HStack)`
    white-space: nowrap;
    flex-wrap: nowrap;
    height: 100%;

    align-items: center;
    padding: 0 12px;
`;

const InstrumentContainer = styled(HStack)<{ $isWhitelistingOn: boolean; $enabled: boolean }>`
    gap: 6px;
    opacity: ${(p) => (p.$isWhitelistingOn && !p.$enabled ? 0.52 : 1)};
`;

const SwitchWrapper = styled.div`
    margin-left: auto;
`;

export const InstrumentNameCell = ({ instrument }: { instrument: Instrument }) => {
    const { instrumentName } = instrument;
    const searchQuery = useSearchQuery();
    const { isWhitelistingOn, isInstrumentInWhitelist } = useWhitelistingContext();
    const isWhitelisted = isInstrumentInWhitelist(instrumentName);

    return (
        <Container>
            <InstrumentContainer $isWhitelistingOn={isWhitelistingOn} $enabled={isWhitelisted}>
                <InstrumentIcon {...instrument} />
                <PBold>{getLabelWithHighlightedSearch(instrument.instrumentName, searchQuery)}</PBold>
            </InstrumentContainer>
            <SwitchWrapper onClick={(ev) => ev.stopPropagation()}>
                <InstrumentWhitelistingSwitch instrumentName={instrumentName} />
            </SwitchWrapper>
        </Container>
    );
};
