import { HStack, Icons, PSmall, PSmallBold } from "@fm-frontend/uikit";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useUsdAmount } from "hooks/useUsdAmount";
import { useMemo } from "react";
import { useFilteredAndExtendedPositionsSnapshot, useFilteredAndExtendedSettlementRequestsSnapshot } from "store/hooks";
import styled, { useTheme } from "styled-components";
import { getFmtPrecisionConfig } from "utils";
import { fmt, getAbsoluteValue } from "utils/format";
import { Amount, AssetAmount, Empty, HintContainer, HintContent, IconContainer, Loading, UsdAmount } from "../styled";
import { Hint } from "../types";

const Network = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export const IncomingRequests = ({ onRequestSelected }: { onRequestSelected: (requestInfo: Hint) => void }) => {
    const { colors } = useTheme();
    const { getUsdAmount, isLoading: isUsdAmountLoading } = useUsdAmount();
    const { data: filteredExtendedPositionsSnapshot, isLoading: isPositionsLoading } =
        useFilteredAndExtendedPositionsSnapshot();
    const { data: filteredAndExtendedRequests, isLoading: isWsRequestsLoading } =
        useFilteredAndExtendedSettlementRequestsSnapshot();
    const { getCpName } = useCpInfoHelpers();
    const isLoading = isPositionsLoading || isWsRequestsLoading || isUsdAmountLoading;
    const incomingRequests = useMemo(() => {
        if (isLoading) {
            return [];
        }

        return filteredAndExtendedRequests[1].map(({ settlementRequest }) => {
            const [cpId, asset, , amount, , , network] = settlementRequest;
            const isAllAvailable = !amount;

            let requestAmount = BigInt(amount);
            if (isAllAvailable) {
                const extendedPosition = filteredExtendedPositionsSnapshot.find(
                    ({ position: [pAsset, , pCpId] }) => pCpId === cpId && pAsset === asset,
                );
                if (extendedPosition !== undefined) {
                    const positionAmount = getAbsoluteValue(extendedPosition.position[1]);
                    requestAmount = BigInt(positionAmount);
                }
            }

            return {
                cpId,
                cpName: getCpName(cpId, "short"),
                asset,
                amount: requestAmount,
                usdAmount: getUsdAmount(asset, requestAmount),
                network,
                isAllAvailable,
            };
        });
    }, [filteredAndExtendedRequests, getCpName, getUsdAmount, isLoading]);

    if (isLoading) {
        return <Loading />;
    }

    if (incomingRequests.length === 0) {
        return <Empty message="No active requests" />;
    }

    return (
        <>
            {incomingRequests.map((requestInfo) => {
                const { cpName, asset, amount, usdAmount, network, isAllAvailable } = requestInfo;

                return (
                    <HintContainer key={`${cpName} ${asset}`} onClick={() => onRequestSelected(requestInfo)}>
                        <HintContent>
                            <HStack spacing={4}>
                                <PSmallBold>{cpName},</PSmallBold>
                                <PSmall>{asset}</PSmall>
                                {network && <Network>{network}</Network>}
                            </HStack>
                            <Amount>
                                {isAllAvailable && "All available"}
                                {!isAllAvailable && (
                                    <>
                                        <AssetAmount>
                                            {fmt(-amount, {
                                                significantDigits: 8,
                                                type: "size64",
                                                unit: ` ${asset}`,
                                                unitPosition: "post",
                                                ...getFmtPrecisionConfig(asset),
                                            }).formattedValue}
                                        </AssetAmount>
                                        <UsdAmount>
                                            {fmt(usdAmount, {
                                                significantDigits: 2,
                                                type: "size64",
                                                unit: "$",
                                                unitPosition: "pre",
                                            }).formattedValue}
                                        </UsdAmount>
                                    </>
                                )}
                            </Amount>
                        </HintContent>
                        <IconContainer>
                            <Icons.ArrowReply color={colors.ui32} />
                        </IconContainer>
                    </HintContainer>
                );
            })}
        </>
    );
};
