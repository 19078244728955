import { BasicButton, Modal } from "@fm-frontend/uikit";
import { FileContextProvider } from "@fm-frontend/uikit/src/components/dropZone/FileContext";
import { useFormShouldCloseConfirm, useModalControl } from "@fm-frontend/utils";
import { FIXED_MODAL_STYLE } from "const/modal";
import { useLimitByAssetSWRKey } from "hooks/useExtentedLimitsByAsset";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { useShortSalesBanSWRKey } from "hooks/useShortSalesBan";
import { FC, useCallback } from "react";
import styled from "styled-components";
import { useSWRConfig } from "swr";
import { BULK_EDIT_ASSET_CONTROL_MODAL_KEY } from "../../utils";
import { AssetsBulkEditModal } from "../AssetsBulkEditModal";

const StyledBasicButton = styled(BasicButton)`
    margin-right: 6px;
`;

type AssetsBulkEditButtonProps = {
    disabled: boolean;
};

export const AssetsBulkEditButton: FC<AssetsBulkEditButtonProps> = ({ disabled }) => {
    const { mutate } = useSWRConfig();
    const limitByAssetSWRKey = useLimitByAssetSWRKey();
    const shortSalesBanSWRKey = useShortSalesBanSWRKey();
    const { isModalOpen, openModal, closeModal } = useModalControl(false);

    const handleModalClose = useCallback(() => {
        mutate(limitByAssetSWRKey);
        mutate(shortSalesBanSWRKey);
        closeModal();
    }, [limitByAssetSWRKey, shortSalesBanSWRKey, mutate, closeModal]);

    const shouldConfirmClose = useFormShouldCloseConfirm(BULK_EDIT_ASSET_CONTROL_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(
        shouldConfirmClose,
        handleModalClose,
    );

    return (
        <>
            <StyledBasicButton size="small" onClick={openModal} disabled={disabled}>
                Bulk edit...
            </StyledBasicButton>
            <Modal isOpen={isModalOpen} onClose={closeModalWithConfirm} style={FIXED_MODAL_STYLE}>
                <FileContextProvider>
                    <AssetsBulkEditModal onCancel={closeModalWithConfirm} />
                </FileContextProvider>
            </Modal>
        </>
    );
};
