import { ValidationMessages } from "utils/validationMessages";
import { validators } from "utils/validators";
import { AnySchema, object, ref, string } from "yup";
import { Inputs as AccountInputs } from "./AccountData";
import { Inputs as ConfirmTqoFaInputs } from "./ConfirmTwoFA";

const MIN_PASSWORD_LENGTH = 8;

export const accountDataSchema = object<Record<keyof AccountInputs, AnySchema>>().shape({
    companyName: validators.username.yupValidator,
    password: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim())
        .min(MIN_PASSWORD_LENGTH, "Must be at least 8 characters")
        .matches(/\d/, "Must contain at least 1 digit")
        .matches(/[A-Z]/, "Must contain at least 1 upper case")
        .matches(/[a-z]/, "Must contain at least 1 lower case")
        .matches(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Must contain at least 1 special character"),
    confirmPassword: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim())
        .oneOf([ref("password"), null], "Passwords must match"),
});

export const confirmTwoFaDataSchema = object<Record<keyof ConfirmTqoFaInputs, AnySchema>>().shape({
    code: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim())
        .matches(/^\d{6}$/, "Code must be 6-digit"),
});
