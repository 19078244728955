import { HStack, PBold } from "@fm-frontend/uikit";
import { getLabelWithHighlightedSearch } from "feature/settlements";
import { Instrument } from "hooks/useInstruments";
import styled from "styled-components";
import { InstrumentIcon } from "../../../InstrumentIcon";
import { useSearchQuery } from "../../../searchQueryContext";
import { useWhitelistingContext } from "../../../whitelistingContext";

const Container = styled(HStack)`
    gap: 6px;
    white-space: nowrap;
    flex-wrap: nowrap;
`;

const InstrumentContainer = styled(HStack)<{ $isWhitelistingOn: boolean; $enabled: boolean }>`
    gap: 6px;
    opacity: ${(p) => (p.$isWhitelistingOn && !p.$enabled ? 0.52 : 1)};
`;

export const InstrumentNameCell = ({ instrument }: { instrument: Instrument }) => {
    const searchQuery = useSearchQuery();
    const { instrumentName } = instrument;
    const { isWhitelistingOn, isInstrumentInWhitelist } = useWhitelistingContext();
    const isWhitelisted = isInstrumentInWhitelist(instrumentName);

    return (
        <Container>
            <InstrumentContainer $isWhitelistingOn={isWhitelistingOn} $enabled={isWhitelisted}>
                <InstrumentIcon {...instrument} />
                <PBold>{getLabelWithHighlightedSearch(instrument.instrumentName, searchQuery)}</PBold>
            </InstrumentContainer>
        </Container>
    );
};
