import { delay, select, take, takeLatest } from "@redux-saga/core/effects";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { authWSRequest } from "api";
import { wsUrl } from "const";
import { authSlice } from "feature/auth";
import { actionChannel } from "store/actionChannel";
import { setIsTermsAndConditionsRequired, setOnboardingStages } from "store/onboardingSlice";
import { parse, stringify } from "utils/json";
import {
    wsNotificationRead,
    wsNotificationReadAll,
    wsNotificationsBind,
    wsNotificationsLoadMore,
    wsNotificationsRequestAuth,
    wsNotificationsReset,
} from "./wsNotifications";

const handleMessage = ({ data }: MessageEvent<any>) => {
    // should be fixed in json-bigint package, proto is not being set correctly => isPlainObject stops working
    const message = { ...parse(data) };
    actionChannel.put(message);
};

const timeouts = [0, 0, 1, 2, 3, 5].map((t) => t * 1000);
function* wsNotificationsSaga() {
    let retryCount = 0;
    do {
        const workerNotifications = new Worker(new URL("./wsNotifications.worker", import.meta.url));

        // awaiting signed T&C
        const { isTermsAndConditionsRequired } = yield select((state) => state.onboarding.onboardingStages);
        if (isTermsAndConditionsRequired) {
            workerNotifications.postMessage(stringify({ type: "close" }));

            yield take([
                setOnboardingStages,
                setIsTermsAndConditionsRequired,
            ]);
        }

        workerNotifications.postMessage(stringify({
            type: "init",
            url: `${wsUrl}/notification_center/ws`,
        }));

        const postNotificationMessage = (message: unknown) => workerNotifications.postMessage(stringify(message));
        workerNotifications.addEventListener("message", handleMessage);

        yield takeLatest(wsNotificationsRequestAuth, async () => {
            const userSession = (await Auth.currentSession()) as CognitoUserSession;
            const key = { token: userSession?.getAccessToken().getJwtToken() };
            postNotificationMessage(authWSRequest(JSON.stringify(key)));
        });
        yield takeLatest(wsNotificationsBind, function () {
            postNotificationMessage({ event: "bind", feed: "NM" });
        });
        yield takeLatest(wsNotificationRead, function ({ payload: notificationId }) {
            postNotificationMessage({
                event: "request",
                method: "set_read",
                content: {
                    message_id: notificationId,
                },
            });
        });
        yield takeLatest(wsNotificationReadAll, () => {
            postNotificationMessage({
                event: "request",
                method: "set_readall",
                content: {},
            });
        });
        yield takeLatest(wsNotificationsLoadMore, function ({ payload: lastNotificationId }) {
            postNotificationMessage({
                event: "request",
                method: "get_messages",
                content: {
                    limit: 50,
                    until_id: lastNotificationId,
                },
            });
        });
        yield take(wsNotificationsReset);
        workerNotifications.terminate();

        const timeout = timeouts[retryCount] || 5000;
        if (retryCount < 5) retryCount += 1;
        yield delay(timeout);
    } while (true);
}

export function* wsNotificationsServiceSaga() {
    yield takeLatest(authSlice.actions.setAuthCognito, wsNotificationsSaga);
}
