import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { useFormShouldCloseConfirm, useModalControl } from "@fm-frontend/utils";
import {
    AdjustType,
    AssetsGroupType,
    DEFAULT_MODAL_CONFIG,
    ModalConfig,
} from "feature/assetsControl/types";
import { ASSET_CONTROL_MODAL_KEY } from "feature/assetsControl/utils";
import { useCounterparties } from "hooks";
import { useExtendedLimitsByAsset, useLimitByAssetSWRKey } from "hooks/useExtentedLimitsByAsset";
import { useOvernightFeesSWRKey } from "hooks/useOvernightFees";
import { useShortSalesBanSWRKey } from "hooks/useShortSalesBan";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { useCallback, useMemo, useState } from "react";
import { useSWRConfig } from "swr";
import { AssetsModal } from "./AssetsModal";
import { AssetsTabContextProvider } from "./assetsTabContext";
import { TableContent } from "./TableContent";

export const AssetsTabContent = () => {
    const { mutate } = useSWRConfig();
    const overnightCostSWRKey = useOvernightFeesSWRKey();
    const limitByAssetSWRKey = useLimitByAssetSWRKey();
    const shortSalesBanSWRKey = useShortSalesBanSWRKey();
    const { closeModal, isModalOpen: isOpened, openModal } = useModalControl(false);
    const [modalConfig, setModalConfig] = useState<ModalConfig>(DEFAULT_MODAL_CONFIG);
    const { counterparties } = useCounterparties();
    const counterpartiesOptions = useMemo(() => {
        return Object.entries(counterparties)
            .sort(([, left], [, right]) => left?.localeCompare(right ?? "") ?? 0)
            .map(
                ([cpId, name]) =>
                    ({
                        value: cpId,
                        text: name,
                    } as DropdownOption),
            );
    }, [counterparties]);

    const onRefreshData = useCallback(
        (adjust: AdjustType) => {
            let mutateKeys = null;

            if (adjust === "tradingLimits") {
                mutateKeys = limitByAssetSWRKey;
            }
            if (adjust === "shortSalesBan") {
                mutateKeys = shortSalesBanSWRKey;
            }
            if (adjust === "overnightFees") {
                mutateKeys = overnightCostSWRKey;
            }

            mutate(mutateKeys);
        },
        [limitByAssetSWRKey, shortSalesBanSWRKey, overnightCostSWRKey],
    );

    const closeModalWithAdjust = (adjust?: AdjustType) => {
        if (adjust) {
            onRefreshData(adjust);
        }
        closeModal();
    };

    const { limitsByAssets } = useExtendedLimitsByAsset();
    const handleCpRowEditClick = ({
        asset,
        cpName,
        cpId,
    }: {
        asset: string;
        cpName: string;
        cpId: number;
    }) => {
        const assetLimit = limitsByAssets?.find(
            ({ asset: assetName, counterpartyId }) =>
                assetName === asset && counterpartyId === cpId,
        );

        setModalConfig({
            ...DEFAULT_MODAL_CONFIG,
            adjust: "tradingLimits",
            assets: asset,
            cp: String(cpId ?? ""),
            cpName,
            grossLimit: assetLimit?.grossLimit,
            limitCurrency: assetLimit?.limitCurrency,
        });
        openModal();
    };

    const handleAssetRowEditClick = (asset: string) => {
        setModalConfig({
            ...DEFAULT_MODAL_CONFIG,
            assets: asset,
        });
        openModal();
    };

    const handleConfigureButtonClick = (selectedAssetsGroupFilter: AssetsGroupType) => {
        setModalConfig({
            ...DEFAULT_MODAL_CONFIG,
            assets: selectedAssetsGroupFilter,
        });
        openModal();
    };

    const shouldConfirmClose = useFormShouldCloseConfirm(ASSET_CONTROL_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldConfirmClose, closeModal);

    return (
        <AssetsTabContextProvider
            value={{ handleCpRowEditClick, handleAssetRowEditClick, handleConfigureButtonClick }}
        >
            <TableContent />
            <AssetsModal
                isOpened={isOpened}
                onSubmit={closeModalWithAdjust}
                onClose={closeModalWithConfirm}
                modalConfig={modalConfig}
                counterparties={counterpartiesOptions}
            />
        </AssetsTabContextProvider>
    );
};
