import { useInstruments } from "hooks";
import { Loading } from "../styled";
import { BankAccountHints } from "./BankAccountHints";
import { CryptoAddressHints } from "./CryptoAddressHints";
import { HintsLayout } from "./HintsLayout";

export const AddressHints = ({ cpId, asset, network }: { cpId: number; asset: string; network?: string }) => {
    const { currencies, isLoading } = useInstruments();

    if (isLoading) {
        return (
            <HintsLayout>
                <Loading />
            </HintsLayout>
        );
    }

    const isFiatAsset = currencies[asset].currencyType === "fiat";

    return isFiatAsset ? (
        <BankAccountHints asset={asset} />
    ) : (
        <CryptoAddressHints cpId={cpId} asset={asset} network={network} />
    );
};
