import React from "react";
import { LogoWidget } from "./logoWdget";

import { SlimContainer } from "./loginPage.styled";

export const SlimPage: React.FC<{ isExpanded?: boolean }> = ({ children, isExpanded }) => (
    <SlimContainer isExpanded={isExpanded}>
        <LogoWidget />
        {children}
    </SlimContainer>
);
