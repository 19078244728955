import {
    DesctructiveButton,
    HStack,
    Icons,
    linkStyles,
    PlainButton,
    PrimaryButton,
    PSmall,
    SimpleInput,
    Switch,
    Tab,
    TabContext,
    TabList,
    TabPanel,
    TextSmall,
} from "@fm-frontend/uikit";
import { getClickHelper } from "@fm-frontend/uikit/src/components/helper";
import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useFormCloseConfirmer } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { post } from "api";
import { CircularProgressbar } from "components/CircularProgressbar";
import { CoinIcon } from "components/CoinIcon";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { PREFERRED_CURRENCIES, SEARCH_PARAMS } from "const";
import { createNotification } from "feature/app";
import { ROUTES } from "feature/app/router";
import { fetchCounterpartiesInfo } from "feature/app/store/counterpartiesInfoFetcher";
import { FormError } from "feature/form/style";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useIsMakerUser, useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import { useCurrenciesWithPreferred } from "store/useCurrenciesWithPreferred";
import { Spacer } from "style";
import styled, { useTheme } from "styled-components";
import { CounterpartyLimit } from "types";
import { getCurrencyPlaceholder, getFmtPrecisionConfig } from "utils";
import EfxTypes from "utils/EfxTypes";
import { fmt, fmtDeltaratePercent, formatPrecision } from "utils/format";
import { FMT_LIMIT_CONFIG } from "../counterparty/utils";
import { FieldGrouper, GroupSwitch } from "../styled";
import {
    getFreeLimit,
    isSubaccountCp,
    isTakerCp,
    LIMIT_FORMAT_REGEX,
    MARGIN_PLACEHOLDER,
    parseLimit,
    PERCENT_FORMAT_REGEX,
} from "../utils";
import { Break } from "./Break";
import { CounterpartyInfo } from "./counterpartyInfo/CounterpartyInfo";
import { EnableTrading } from "./enableTrading/EnableTrading";
import { LimitsByAsset } from "./limitsByAsset";
import { MakerRiskOverview } from "./markerRiskOverview/MakerRiskOverview";
import {
    getFormInfobox,
    getLimitLabel,
    getLimitLabelHelper,
    getMarginLabelHelper,
} from "./messages";
import { NotificationMessage } from "./NotificationMessage";
import { schema } from "./schema";
import * as Styled from "./styled";
import { Indicator, Inputs, Props } from "./types";
import {
    ASSETS_CONTROL_TAB,
    COUNTERPARTY_LIMIT_MODAL_KEY,
    FMT_PERCENT_CONFIG,
    getFmtModalIndicatorConfig, getMarginsAutocomplete,
    getRequestBodyForNotSubaccount,
    getRequestBodyForSubaccount,
    TRADING_LIMITS_TAB
} from "./utils";

const DeleteButton = styled(DesctructiveButton)`
    background: ${(p) => p.theme.colors.ui8};
    border: none;
    box-shadow: none;
    margin-bottom: 8px;
`;

const GroupSwitchLabel = styled.div`
    display: flex;
    gap: 5px;
`;

const Label = styled(TextSmall)`
    display: flex;
    gap: 8px;
`;

const StyledLink = styled(Link)`
    ${linkStyles}
`;

// TODO Rethink this component and split it in better readable ones
/*
    There are different cases for a usages of the modal.
    It should not be opened for subaccount users (subaccounts cannot create limits at all)
    1. For creating new limit (limit prop is empty):
        - if the user is master then initially he will input cpId in previous modal and here we will
          have cpData prop with id and type of the counterparty, so couterpartyId field is disabled
          and success creating modal will opened after limit creation
        - maker and taker users will see only this modal, so cpData will be empty
    2. For editing existed limit (limit prop is NOT empty):
        - cpData should be passed for all user types
*/
export const CounterpartyLimitModal: React.FC<Props> = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const {
        cpData,
        title,
        limit,
        status,
        onSubmit,
        onClose,
        onDelete,
        defaultTab = TRADING_LIMITS_TAB,
        error: outerError,
        deleting: isDeleting = false,
    } = props;
    const { cpId, cpType, cpName } = cpData;
    const isNewLimit = !limit;

    const currencies = useCurrenciesWithPreferred();
    const currenciesOptions = useMemo(
        () =>
            currencies.map((currency) => ({
                ...currency,
                icon: <CoinIcon coin={currency.value} size={16} />,
            })),
        [currencies],
    );

    const primeBrokerViewType = usePrimeBrokerViewType();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isMakerUser = useIsMakerUser();
    const userHasMakerRoleInLimit =
        isMakerUser || (isPrimeBrokerUser && (cpType === "taker" || isSubaccountCp(cpType)));
    const isMasterToMasterRelation = isPrimeBrokerUser && cpType === "primeBroker";

    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        if (outerError) {
            setError(outerError);
        }
    }, [outerError]);

    const [tab, setTab] = useState(defaultTab);

    const getDefaultFormValue = (newLimit: CounterpartyLimit | undefined) => {
        if (!newLimit) {
            return {
                counterpartyId: String(cpId),
                currency: PREFERRED_CURRENCIES[0],
            };
        }

        const {
            counterpartyId,
            currency,
            grossLimit,
            restrictedTrading,
            initialMargin,
            maintenanceMargin,
            markupValue,
        } = parseLimit(newLimit);

        const isMarginRequirementEnabled = Boolean(
            maintenanceMargin || restrictedTrading || initialMargin,
        );
        const isMarkupEnabled = Boolean(markupValue);

        return {
            currency: currency,
            counterpartyId: String(counterpartyId),
            grossLimit: fmt(grossLimit, {
                ...FMT_LIMIT_CONFIG,
                ...getFmtPrecisionConfig(currency),
            }).copyableValue,
            isMarginActive: userHasMakerRoleInLimit && isMarginRequirementEnabled,
            initialMargin: isMarginRequirementEnabled
                ? fmtDeltaratePercent(initialMargin, FMT_PERCENT_CONFIG).copyableValue
                : undefined,
            restrictedTrading: isMarginRequirementEnabled
                ? fmtDeltaratePercent(restrictedTrading, FMT_PERCENT_CONFIG).copyableValue
                : undefined,
            maintenanceMargin: isMarginRequirementEnabled
                ? fmtDeltaratePercent(maintenanceMargin, FMT_PERCENT_CONFIG).copyableValue
                : undefined,
            isMarkupActive: isMarkupEnabled,
            markup: isMarkupEnabled
                ? fmtDeltaratePercent(markupValue, FMT_PERCENT_CONFIG).copyableValue
                : undefined,
        };
    };

    const {
        control,
        register,
        handleSubmit,
        watch,
        trigger,
        setValue,
        getFieldState,
        formState: { errors, isSubmitting, defaultValues, isDirty },
    } = useForm<Inputs>({
        mode: "onSubmit",
        defaultValues: getDefaultFormValue(limit),
        resolver: yupResolver(schema),
    });

    useFormCloseConfirmer(COUNTERPARTY_LIMIT_MODAL_KEY, isNewLimit || isDirty);

    const newGrossLimit = watch("grossLimit");
    const selectedCurrency = watch("currency");
    const newMaintenanceMargin = watch("maintenanceMargin");
    const newRestrictedTrading = watch("restrictedTrading");
    const newInitialMargin = watch("initialMargin");
    const isMarginActive = watch("isMarginActive");
    const isMarkupActive = watch("isMarkupActive");

    const [freeLimitIndicator, setFreeLimitIndicator] = useState<Indicator | null>(() => {
        if (!limit) {
            return null;
        }

        const { grossLimit, grossLimitUtilization } = parseLimit(limit);
        const { freeLimit, freeLimitPercent } = getFreeLimit(grossLimit, grossLimitUtilization);
        const { copyableValue: rawPercent, formattedValue: percent } = fmtDeltaratePercent(
            freeLimitPercent,
            FMT_PERCENT_CONFIG,
        );

        return {
            title: "Gross free",
            rawPercent,
            percent,
            value: fmt(freeLimit, getFmtModalIndicatorConfig(selectedCurrency)).formattedValue,
        };
    });

    useEffect(() => {
        if (!PERCENT_FORMAT_REGEX.test(String(newMaintenanceMargin))) {
            return;
        }

        const { restrictedTrading: autoRestrictedTrading, initialMargin: autoInitialMargin } =
            getMarginsAutocomplete(Number(newMaintenanceMargin));
        const { isTouched: isRestrictedTradingTouched } = getFieldState("restrictedTrading");
        const { isTouched: isInitialMarginTouched } = getFieldState("initialMargin");

        if (!isInitialMarginTouched && !defaultValues?.initialMargin) {
            setValue("initialMargin", formatPrecision(autoInitialMargin));
        }
        if (!isRestrictedTradingTouched && !defaultValues?.restrictedTrading) {
            setValue("restrictedTrading", formatPrecision(autoRestrictedTrading));
        }
    }, [newMaintenanceMargin]);

    useEffect(() => {
        if (newMaintenanceMargin && newRestrictedTrading && newInitialMargin) {
            trigger(["maintenanceMargin", "restrictedTrading", "initialMargin"]);
        }
    }, [newMaintenanceMargin, newRestrictedTrading, newInitialMargin]);

    useEffect(() => {
        if (!LIMIT_FORMAT_REGEX.test(String(newGrossLimit)) || !limit || !freeLimitIndicator) {
            return;
        }

        const { grossLimitUtilization } = parseLimit(limit);
        const parsedGrossLimit = BigInt(EfxTypes.parseValue(newGrossLimit, "limit"));
        const { freeLimit, freeLimitPercent } = getFreeLimit(
            parsedGrossLimit,
            grossLimitUtilization,
        );
        const { copyableValue: rawPercent, formattedValue: percent } = fmtDeltaratePercent(
            freeLimitPercent,
            FMT_PERCENT_CONFIG,
        );

        setFreeLimitIndicator({
            ...freeLimitIndicator,
            rawPercent,
            percent,
            value: fmt(freeLimit, getFmtModalIndicatorConfig(selectedCurrency)).formattedValue,
        });
    }, [newGrossLimit, limit, selectedCurrency]);

    const submitHandler: SubmitHandler<Inputs> = async (values) => {
        const isSubaccount = isSubaccountCp(cpType);
        const apiMethod = isSubaccount ? "setSubaccountLimit" : "setCLimit";
        const params = (
            isSubaccount ? getRequestBodyForSubaccount : getRequestBodyForNotSubaccount
        )(values, {
            isNewLimit,
            isMarginActive,
            isMarkupActive,
        });

        try {
            await post(apiMethod, params);

            dispatch(fetchCounterpartiesInfo(cpId));

            const { grossLimit, currency } = isSubaccount
                ? (params as ReturnType<typeof getRequestBodyForSubaccount>).limits
                : (params as ReturnType<typeof getRequestBodyForNotSubaccount>);

            // this delay gives a chance to load new data
            setTimeout(() => dispatch(
                createNotification({
                    type: "success",
                    content: (
                        <NotificationMessage
                            isNewLimit={isNewLimit}
                            grossLimit={grossLimit}
                            currency={currency}
                            cpId={cpId}
                            cpType={cpType}
                        />
                    ),
                }),
            ), 100);

            onSubmit?.();
        } catch (e) {
            setError(String(e));
        }
    };

    const handleMarginActiveChange = () => {
        setValue("isMarginActive", !isMarginActive, { shouldDirty: true });
    };

    const handleMarkupActiveChange = () => {
        setValue("isMarkupActive", !isMarkupActive, { shouldDirty: true });
    };

    return (
        <>
            <TabContext value={tab} handleClick={setTab}>
                {!isNewLimit && (
                    <TabList size="small">
                        <Tab title="Trading settings" value={TRADING_LIMITS_TAB} />
                        <Tab title="Assets control" value={ASSETS_CONTROL_TAB} />
                    </TabList>
                )}
                <TabPanel value={TRADING_LIMITS_TAB}>
                    {isNewLimit && (
                        <>
                            <CounterpartyInfo
                                counterpartyId={cpId}
                                counterpartyType={cpType}
                                counterpartyName={cpName}
                            />
                            <Break />
                        </>
                    )}
                    <Styled.Form onSubmit={handleSubmit(submitHandler)}>
                        {isNewLimit && getFormInfobox(primeBrokerViewType)}
                        {!isNewLimit && (
                            <MakerRiskOverview
                                counterpartyName={cpName}
                                counterpartyType={cpType}
                                limit={limit}
                                status={status}
                                userHasMakerRole={userHasMakerRoleInLimit}
                            />
                        )}
                        {!isNewLimit && <EnableTrading limit={limit} setError={setError} />}
                        <FieldGrouper isExpanded>
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <SingleDropdown
                                        value={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(triggerProps) => (
                                            <SingleDropdown.Trigger
                                                {...triggerProps}
                                                size="large"
                                                variant="simple"
                                            >
                                                <CurrencyTriggerEssence
                                                    {...triggerProps}
                                                    option={triggerProps.selectedOption}
                                                    size="large"
                                                />
                                            </SingleDropdown.Trigger>
                                        )}
                                        options={currenciesOptions}
                                        error={errors.currency?.message}
                                        caption="Currency"
                                        fullWidth
                                    >
                                        <CurrencyDropdownSheet
                                            size="large"
                                            options={currenciesOptions}
                                            Dropdown={SingleDropdown}
                                        />
                                    </SingleDropdown>
                                )}
                                name="currency"
                            />
                            <SimpleInput
                                label={getLimitLabel(primeBrokerViewType)}
                                labelHelper={getLimitLabelHelper(primeBrokerViewType)}
                                labelHelperOptions={{ positions: ["bottom"] }}
                                placeholder={getCurrencyPlaceholder(selectedCurrency ?? "")}
                                {...register("grossLimit")}
                                error={errors.grossLimit?.message?.toString()}
                            />
                        </FieldGrouper>
                        {freeLimitIndicator && (
                            <Styled.IndicatorContainer>
                                <TextSmall color={theme.colors.ui52}>
                                    {freeLimitIndicator.title}
                                </TextSmall>
                                <Styled.Indicator>
                                    <TextSmall color={theme.colors.ui52}>
                                        {freeLimitIndicator.value}
                                    </TextSmall>
                                    <TextSmall color={theme.colors.ui52}>
                                        {freeLimitIndicator.percent}
                                    </TextSmall>
                                    <CircularProgressbar $percent={freeLimitIndicator.rawPercent} />
                                </Styled.Indicator>
                            </Styled.IndicatorContainer>
                        )}
                        {userHasMakerRoleInLimit && (
                            <FieldGrouper isExpanded={isMarginActive}>
                                <GroupSwitch data-field-group>
                                    <GroupSwitchLabel>
                                        <Label>Margin requirement</Label>
                                        {getClickHelper(getMarginLabelHelper(primeBrokerViewType))}
                                    </GroupSwitchLabel>
                                    <Switch
                                        onChange={handleMarginActiveChange}
                                        checked={isMarginActive}
                                    />
                                </GroupSwitch>
                                {isMarginActive && (
                                    <>
                                        <SimpleInput
                                            label="Maintenance, %"
                                            placeholder={MARGIN_PLACEHOLDER}
                                            {...register("maintenanceMargin")}
                                            error={errors.maintenanceMargin?.message?.toString()}
                                        />
                                        <SimpleInput
                                            label="Restricted Trading, %"
                                            placeholder={MARGIN_PLACEHOLDER}
                                            {...register("restrictedTrading")}
                                            error={errors.restrictedTrading?.message?.toString()}
                                        />
                                        <SimpleInput
                                            label="Initial, %"
                                            placeholder={MARGIN_PLACEHOLDER}
                                            {...register("initialMargin")}
                                            error={errors.initialMargin?.message?.toString()}
                                        />
                                    </>
                                )}
                            </FieldGrouper>
                        )}
                        {(userHasMakerRoleInLimit || isMasterToMasterRelation) && (
                            <FieldGrouper isExpanded={isMarkupActive}>
                                <GroupSwitch data-field-group>
                                    <Label>Trading Markup</Label>
                                    <Switch
                                        onChange={handleMarkupActiveChange}
                                        checked={isMarkupActive}
                                    />
                                </GroupSwitch>
                                {isMarkupActive && (
                                    <>
                                        <SimpleInput
                                            label="Default size, %"
                                            placeholder={MARGIN_PLACEHOLDER}
                                            {...register("markup")}
                                            hint={
                                                <>
                                                    Change of Global markup won't affect instrument markups overrides.
                                                    Edit instrument markups{" "}
                                                    <StyledLink
                                                        to={{
                                                            pathname: ROUTES.assetsControl,
                                                            search: String(
                                                                new URLSearchParams(
                                                                    primeBrokerViewType === "counterparties"
                                                                        ? [
                                                                              [
                                                                                  SEARCH_PARAMS.assetsAndInstrumentsTab,
                                                                                  "instruments",
                                                                              ],
                                                                          ]
                                                                        : [
                                                                              [
                                                                                  SEARCH_PARAMS.assetsAndInstrumentsTab,
                                                                                  "instruments",
                                                                              ],
                                                                              [
                                                                                  SEARCH_PARAMS.primeBrokerViewType,
                                                                                  "subaccounts",
                                                                              ],
                                                                          ],
                                                                ),
                                                            ),
                                                        }}
                                                        target="_blank"
                                                    >
                                                        here
                                                    </StyledLink>
                                                    .
                                                </>
                                            }
                                            error={errors.markup?.message?.toString()}
                                        />
                                    </>
                                )}
                            </FieldGrouper>
                        )}
                        {error && <FormError>{error}</FormError>}
                        {!isNewLimit && (
                            <DeleteButton
                                type="button"
                                size="large"
                                fullWidth
                                loading={isDeleting}
                                onClick={onDelete}
                            >
                                <HStack>
                                    <PSmall>Delete risk profile</PSmall>
                                    <Spacer />
                                    <Icons.Bin color={theme.colors.negative80} />
                                </HStack>
                            </DeleteButton>
                        )}
                        {isNewLimit && (
                            <Styled.ButtonContainer>
                                <PrimaryButton size="large" fullWidth loading={isSubmitting}>
                                    Add risk profile
                                </PrimaryButton>
                            </Styled.ButtonContainer>
                        )}
                        {!isNewLimit && (
                            <Styled.ButtonContainer>
                                <PrimaryButton
                                    size="large"
                                    fullWidth
                                    disabled={!isDirty}
                                    loading={isSubmitting}
                                >
                                    Save
                                </PrimaryButton>
                                <PlainButton type="button" size="large" fullWidth onClick={onClose}>
                                    Cancel
                                </PlainButton>
                            </Styled.ButtonContainer>
                        )}
                    </Styled.Form>
                </TabPanel>
                {!isNewLimit && (
                    <TabPanel value={ASSETS_CONTROL_TAB}>
                        <LimitsByAsset
                            limit={limit}
                            cpName={title}
                            isSubaccount={isSubaccountCp(cpType)}
                            isTakerCp={isTakerCp(cpType)}
                        />
                    </TabPanel>
                )}
            </TabContext>
        </>
    );
};
