import { Notifications } from "@fm-frontend/uikit";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { EnableMFAPage } from "./EnableMFAPage/enableMFAPage";
import { logout } from "./index";
import { LoginPage } from "./loginPage";
import { MFAPage } from "./MfaPage/mfaPage";
import { ResetPasswordCognito } from "./ResetPassword/ResetPasswordCognito";
import { SlimPage } from "./slimPage";
import { ChallengeName } from "./types";

export const Login = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState<ChallengeName | string>("login");
    const [credentials, setCredentials] = useState({});
    const [cognitoUser, setCognitoUser] = useState<any>(null);
    const [email, setEmail] = useState<string>();

    const enterMFACode = (values: Record<string, unknown>) => {
        setState(ChallengeName.SOFTWARE_TOKEN_MFA);
        setCredentials(values);
        setCognitoUser(values);
    };

    const enableMFA = (user: any, email?: string) => {
        setCognitoUser(user);
        if (email) {
            setEmail(email);
        }
        setState("enableMFA");
    };

    const confirmMFA = (user: any) => {
        setState("confirmMFA");
        setCognitoUser(user);
    };

    const backToLogin = () => {
        setState("login");
        setCredentials({});
        dispatch(logout());
    };

    const backToEnableMfa = () => {
        setState("enableMFA");
    };
    const handleResetPassword = (user: any, email: string) => {
        setState(ChallengeName.NEW_PASSWORD_REQUIRED);
        setCognitoUser(user);
        setEmail(email);
    };

    if (state === ChallengeName.LOGIN) {
        return (
            <LoginPage
                onEnterMFACode={enterMFACode}
                onEnableMFA={enableMFA}
                onConfirmMfa={confirmMFA}
                onResetPassword={handleResetPassword}
            />
        );
    }

    if (state === ChallengeName.SOFTWARE_TOKEN_MFA) {
        return (
            <MFAPage
                actionType="login"
                credentials={credentials}
                user={cognitoUser}
                challengeName={ChallengeName.SOFTWARE_TOKEN_MFA}
                onBack={backToLogin}
            />
        );
    }

    if (state === "confirmMFA") {
        return (
            <MFAPage
                actionType="login"
                user={cognitoUser}
                credentials={credentials}
                challengeName={ChallengeName.SOFTWARE_TOKEN_MFA}
                onBack={backToEnableMfa}
            />
        );
    }

    if (state === ChallengeName.NEW_PASSWORD_REQUIRED) {
        return <ResetPasswordCognito user={cognitoUser} onEnableMFA={enableMFA} />;
    }

    return <EnableMFAPage onNext={confirmMFA} user={cognitoUser} email={email} />;
};

export const LoginFlow = () => {
    return (
        <SlimPage>
            <Login />
            <Notifications />
        </SlimPage>
    );
};
