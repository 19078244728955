import { HStack, Icons, Notifications, P as CopyrightContainer, P as LinkWrapper, PBold } from "@fm-frontend/uikit";
import { Item } from "@react-stately/collections";
import { Copyright } from "components/Copyright";
import { LinkFAQ, LinkLegal, LinkLogout, LinkSupport } from "components/Links";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { CompactSelect } from "components/select/CompactSelect";
import OnboardingRouter from "feature/app/router/OnboardignRouter";
import { LayoutContainer } from "feature/auth/style";
import { OnboardingMenu } from "feature/onboarding";
import {
    LinksContainer,
    LogoutWrapper,
    OnboardingContent,
    OnboardingFooter,
    UserOptionsContainer,
} from "feature/onboarding/common";
import { PermissionsLackErrorModal } from "feature/usersManagement";
import { useHandleLogout } from "hooks/useHandleLogout";
import { useSelector } from "hooks/useSelector";
import { useSettings } from "hooks/useSettings";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIsMakerUser, useIsPrimeBrokerUser, useIsTakerUser } from "store/hooks";
import { stopUserOnboarding } from "store/onboardingActions";
import { ONBOARDING_STEPS, setCurrentStep } from "store/onboardingSlice";
import styled, { useTheme } from "styled-components";

const AttentionContainer = styled.div`
    position: absolute;
    top: -3px;
    right: -3px;
    z-index: 1;
`;

export const OnboardingLayout: React.FC<{ currentStep: ONBOARDING_STEPS | null }> = ({ currentStep, children }) => {
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const { isMobile, isMobileMenuOpen, notifications } = useSelector((state) => state.app);
    const { username } = useSelector((state) => state.auth);
    const { isTermsAndConditionsDelegated, isTermsAndConditionsRequired } = useSelector(
        (state) => state.onboarding.onboardingStages,
    );
    const isLegalOptionActive = Boolean(isTermsAndConditionsRequired && isTermsAndConditionsDelegated);
    const onLogout = useHandleLogout();
    const settings = useSettings();

    const onSelectionChange = useCallback(
        (key) => {
            switch (key) {
                case "logout": {
                    onLogout();
                    break;
                }
                case "legal": {
                    dispatch(setCurrentStep(ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS));
                    break;
                }
            }
        },
        [dispatch, onLogout],
    );

    return (
        <LayoutContainer isOverlayEnabled={isMobile && isMobileMenuOpen}>
            <OnboardingMenu currentStep={currentStep} />
            <OnboardingContent>
                {username && !isMobile && (
                    <UserOptionsContainer>
                        <CompactSelect
                            label=""
                            name=""
                            onSelectionChange={onSelectionChange}
                            extra={username}
                            unselectable
                            aria-label="Settings"
                            data-test-id="settings"
                        >
                            {isLegalOptionActive && (
                                <Item key="legal">
                                    <HStack justifyContent="space-between" width="100%" alignItems="center">
                                        <PBold>Legal</PBold>
                                        <Icons.Attention color={colors.attention100} />
                                    </HStack>
                                </Item>
                            )}
                            <Item key="logout">
                                <HStack justifyContent="space-between" width="100%" alignItems="center">
                                    <PBold>Logout</PBold>
                                    <Icons.LogoutArrow />
                                </HStack>
                            </Item>
                        </CompactSelect>
                        {isLegalOptionActive && (
                            <AttentionContainer>
                                <Icons.Attention color={colors.attention100} />
                            </AttentionContainer>
                        )}
                    </UserOptionsContainer>
                )}
                {children}
            </OnboardingContent>
            <Notifications notifications={notifications} />
            <ConfirmationModal />
            <PermissionsLackErrorModal />
            {isMobile && (
                <OnboardingFooter>
                    <LinksContainer>
                        {settings.footer_links && (
                            <>
                                <LinkWrapper>
                                    <LinkLegal />
                                </LinkWrapper>
                                <LinkWrapper>
                                    <LinkFAQ />
                                </LinkWrapper>
                            </>
                        )}
                        <LinkWrapper>
                            <LinkSupport />
                        </LinkWrapper>
                        <LogoutWrapper>
                            <Icons.LogoutArrow />
                            <LinkLogout />
                        </LogoutWrapper>
                    </LinksContainer>
                    <CopyrightContainer>
                        <Copyright />
                    </CopyrightContainer>
                </OnboardingFooter>
            )}
        </LayoutContainer>
    );
};

export const Onboarding: React.VFC = () => {
    const dispatch = useDispatch();
    const isMakerUser = useIsMakerUser();
    const isTakerUser = useIsTakerUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const { currentStep, onboardingStages } = useSelector((state) => state.onboarding);

    const {
        isLegalDataRequired,
        isTermsAndConditionsRequired,
        isGeneralInfoRequired,
        isAssetsRequired,
        isApiIntegrationRequired,
        isLpsRequired,
        isTermsAndConditionsDelegated,
    } = onboardingStages;

    useEffect(() => {
        if (isLegalDataRequired) {
            dispatch(setCurrentStep(ONBOARDING_STEPS.BL_LEGAL_DATA));
            return;
        }
        if (isTermsAndConditionsRequired && !isTermsAndConditionsDelegated) {
            dispatch(setCurrentStep(ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS));
            return;
        }
        if (isGeneralInfoRequired && (isTakerUser || isPrimeBrokerUser)) {
            dispatch(setCurrentStep(ONBOARDING_STEPS.BL_GENERAL_INFO));
            return;
        }
        if (isAssetsRequired && (isTakerUser || isPrimeBrokerUser)) {
            dispatch(setCurrentStep(ONBOARDING_STEPS.BL_ASSETS));
            return;
        }
        if (isApiIntegrationRequired && isTakerUser) {
            dispatch(setCurrentStep(ONBOARDING_STEPS.BL_API_INTEGRATION));
            return;
        }
        if (isApiIntegrationRequired && (isMakerUser || isPrimeBrokerUser)) {
            dispatch(setCurrentStep(ONBOARDING_STEPS.API_INTEGRATION));
            return;
        }
        if (isLpsRequired && isTakerUser) {
            dispatch(setCurrentStep(ONBOARDING_STEPS.PROVIDERS));
            return;
        }

        if (!isTermsAndConditionsDelegated && !isTermsAndConditionsRequired) {
            dispatch(stopUserOnboarding());
        }
    }, [onboardingStages, isMakerUser, isTakerUser]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [currentStep]);

    return (
        <OnboardingLayout currentStep={currentStep}>
            <OnboardingRouter currentStep={currentStep} />
        </OnboardingLayout>
    );
};
