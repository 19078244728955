import { FormInfo, H2, Link, Modal, PrimaryButton, SimpleInput } from "@fm-frontend/uikit";
import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "hooks";
import { useFaqLinks } from "hooks/useFaqLinks";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { ValidationMessages } from "utils/validationMessages";
import { validators } from "utils/validators";
import { object, SchemaOf, string } from "yup";

interface SubaccountInfoProps {
    onClose: () => void;
    onNextStep: (data: SubaccountInfoModalData) => Promise<void>;
    isOpen: boolean;
}

const Content = styled.div`
    width: 360px;
    padding: 0 12px 16px 12px;
`;
const Title = styled(H2)`
    margin: 12px 0;
`;
const Controls = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
`;
const Error = styled.div`
    margin-top: 8px;
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: ${(p) => p.theme.colors.negative100};
`;

export interface SubaccountInfoModalData {
    name: string;
    email: string;
    accountType: string;
}

const validationSchema: SchemaOf<SubaccountInfoModalData> = object({
    name: validators.username.yupValidator,
    email: string().trim().email(ValidationMessages.INVALIDEmail).required(ValidationMessages.REQUIRED),
    accountType: string().oneOf(["maker", "taker"]).required(ValidationMessages.REQUIRED),
});

const ACCOUNT_TYPE_OPTIONS: DropdownOption[] = [
    { value: "maker", text: "Sub-maker" },
    { value: "taker", text: "Sub-taker" },
];

export const SubaccountInfoModal = ({ isOpen, onClose, onNextStep }: SubaccountInfoProps) => {
    const faqLinks = useFaqLinks();
    const { clientData } = useSelector((state) => state.auth);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting, isDirty },
        reset,
    } = useForm<SubaccountInfoModalData>({
        resolver: yupResolver(validationSchema),
    });

    const [apiError, setApiError] = useState("");

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    const onSubmit = (formData: SubaccountInfoModalData) => {
        setApiError("");
        return onNextStep(formData).catch((error) => setApiError(String(error)));
    };
    const { closeModalWithConfirm } = useModalCloseWithConfirm(isDirty, onClose);

    return (
        <Modal isOpen={isOpen} additionalActions={{ helpButtonUrlOrClickHandler: faqLinks?.faqSubs }} onClose={closeModalWithConfirm}>
            <Content>
                <Title>Add new sub-account</Title>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controls>
                        <SimpleInput
                            {...register("name")}
                            label="Display name"
                            placeholder="Sub-account name"
                            error={errors.name?.message}
                            hint={
                                clientData?.subsSignTnc
                                    ? "The specified name will belong to the invited user and will be displayed in the UI"
                                    : undefined
                            }
                        />
                        <SimpleInput
                            {...register("email")}
                            label="Email"
                            placeholder="sample@company.name"
                            error={errors.email?.message}
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger {...trigger} size="large" variant="simple">
                                            <SingleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="large"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={ACCOUNT_TYPE_OPTIONS}
                                    caption="Account Type"
                                    error={errors.accountType?.message}
                                    align="end"
                                    fullWidth
                                >
                                    <SingleDropdown.BasicSheet size="medium" options={ACCOUNT_TYPE_OPTIONS} />
                                </SingleDropdown>
                            )}
                            name="accountType"
                        />
                        {clientData?.subsSignTnc && (
                            <FormInfo>
                                Continuing, you confirm that you have completed all necessary due diligence and AML
                                procedures for the sub-account in accordance with FM{" "}
                                <Link href="https://finerymarkets.com/legal.html#terms">T&C</Link> and{" "}
                                <Link href="https://finerymarkets.com/legal.html#rules">Operating rules</Link>
                            </FormInfo>
                        )}
                        {apiError && <Error>{apiError}</Error>}
                    </Controls>
                    <PrimaryButton size="large" loading={isSubmitting} fullWidth>
                        Create new sub-account
                    </PrimaryButton>
                </form>
            </Content>
        </Modal>
    );
};
