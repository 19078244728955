import { Flex, IconButton, Icons, Search, Tooltip, VStack } from "@fm-frontend/uikit";
import { useDelayedState } from "hooks";
import { memo, useMemo } from "react";
import styled from "styled-components";
import { AssetControlMenu } from "../../AssetControlMenu";
import { SearchQueryProvider } from "../searchQueryContext";
import { useWhitelistingManager } from "../useWhitelistingManager";
import { WhitelistingProvider } from "../whitelistingContext";
import { WhitelistingSwitch } from "../WhitelistingSwitch";
import { TakerInstrumentsTable } from "./TakerInstrumentsTable";
import { useTableData } from "./TakerInstrumentsTable/hooks";
import { InstrumentTableData } from "./TakerInstrumentsTable/types";

const StyledSearch = styled(Search)`
    max-width: 160px;
`;

const Container = styled(Flex)`
    overflow: auto;
    min-height: calc(100vh - 87px);

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const useFilteredTableData = ({
    searchQuery,
    instrumentsData,
}: {
    searchQuery: string;
    instrumentsData: InstrumentTableData[];
}) => {
    const normalizedSearchQuery = searchQuery.toLocaleLowerCase().trim();

    return useMemo(() => {
        const isSearchQueryEmpty = normalizedSearchQuery === "";

        if (isSearchQueryEmpty) {
            return instrumentsData;
        }

        const filteredInstrumentsData: InstrumentTableData[] = [];
        for (const instrumentData of instrumentsData) {
            const instrumentNameMatched = instrumentData.instrument.instrumentName
                .toLocaleLowerCase()
                .includes(normalizedSearchQuery);
            if (!isSearchQueryEmpty && instrumentNameMatched) {
                filteredInstrumentsData.push(instrumentData);
                continue;
            }
            const filteredCounterparties = instrumentData.counterparties.filter(({ name, id }) => {
                const searchQueryFilterMatched =
                    isSearchQueryEmpty ||
                    instrumentNameMatched ||
                    [name.toLocaleLowerCase(), String(id)].some((str) =>
                        str.includes(normalizedSearchQuery),
                    );

                return searchQueryFilterMatched;
            });

            if (filteredCounterparties.length > 0) {
                filteredInstrumentsData.push({
                    ...instrumentData,
                    counterparties: filteredCounterparties,
                });
            }
        }

        return filteredInstrumentsData;
    }, [normalizedSearchQuery, instrumentsData]);
};

const TakerInstrumentsTabContentView = () => {
    const [delayedSearchQuery, setSearchQuery, searchQuery, setSearchQueryImmediately] =
        useDelayedState(750, "");
    const { isLoading: isInstrumentsDataLoading, instrumentsData } = useTableData();
    const filteredTableData = useFilteredTableData({
        instrumentsData,
        searchQuery: delayedSearchQuery,
    });
    const whitelistManager = useWhitelistingManager();

    const resetFilter = () => {
        setSearchQueryImmediately("");
    };

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <CardContainer flex={1} asCard minWidth="445px">
                <WhitelistingProvider value={whitelistManager}>
                    <Flex padding={12} paddingBottom={16} alignItems="flex-start">
                        <AssetControlMenu />
                        <WhitelistingSwitch />
                    </Flex>
                    <Flex alignItems="flex-start" paddingX={12} paddingBottom={20} spacing={6}>
                        <StyledSearch
                            size="small"
                            placeholder="Search"
                            query={searchQuery}
                            onChange={(value) => setSearchQuery(value)}
                        />
                        {searchQuery.trim() !== "" && (
                            <Tooltip content="Reset filter" align="center">
                                <IconButton
                                    variant="plain"
                                    type="button"
                                    Icon={Icons.Recent}
                                    onClick={resetFilter}
                                />
                            </Tooltip>
                        )}
                    </Flex>

                    <SearchQueryProvider value={delayedSearchQuery}>
                        <TakerInstrumentsTable
                            instrumentsData={filteredTableData}
                            isLoading={isInstrumentsDataLoading}
                        />
                    </SearchQueryProvider>
                </WhitelistingProvider>
            </CardContainer>
        </Container>
    );
};

export const TakerInstrumentsTabContent = memo(TakerInstrumentsTabContentView);
