import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientData } from "../../types";
import { UserCognito } from "./types";

export const setToken = createAction("setToken", (token: string | null) => ({
    payload: token,
}));

export const setUser = createAction("setUser", (user: UserCognito | null) => ({
    payload: user,
}));

export const logout = createAction("logout");
export const updateClientData = createAction("updateClientData");

export type State = Partial<{
    online: boolean;
    clientData: ClientData | null;
    username: string;
    maker: boolean;
    ptc: boolean;
    token: string;
    pubToken: string;
    mfaEnabled: boolean;
    mfaConfirmed: boolean;
    userCognito: UserCognito;
    userId: number;
}>;

const initialState: State = {};
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth(state, { payload: diff }: PayloadAction<State | null>) {
            if (diff === null) {
                state.online = false;
                state.mfaEnabled = false;
            } else {
                Object.entries(diff).forEach(([key, value]) => ((state as any)[key] = value));
            }
        },
        setAuthCognito(state, { payload: userSession }: PayloadAction<UserCognito | null>) {
            if (userSession === null || !userSession?.accessToken?.jwtToken) {
                state.online = false;
                state.mfaEnabled = false;
                return;
            }

            state.online = true;
            state.mfaEnabled = true;
        },
        setClientData(state, { payload: clientData }: PayloadAction<ClientData | null>) {
            state.clientData = clientData;
            if (clientData) {
                state.userId = clientData.clientId;
                state.maker = clientData.maker;
                state.username = clientData.username;
            }
        },
    },
});
