import {
    Flex,
    FormTab,
    FormTabList,
    IconButton,
    Icons,
    PrimaryButton,
    Search,
    TabContext,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { useMemo, useState, VFC } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { getTabNumericLabel } from "utils";
import { Transaction } from "../types";
import { useFilteredTransactions } from "../useFilteredTransactions";
import { TransactionsTable } from "./TransactionsTable";
import { useActions } from "./useActions";

const Container = styled(Flex)`
    overflow: auto;
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const Header = styled.div`
    margin: 12px;
    margin-left: 8px;
    display: flex;
    justify-content: space-between;
`;

const HeaderActions = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-end;

    .search {
        width: 160px;

        input::placeholder {
            font-size: 12px;
        }
    }
`;

type FilterValue = {
    statusFilter: Transaction["status"][];
    assetFilter: Transaction["asset"][];
};

const DEFAULT_FILTER_VALUE: FilterValue = {
    statusFilter: [],
    assetFilter: [],
};

const STATUS_OPTIONS: DropdownOption<Transaction["status"]>[] = [
    {
        text: "Created",
        value: "created",
    },
    {
        text: "Sent",
        value: "sent",
    },
    {
        text: "Received",
        value: "received",
    },
    {
        text: "Committed",
        value: "committed",
    },
    {
        text: "Cancelled",
        value: "cancelled",
    },
];

export const SubaccountTransactionsPageContent: VFC = () => {
    const [selectedFilterTab, setSelectedFilterTab] = useState<"all" | "deposits" | "withdrawals">(
        "all",
    );
    const { sendDeposit, createDeposit, removeTransaction, actionsContent } = useActions();
    const [searchQuery, setSearchQuery] = useState("");
    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm<FilterValue>({
        defaultValues: { ...DEFAULT_FILTER_VALUE },
    });
    const filterValues = watch();

    const resetFilter = () => {
        reset({ ...DEFAULT_FILTER_VALUE });
    };

    const allTabsData = useFilteredTransactions(true);
    const transactions = allTabsData[selectedFilterTab];

    const assetFilterOptions: DropdownOption<Transaction["asset"]>[] = [
        ...new Set(transactions.map(({ asset }) => asset)),
    ].map((asset) => ({
        text: asset.toLocaleUpperCase(),
        value: asset,
    }));

    const filteredTransactions = useMemo(
        () =>
            transactions.filter(({ comment, asset, network, status, txId }) => {
                let matched =
                    (filterValues.statusFilter.length === 0 ||
                        filterValues.statusFilter.includes(status)) &&
                    (filterValues.assetFilter.length === 0 ||
                        filterValues.assetFilter.includes(asset));

                const query = searchQuery.trim().toLocaleLowerCase();
                if (matched && query) {
                    matched =
                        comment.toLocaleLowerCase().includes(query) ||
                        Boolean(asset?.toLocaleLowerCase().includes(query)) ||
                        Boolean(network?.toLocaleLowerCase().includes(query)) ||
                        txId.toLocaleLowerCase().includes(query);
                }

                return matched;
            }),
        [transactions, selectedFilterTab, searchQuery, filterValues],
    );

    const { depositCount, withdrawalCount } = useMemo(
        () =>
            allTabsData.all.reduce<{ depositCount: number; withdrawalCount: number }>(
                (acc, { type, status }) => {
                    if (status !== "committed" && status !== "cancelled") {
                        acc[type === "deposit" ? "depositCount" : "withdrawalCount"]++;
                    }
                    return acc;
                },
                {
                    depositCount: 0,
                    withdrawalCount: 0,
                },
            ),
        [allTabsData.all],
    );

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <CardContainer flex={1} asCard minWidth="445px">
                <Header>
                    <TabContext
                        value={selectedFilterTab}
                        handleClick={(v) =>
                            setSelectedFilterTab(v as "all" | "deposits" | "withdrawals")
                        }
                    >
                        <FormTabList>
                            <FormTab
                                title="All"
                                label={getTabNumericLabel(depositCount + withdrawalCount)}
                                value="all"
                            />
                            <FormTab
                                title="Deposits"
                                label={getTabNumericLabel(depositCount)}
                                value="deposits"
                            />
                            <FormTab
                                title="Withdrawals"
                                label={getTabNumericLabel(withdrawalCount)}
                                value="withdrawals"
                            />
                        </FormTabList>
                    </TabContext>
                    <HeaderActions>
                        <Search
                            size="small"
                            placeholder="Search"
                            query={searchQuery}
                            onChange={setSearchQuery}
                            className="search"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <CurrencyTriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                noIcon
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={assetFilterOptions}
                                    align="end"
                                    caption="Asset"
                                    asFilter
                                >
                                    <CurrencyDropdownSheet
                                        size="medium"
                                        options={assetFilterOptions}
                                        Dropdown={MultipleDropdown}
                                    />
                                </MultipleDropdown>
                            )}
                            name="assetFilter"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <MultipleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={STATUS_OPTIONS}
                                    align="end"
                                    caption="Status"
                                    asFilter
                                >
                                    <MultipleDropdown.BasicSheet
                                        size="small"
                                        options={STATUS_OPTIONS}
                                    />
                                </MultipleDropdown>
                            )}
                            name="statusFilter"
                        />
                        {isDirty && (
                            <Tooltip content="Reset filter" align="center">
                                <IconButton
                                    variant="plain"
                                    type="button"
                                    Icon={Icons.Recent}
                                    onClick={resetFilter}
                                />
                            </Tooltip>
                        )}

                        <PrimaryButton size="small" onClick={createDeposit}>
                            Add deposit
                        </PrimaryButton>
                    </HeaderActions>
                </Header>
                <TransactionsTable
                    data={filteredTransactions}
                    onSendDepositClick={sendDeposit}
                    onRemoveTransactionClick={removeTransaction}
                    isLoading={allTabsData.isLoading}
                />
            </CardContainer>
            {actionsContent}
        </Container>
    );
};
