import { Auth } from "aws-amplify";
import { appUrl } from "../const";
import { appendLogEntry, setIsPermissionsLackErrorOccured, setNeeds2fa } from "../feature/app";
import { logout } from "../feature/auth";
import { actionChannel } from "../store/actionChannel";
import { agreementNotSigned } from "../store/onboardingActions";
import { format, kAgreementNotSigned, kAPIMethodNotAvailable, kInternalError, kInvalidPermissions, kMFANotEnabled } from "../utils/ErrorCodes";
import { stringify } from "../utils/json";

let nextNonce = new Date().valueOf();

export const post = async <T>(method: string, data: T, allowUnauthorized?: boolean) => {
    actionChannel.put(appendLogEntry(`Request to ${method} started`));
    let currentSession = null;
    let isAuthed;
    let jsonToken;

    if (!allowUnauthorized) {
        try {
            currentSession = await Auth.currentUserPoolUser();
        } catch (e) {
            console.error(e);
            actionChannel.put(logout());

            return;
        }
        jsonToken = JSON.stringify({ token: currentSession?.signInUserSession?.accessToken?.jwtToken });
        isAuthed = Boolean(currentSession?.signInUserSession?.accessToken?.jwtToken);
    }

    const body = stringify({
        ...data,
        ...((isAuthed || allowUnauthorized) && {
            nonce: nextNonce++,
            timestamp: new Date().valueOf(),
        }),
    });

    const response = await fetch(`${window.location.protocol}//${appUrl}/api/${method}`, {
        method: "POST",
        body,
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            ...(isAuthed && {
                "EFX-Key": jsonToken,
            }),
            "EFX-Sign": "",
        },
    });

    const json = await response.json();
    if (response.status !== 200) {
        switch (response.status) {
            case 400: {
                const data = json;
                if (data && data.error) {
                    if (data.param) {
                        throw { [data.param]: format(data.error) };
                    } else {
                        if (data.error === kMFANotEnabled) {
                            actionChannel.put(setNeeds2fa(true));
                        }
                        if (data.error === kAgreementNotSigned) {
                            actionChannel.put(agreementNotSigned());
                        }
                        if (data.error === 176) {
                            actionChannel.put(logout());
                        }
                        if (data.error === 175) {
                            const cognitoUser = await Auth.currentAuthenticatedUser();
                            // @ts-ignore
                            cognitoUser?.refreshSession(currentSession.refreshToken, (err) => {
                                if (err) {
                                    console.error(err);
                                    return err;
                                }
                            });
                        }
                        if (data.error === kInvalidPermissions) {
                            actionChannel.put(setIsPermissionsLackErrorOccured(true));
                        }
                        if (data.error === kAPIMethodNotAvailable && method === "onboarding/startKYB") {
                            throw kAPIMethodNotAvailable;
                        }
                        throw format(data.error);
                    }
                } else {
                    console.error("POST http error: (" + response.status + ")", json);
                    throw format(kInternalError);
                }

                break;
            }
            default: {
                console.error("POST http error: (" + response.status + ")", json);
                throw format(kInternalError);
                break;
            }
        }
    }
    actionChannel.put(appendLogEntry(`Request to ${method} fulfilled`));
    return json;
};

export const authWSRequest = (token: string) => {
    const content = stringify({
        nonce: nextNonce++,
        timestamp: new Date().valueOf(),
    });
    return {
        content,
        event: "auth",
        key: token,
        signature: "",
    };
};
