import { Item } from "@react-stately/collections";
import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { IS_TEST_ENV, onMobile } from "../const";
import { setIsMobileMenuOpen } from "../feature/app";
import { PlainIconButton } from "../feature/form/Buttons";
import { useHandleLogout } from "../hooks/useHandleLogout";
import { LS_VARIABLES, useLSState } from "../hooks/useLSState";
import { useSelector } from "../hooks/useSelector";
import { useTitle } from "../hooks/useTitle";
import { useIsSubaccountUser } from "../store/hooks";
import { Spacer } from "../style";
import { selectors } from "../style/theme";
import { IconMenu, IconMenuClose, MobileLogo } from "./icons";
import { CompactSelect } from "./select/CompactSelect";
import { UserMenuControl } from "./UserMenuControl/UserMenuControl";

export const TopMenuHeader = styled.header`
    ${selectors.mixinHeader1}
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    align-items: start;
    & > * {
        margin-bottom: 12px;
    }
    & > span {
        margin-right: 4px;
        margin-top: 6px;
        display: flex;
    }
    & > svg {
        margin-top: 11px;
    }
    & > *:last-child {
        margin-left: 8px;
    }
    ${onMobile} {
        white-space: nowrap;
        flex-wrap: wrap;
    }
    @media (max-width: 860px) {
        aside {
            order: 1;
            width: 100%;
        }
    }
`;

export const TopMenuSelect = () => {
    const isSubaccountUser = useIsSubaccountUser();
    const dispatch = useDispatch();
    const history = useHistory();
    const { username } = useSelector((state) => state.auth);

    const onLogout = useHandleLogout();
    const { isMobile, isMobileMenuOpen } = useSelector((state) => state.app);
    const [shouldCrash, setShouldCrash] = useState(false);
    if (shouldCrash) throw new Error("Halt and Catch Fire");

    const onSelectionChange = useCallback(
        (key) => {
            switch (key) {
                case "/settings/profile":
                case "/settings/usersmanagement":
                case "/settings/referrals":
                case "/settings/security":
                case "/settings/notifications": {
                    history.push(key);
                    break;
                }
                case "logout": {
                    onLogout();
                    break;
                }
                case "halt": {
                    setShouldCrash(true);
                    break;
                }
            }
        },
        [dispatch, onLogout],
    );
    const onClickMobileMenu = useCallback(() => dispatch(setIsMobileMenuOpen(!isMobileMenuOpen)), [isMobileMenuOpen]);

    const [areTestingToolsEnabled] = useLSState(LS_VARIABLES.TESTING_TOOLS, false);
    return isMobile ? (
        <PlainIconButton onClick={onClickMobileMenu}>
            {isMobileMenuOpen ? <IconMenuClose /> : <IconMenu />}
        </PlainIconButton>
    ) : (
        <CompactSelect
            label=""
            name=""
            onSelectionChange={onSelectionChange}
            extra={username}
            unselectable
            aria-label="Settings"
            data-test-id="settings"
        >
            <Item key="/settings/profile">Account data</Item>
            <Item key="/settings/usersmanagement">Users and roles</Item>
            {!isSubaccountUser && <Item key="/settings/referrals">Referrals</Item>}
            <Item key="/settings/security">Login and security</Item>
            <Item key="/settings/notifications">Notifications</Item>
            {areTestingToolsEnabled && IS_TEST_ENV && <Item key="halt">Halt and Catch Fire</Item>}
            <Item key="logout">Logout</Item>
        </CompactSelect>
    );
};

export const TopMenu: React.FC = memo(({ children }) => {
    const { isMobile } = useSelector((state) => state.app);
    useTitle(typeof children === "string" ? children : null);

    return (
        <TopMenuHeader>
            {isMobile && (
                <>
                    <MobileLogo />
                    <Spacer />
                </>
            )}
            <span>{children}</span>
            <Spacer />
            <UserMenuControl />
        </TopMenuHeader>
    );
});
