import { call, put, take, takeEvery } from "@redux-saga/core/effects";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { post } from "api";
import { Amplify, Auth } from "aws-amplify";
import { awsExports } from "aws-exports";
import { getLSValue, LS_VARIABLES } from "hooks/useLSState";
import { agreementNotSigned, startUserOnboarding } from "store/onboardingActions";
import { OnboardingStages, setOnboardingStages } from "store/onboardingSlice";
import { ClientData } from "types";
import { noop } from "utils";
import { authSlice, setUser, updateClientData } from ".";
import { setIsLoadingApp, shutdownApp } from "../app";
import { clearSnapshots } from "../app/ws";
import { logout } from "./index";
import { UserCognito } from "./types";

const isLegalDataRequired = (clientData?: ClientData) => {
    if (!clientData) {
        return true;
    }

    const { companyName, registrationNumber, address, city, zipCode, country } = clientData;

    return [companyName, registrationNumber, address, city, zipCode, country].some((prop) => !Boolean(prop));
};

// when T&C is not required, then T&C delegated also not required
// but if T&C is required, T&C is depended on a storage value
const getGeneralOnboardingStages = (clientData?: ClientData) => {
    const isTermsAndConditionsRequired = Boolean(clientData?.needAgreement);
    const isTermsAndConditionsDelegated = isTermsAndConditionsRequired
        ? getLSValue<boolean>(LS_VARIABLES.TnC_DELEGATED) ?? false
        : false;

    return {
        isLegalDataRequired: isTermsAndConditionsRequired && isLegalDataRequired(clientData),
        isTermsAndConditionsRequired,
        isTermsAndConditionsDelegated,
    } as OnboardingStages;
};

async function handleVisibilityChange() {
    if (!document.hidden) {
        (await Auth.currentAuthenticatedUser()) as UserCognito;
    }
}

function* sagaUpdateClientData() {
    try {
        const clientData = (yield call(post, "getClientData", {})) as ClientData;
        const generalOnboardingStages = getGeneralOnboardingStages(clientData);
        yield put(setOnboardingStages(generalOnboardingStages));
        yield put(authSlice.actions.setClientData(clientData));
    } catch (error) {
        console.error(error);
    }
}

function* sagaSetUser({ payload: userSession }: ReturnType<typeof setUser>) {
    try {
        if (!userSession?.accessToken?.jwtToken) {
            return;
        }

        yield put(
            setIsLoadingApp({
                isLoading: true,
                key: "sagaSetUser",
            }),
        );
        yield put(authSlice.actions.setAuthCognito(userSession));
        yield call(sagaUpdateClientData);
        yield put(startUserOnboarding());
    } catch (error) {
        console.error(error);
        return;
    }

    yield put(setIsLoadingApp({ isLoading: false, key: "sagaSetUser" }));
}

function* sagaLogout() {
    Auth.signOut();
    yield put(authSlice.actions.setAuth(null));
    yield put(clearSnapshots());
}

export function* authSaga() {
    yield takeEvery(setUser, sagaSetUser);
    yield takeEvery(logout, sagaLogout);
    yield takeEvery(updateClientData, sagaUpdateClientData);
    yield takeEvery(agreementNotSigned, sagaUpdateClientData);

    Amplify.configure(awsExports);
    let currentUserInfo = null;
    yield put(
        setIsLoadingApp({
            isLoading: true,
            key: "authSaga",
        }),
    );
    try {
        currentUserInfo = (yield call(() => Auth.currentAuthenticatedUser())) as CognitoUserSession;
    } catch (error) {
        noop();
    }

    if (currentUserInfo) {
        const cognitoUserSession = (yield call(() => Auth.currentSession())) as CognitoUserSession;
        yield call(sagaSetUser, { payload: cognitoUserSession, type: "setUser" });
        yield put(
            setIsLoadingApp({
                isLoading: false,
                key: "authSaga",
            }),
        );
        document.addEventListener("visibilitychange", handleVisibilityChange);
        yield take(shutdownApp);
        document.removeEventListener("visibilitychange", handleVisibilityChange);
    }

    yield put(
        setIsLoadingApp({
            isLoading: false,
            key: "authSaga",
        }),
    );
}
