import { useHandlePrimeBrokerViewTypeChange } from "feature/mainMenu/PrimeBrokerMenuContent";
import { useDelayedState } from "hooks";
import { useSearchParamState } from "hooks/useSearchParamState";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useExtendedCurrencies } from "store/useExtentedCurrencies";
import { DEFAULT_ASSETS_OPTION } from "../../../types";
import { CurrencyTypeToAssetGroupMap } from "../consts";
import { use2WayBinding } from "../hooks";
import { ShortSalesStatus } from "./filters/ShortSalesStatusFilter";
import { AssetRow } from "./types";

export type Filters = {
    cpFilter: number[];
    limitPerAssetFilter: string[];
    overnightsFilter: string[];
    shortSalesFilter: ShortSalesStatus[];
};
const DEFAULT_FILTER_VALUE: Filters = {
    cpFilter: [],
    limitPerAssetFilter: [],
    overnightsFilter: [],
    shortSalesFilter: [],
};

export const useFilters = (data: AssetRow[]) => {
    const [
        selectedAssetsGroupFilter,
        setSelectedAssetsGroupFilter,
        deleteSelectedAssetsGroupFilter,
    ] = useSearchParamState("assetGroup", DEFAULT_ASSETS_OPTION);
    const [searchFilter, setSearchFilter, deleteSearchFilter] = useSearchParamState("search", "");
    const [cpFilter, setCpFilter, deleteCpFilter] = useSearchParamState<number[]>("cp", [], {
        isArray: true,
        parseValue: Number,
    });
    const [limitPerAssetFilter, setLimitPerAssetFilter, deleteLimitPerAssetFilter] =
        useSearchParamState<string[]>("limitPerAsset", [], {
            isArray: true,
        });
    const [overnightsFilter, setOvernightsFilter, deleteOvernightsFilter] = useSearchParamState<
        string[]
    >("overnights", [], {
        isArray: true,
    });
    const [shortSalesFilter, setShortSalesFilter, deleteShortSalesFilter] = useSearchParamState<
        ShortSalesStatus[]
    >("shortsales", [], {
        isArray: true,
    });
    const [delayedSearchQuery, setSearchQuery, searchQuery, setSearchQueryImmediately] =
        useDelayedState(750, searchFilter);

    const { control, watch, setValue } = useForm<Filters>({
        defaultValues: {
            ...DEFAULT_FILTER_VALUE,
            cpFilter,
            limitPerAssetFilter,
            overnightsFilter,
            shortSalesFilter,
        },
    });
    // cpFilter
    const cpFilterValue = watch("cpFilter");
    use2WayBinding(
        cpFilterValue,
        (filterValue) => setValue("cpFilter", filterValue),
        cpFilter,
        setCpFilter,
    );
    // limitPerAssetFilter
    const limitPerAssetFilterValue = watch("limitPerAssetFilter");
    use2WayBinding(
        limitPerAssetFilterValue,
        (filterValue) => setValue("limitPerAssetFilter", filterValue),
        limitPerAssetFilter,
        setLimitPerAssetFilter,
    );
    // overnightsFilter
    const overnightsFilterValue = watch("overnightsFilter");
    use2WayBinding(
        overnightsFilterValue,
        (filterValue) => setValue("overnightsFilter", filterValue),
        overnightsFilter,
        setOvernightsFilter,
    );
    // shortSalesFilter
    const shortSalesFilterValue = watch("shortSalesFilter");
    use2WayBinding(
        shortSalesFilterValue,
        (filterValue) => setValue("shortSalesFilter", filterValue),
        shortSalesFilter,
        setShortSalesFilter,
    );
    // searchFilter
    use2WayBinding(delayedSearchQuery, setSearchQueryImmediately, searchFilter, setSearchFilter);

    const { currencyGroups } = useExtendedCurrencies();

    const resetFilters = (pushToHistory = true) => {
        deleteSearchFilter(!pushToHistory);
        deleteSelectedAssetsGroupFilter(true);
        deleteLimitPerAssetFilter(true);
        deleteOvernightsFilter(true);
        deleteShortSalesFilter(true);
        deleteCpFilter(true);
    };
    useHandlePrimeBrokerViewTypeChange(() => resetFilters(false));

    const areFiltersDirty =
        cpFilterValue.length !== 0 ||
        limitPerAssetFilterValue.length !== 0 ||
        overnightsFilterValue.length !== 0 ||
        shortSalesFilterValue.length !== 0 ||
        delayedSearchQuery.trim() !== "" ||
        selectedAssetsGroupFilter !== "all";

    const normalizedSearchQuery = delayedSearchQuery.trim().toLocaleLowerCase();
    const filteredData = useMemo(() => {
        const result: AssetRow[] = [];

        for (const assetData of data) {
            const { currency, counterparties } = assetData;

            const assetGroupFilterMatched =
                selectedAssetsGroupFilter === "all" ||
                CurrencyTypeToAssetGroupMap[currencyGroups[currency]] === selectedAssetsGroupFilter;
            if (!assetGroupFilterMatched) continue;

            const currencyMatched = currency.toLocaleLowerCase().includes(normalizedSearchQuery);
            const filteredCounterparties = counterparties.filter((cpData) => {
                const {
                    name,
                    id,
                    isUserSetShortSalesBan,
                    isCpSetShortSalesBan,
                    negativeRate,
                    positiveRate,
                    userLimit,
                    cpLimit,
                } = cpData;

                const isSearchMatched =
                    !normalizedSearchQuery ||
                    name.toLocaleLowerCase().includes(normalizedSearchQuery) ||
                    String(id).includes(normalizedSearchQuery);
                if (!isSearchMatched && !currencyMatched) return false;

                const cpFilterMatched =
                    cpFilterValue.length === 0 ||
                    cpFilterValue.some((filterValue) => filterValue === id);
                if (!cpFilterMatched) return false;

                const limitPerAssetFilterMatched =
                    limitPerAssetFilterValue.length === 0 ||
                    limitPerAssetFilterValue.some((filterValue) => {
                        if (filterValue === "enabledByUser") {
                            return userLimit;
                        }
                        if (filterValue === "disabledByUser") {
                            return !userLimit;
                        }
                        if (filterValue === "enabledByCp") {
                            return cpLimit;
                        }
                        if (filterValue === "disabledByCp") {
                            return !cpLimit;
                        }
                        return false;
                    });
                if (!limitPerAssetFilterMatched) return false;

                const overnightsFilterMatched =
                    overnightsFilterValue.length === 0 ||
                    overnightsFilterValue.some((filterValue) => {
                        switch (filterValue) {
                            case "enabledLong":
                                return positiveRate;
                            case "disabledLong":
                                return !positiveRate;
                            case "enabledShort":
                                return negativeRate;
                            case "disabledShort":
                                return !negativeRate;
                            default:
                                return false;
                        }
                    });
                if (!overnightsFilterMatched) return false;

                const shortSalesBanFilterMatched =
                    shortSalesFilterValue.length === 0 ||
                    shortSalesFilterValue.some((filterValue) => {
                        switch (filterValue) {
                            case "onByUser":
                                return !isUserSetShortSalesBan;
                            case "banByUser":
                                return isUserSetShortSalesBan;
                            case "onByCp":
                                return !isCpSetShortSalesBan;
                            case "banByCp":
                                return isCpSetShortSalesBan;
                            default:
                                return false;
                        }
                    });
                if (!shortSalesBanFilterMatched) return false;

                return true;
            });

            if (filteredCounterparties.length === 0) {
                continue;
            }

            result.push({
                ...assetData,
                counterparties:
                    filteredCounterparties.length === 0 ? counterparties : filteredCounterparties,
            });
        }

        return result;
    }, [
        data,
        normalizedSearchQuery,
        cpFilterValue,
        selectedAssetsGroupFilter,
        limitPerAssetFilterValue,
        overnightsFilterValue,
        shortSalesFilterValue,
    ]);

    return {
        areFiltersDirty,
        resetFilters,
        control,
        setSearchQuery,
        searchQuery,
        delayedSearchQuery,
        selectedAssetsGroupFilter,
        setSelectedAssetsGroupFilter,
        filteredData,
    };
};
