import {
    Checkbox,
    FieldGroup,
    Form,
    H2,
    HStack,
    Modal,
    P,
    PlainButton,
    PrimaryButton,
    PSmall,
    SimpleInput,
    SwitchField,
    VStack,
} from "@fm-frontend/uikit";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CounterpartiesMultipleGroupedDropdown } from "components/customMultipleDropdowns/CounterpartiesMultipleGroupedDropdown";
import { InstrumentsMultipleGroupedDropdown } from "components/customMultipleDropdowns/InstrumentsMultipleGroupedDropdown";
import { InfoBox } from "components/InfoBox";
import { FIXED_MODAL_STYLE } from "const/modal";
import { createNotification } from "feature/app";
import { useInstruments } from "hooks";
import { useWithConfirmationModalDecorator } from "hooks/useWithConfirmationModalDecorator";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useExtendedCurrencies } from "store/useExtentedCurrencies";
import styled from "styled-components";
import { UpdateMarkupResponse } from "../../types";
import { useCounterpartiesWithTypes } from "../IntsrumentsTable/hooks";
import { useNotMakerCpsIds } from "../IntsrumentsTable/hooks/useCounterpartiesWithTypes";
import { InstrumentTableData } from "../IntsrumentsTable/types";
import { calculateDefaultMarkups } from "../IntsrumentsTable/utils";
import { SetupModalSettings } from "../MakerMasterInstrumentsTabContent";
import { schema } from "./schema";
import { convertToRequestBodies, getMarkupsCount, updateMarkups } from "./utils";

const Strong = styled.span`
    color: ${(p) => p.theme.colors.brand100};
`;

const StyledInfoBox = styled(InfoBox)`
    margin: 0 12px 8px 12px;
`;

const MarkupsDescription = styled(PSmall)`
    margin-top: -4px;
    padding: 0 12px 8px 12px;
    color: ${(p) => p.theme.colors.ui32};
`;

const FadedText = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

const Error = styled.div`
    margin-top: auto;
    text-align: center;
    width: 100%;
    font-size: 14px;
    padding-bottom: 8px;
    color: ${(p) => p.theme.colors.negative100};
`;

export type InstrumentMarkupInputs = {
    instruments: string[];
    cps: number[];
    enableMarkups: boolean;
    bidMarkup: string | number;
    askMarkup: string | number;
    applySameMarkups: boolean;
};

type Props = {
    onClose: () => void;
    onUpdate: (result: UpdateMarkupResponse[]) => Promise<void>;
    initialSetupModalSettings?: SetupModalSettings;
    instrumentsData: InstrumentTableData[];
};

export const InstrumentMarkupsSetupModal: React.FC<Props> = ({
    onClose,
    onUpdate,
    instrumentsData,
    initialSetupModalSettings: { defaultValues = {}, isParticularMarkup = false } = {},
}) => {
    const dispatch = useDispatch();
    const { currencyGroups } = useExtendedCurrencies();
    const { instruments, currencies } = useInstruments();
    const { counterparties: cpsWithTypes } = useCounterpartiesWithTypes();
    const notMakerCpsIds = useNotMakerCpsIds();
    const [apiError, setApiError] = useState<string | null>(null);

    const onCloseWithConfirmationModal = useWithConfirmationModalDecorator({
        title: "Discard Changes?",
        description:
            "Are you sure you want to close without saving changes? Any unsaved data will be lost.",
        confirmButtonTitle: "Yes, discard",
        cancelButtonTitle: "Cancel",
        onConfirm: onClose,
    });

    const { cps, instruments: instrumentsConfig } = defaultValues;
    const {
        control,
        formState: { isSubmitting, errors, isDirty },
        watch,
        handleSubmit,
        register,
        setValue,
    } = useForm<InstrumentMarkupInputs>({
        mode: "onSubmit",
        defaultValues: {
            enableMarkups: true,
            ...defaultValues,
            cps,
            instruments: instrumentsConfig,
        },
        resolver: yupResolver(schema),
    });
    const handleClose = () => onCloseWithConfirmationModal(isDirty);

    const selectedCps = watch("cps");
    const selectedInstruments = watch("instruments");
    const bidMarkup = watch("bidMarkup");
    const enableMarkups = watch("enableMarkups");

    const [isSameMarkups, setIsSameMarkups] = useState(false);

    useEffect(() => {
        const { askMarkup, bidMarkup } = calculateDefaultMarkups({
            selectedInstruments,
            selectedCps,
            instrumentsData,
        });
        setValue("askMarkup", askMarkup ?? "");
        setValue("bidMarkup", bidMarkup ?? "");
        setIsSameMarkups(askMarkup === bidMarkup);
    }, [selectedCps, selectedInstruments, currencyGroups, instrumentsData]);

    useEffect(() => {
        if (isSameMarkups) {
            setValue("askMarkup", bidMarkup);
        }
    }, [bidMarkup, isSameMarkups]);

    const touchedMarkupsCount = useMemo(
        () =>
            getMarkupsCount({
                selectedCps,
                selectedInstruments,
            }),
        [selectedCps, selectedInstruments, instruments, currencies, cpsWithTypes],
    );

    const applyButtonText = `Apply ${isParticularMarkup ? "" : touchedMarkupsCount}`;

    const onSubmit = async (data: InstrumentMarkupInputs) => {
        try {
            setApiError(null);

            const requestsBodies = convertToRequestBodies(data);
            const result = await updateMarkups(requestsBodies);

            await onUpdate(result);
            onClose();
        } catch (err) {
            setApiError(String(err));
            dispatch(
                createNotification({
                    type: "error",
                    content: String(err),
                }),
            );
        }
    };

    const title = isParticularMarkup ? (
        <>
            <Strong>{selectedInstruments[0]}</Strong> for{" "}
            {cpsWithTypes[Number(selectedCps[0])]?.name}
        </>
    ) : (
        "Instrument markups setup"
    );

    return (
        <Modal isOpen onClose={handleClose} style={FIXED_MODAL_STYLE}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <VStack minWidth="360px" asCard>
                    <VStack padding={12}>
                        <H2>{title}</H2>
                    </VStack>
                    {!isParticularMarkup && (
                        <>
                            <FieldGroup direction="column" paddingX={12} paddingBottom={8}>
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <InstrumentsMultipleGroupedDropdown
                                            values={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                    name="instruments"
                                />
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <CounterpartiesMultipleGroupedDropdown
                                            values={field.value}
                                            onChange={field.onChange}
                                            cpsIds={notMakerCpsIds}
                                        />
                                    )}
                                    name="cps"
                                />
                            </FieldGroup>
                        </>
                    )}
                    <StyledInfoBox
                        title={
                            <P>
                                How <span>Instrument markups</span> work?
                            </P>
                        }
                    >
                        <P>
                            Customize fees per instrument with bid/ask flexibility. If instrument
                            markup is enabled, it takes precedence over the default markup for the
                            selected instruments. To avoid charging fees for the selected
                            instruments, set the value to 0. To disable the use of custom markups,
                            set the switch to disabled.
                        </P>
                    </StyledInfoBox>
                    <FieldGroup direction="column" paddingX={12} paddingBottom={8}>
                        <SwitchField
                            variant="simple"
                            fullWidth
                            text="Enable instrument markups"
                            control={control}
                            {...register("enableMarkups")}
                        />
                        {enableMarkups && (
                            <>
                                <SimpleInput
                                    label={isSameMarkups ? "Markup, %" : "Bid, %"}
                                    placeholder="0.0000"
                                    {...register("bidMarkup")}
                                    autoFocus
                                    error={errors.bidMarkup?.message}
                                />
                                {!isSameMarkups && (
                                    <SimpleInput
                                        label="Ask, %"
                                        placeholder="0.0000"
                                        {...register("askMarkup")}
                                        error={errors.askMarkup?.message}
                                    />
                                )}
                            </>
                        )}
                    </FieldGroup>
                    {!enableMarkups && (
                        <MarkupsDescription>Default markup will be applied</MarkupsDescription>
                    )}
                    {enableMarkups && (
                        <HStack alignItems="center" spacing={8} paddingX={12}>
                            <Checkbox
                                id="sameMarkup"
                                onChange={() => setIsSameMarkups(!isSameMarkups)}
                                size="small"
                                checked={isSameMarkups}
                            />
                            <label htmlFor="sameMarkup">
                                <FadedText>Same markup for bid and ask</FadedText>
                            </label>
                        </HStack>
                    )}
                    {apiError && <Error>{apiError}</Error>}
                    <VStack padding={12} paddingTop={16} spacing={10}>
                        <PrimaryButton
                            fullWidth
                            size="large"
                            type="submit"
                            disabled={touchedMarkupsCount === 0}
                            loading={isSubmitting}
                        >
                            {applyButtonText}
                        </PrimaryButton>
                        <PlainButton fullWidth size="large" type="button" onClick={handleClose}>
                            Cancel
                        </PlainButton>
                    </VStack>
                </VStack>
            </Form>
        </Modal>
    );
};
