import {
    Flex,
    FormTab,
    FormTabList,
    IconButton,
    Icons,
    PrimaryButton,
    Search,
    TabContext,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useModalControl } from "@fm-frontend/utils";
import { post } from "api";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { createNotification, setConfirmModalContent } from "feature/app";
import { useInitialValues } from "feature/form/initialValuesUtils";
import { deleteSettlementRequest } from "feature/settlements/api";
import { useEffect, useMemo, useState, VFC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getTabNumericLabel } from "utils";
import { CreateSettlementModal } from "./CreateSettlementModal";
import { SettlementDetailsModal } from "./SettlementDetailsModal";
import { SettlementInfo } from "./SettlementDetailsModal/detailsModals/components/SettlementInfo";
import { SettlementsTable } from "./SettlementsTable";
import { Settlement } from "./types";
import { useSettlements } from "./useSettlements";

const Container = styled(Flex)`
    overflow: auto;
    min-height: calc(100vh - 87px);

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const Header = styled.div`
    margin: 12px;
    margin-left: 8px;
    display: flex;
    justify-content: space-between;
`;

const HeaderActions = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-end;

    .search {
        width: 160px;
        input::placeholder {
            font-size: 12px;
        }
    }
`;

type FilterValue = {
    statusFilter: Settlement["status"][];
    assetFilter: Settlement["asset"][];
    cpFilter: number[];
};

const DEFAULT_FILTER_VALUE: FilterValue = {
    statusFilter: [],
    assetFilter: [],
    cpFilter: [],
};

const STATUS_OPTIONS: DropdownOption<Settlement["status"]>[] = [
    {
        text: "Request",
        value: "request",
    },
    {
        text: "Created",
        value: "created",
    },
    {
        text: "Sent",
        value: "sent",
    },
    {
        text: "Received",
        value: "received",
    },
    {
        text: "Committed",
        value: "committed",
    },
    {
        text: "Cancelled",
        value: "cancelled",
    },
];

export const SettlementsPageContent: VFC = () => {
    const dispatch = useDispatch();
    const [selectedSettlement, setSelectedSettlement] = useState<Settlement | null>(null);
    const [selectedFilterTab, setSelectedFilterTab] = useState<"all" | "in" | "out" | "requests">(
        "all",
    );
    const [searchQuery, setSearchQuery] = useState("");

    const removeSettlement = async (settlement: Settlement) => {
        const isRequest = settlement.status === "request";
        const isSentTransaction = settlement.status === "sent";
        const title = isRequest
            ? "Delete request?"
            : `${isSentTransaction ? "Cancel" : "Delete"} transaction?`;
        const contentMessage = `This action cannot be undone. Are you sure you want to ${
            isSentTransaction ? "cancel" : "delete"
        } this ${isRequest ? "request" : "transaction"}?`;
        const successMessage = isRequest
            ? "Request was deleted"
            : `Transaction was ${isSentTransaction ? "cancelled" : "deleted"}`;

        dispatch(
            setConfirmModalContent({
                title,
                onConfirm: async () => {
                    try {
                        if (isRequest) {
                            await deleteSettlementRequest({
                                requestId: settlement.id,
                            });
                        } else {
                            await post("delOutgoingSettlementTransaction", {
                                transactionId: settlement.id,
                            });
                        }

                        dispatch(
                            createNotification({
                                type: "success",
                                content: successMessage,
                            }),
                        );
                    } catch (e) {
                        dispatch(
                            createNotification({
                                type: "error",
                                content: String(e),
                            }),
                        );
                    }
                },
                description: (
                    <VStack spacing={8}>
                        <span>{contentMessage}</span>
                        <SettlementInfo settlement={settlement} />
                    </VStack>
                ),
                cancelButtonTitle: isSentTransaction ? "Keep transaction" : "Cancel",
                confirmButtonTitle: isSentTransaction ? "Yes, cancel" : "Yes, Delete",
            }),
        );
    };

    const [defaultNewSettlementValues, setDefaultNewSettlementValues] = useState<null | any>(null);
    const {
        isModalOpen: isCreateSettlementModalOpened,
        openModal: openCreateSettlementModal,
        closeModal: closeCreateSettlementModal,
    } = useModalControl();

    useInitialValues("settlement", setDefaultNewSettlementValues);
    useEffect(() => {
        if (defaultNewSettlementValues) {
            openCreateSettlementModal();
            setTimeout(() => setDefaultNewSettlementValues(null), 0);
        }
    }, [defaultNewSettlementValues]);

    const { settlements, isLoading } = useSettlements();
    const assetFilterOptions: DropdownOption<Settlement["asset"]>[] = [
        ...new Set(settlements.map(({ asset }) => asset)),
    ].map((asset) => ({
        text: asset.toLocaleUpperCase(),
        value: asset,
    }));
    const cpFilterOptions: DropdownOption<number>[] = Object.entries(
        settlements.reduce(
            (acc, { cpId, cpName }) => ({
                ...acc,
                [cpId]: cpName,
            }),
            {},
        ),
    ).map(([cpId, cpName]) => ({
        text: String(cpName),
        value: Number(cpId),
    }));
    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm<FilterValue>({
        defaultValues: { ...DEFAULT_FILTER_VALUE },
    });
    const statusFilter = watch("statusFilter");
    const assetFilter = watch("assetFilter");
    const cpFilter = watch("cpFilter");

    const resetFilter = () => {
        reset({ ...DEFAULT_FILTER_VALUE });
        setSearchQuery("");
    };

    const { incomingCount, outgoingCount, requestCount } = useMemo(
        () =>
            settlements.reduce<{
                incomingCount: number;
                outgoingCount: number;
                requestCount: number;
            }>(
                (acc, { type, status }) => {
                    if (status === "request") {
                        acc.requestCount++;
                    }
                    if (status !== "committed" && status !== "cancelled" && status !== "request") {
                        acc[type === "in" ? "incomingCount" : "outgoingCount"]++;
                    }
                    return acc;
                },
                {
                    incomingCount: 0,
                    outgoingCount: 0,
                    requestCount: 0,
                },
            ),
        [settlements],
    );
    const filteredSettlements = useMemo(
        () =>
            settlements.filter(({ type, comment, cpId, cpName, asset, network, status, txId }) => {
                let matched =
                    (statusFilter.length === 0 || statusFilter.includes(status)) &&
                    (assetFilter.length === 0 || assetFilter.includes(asset)) &&
                    (cpFilter.length === 0 || cpFilter.includes(cpId));
                if (matched && selectedFilterTab !== "all") {
                    if (selectedFilterTab === "in" || selectedFilterTab === "out") {
                        matched = type === selectedFilterTab && status !== "request";
                    }
                    if (selectedFilterTab === "requests") {
                        matched = status === "request";
                    }
                }

                const query = searchQuery.trim().toLocaleLowerCase();
                if (matched && query) {
                    matched =
                        comment.toLocaleLowerCase().includes(query) ||
                        String(cpId).toLocaleLowerCase().includes(query) ||
                        cpName.toLocaleLowerCase().includes(query) ||
                        Boolean(asset?.toLocaleLowerCase().includes(query)) ||
                        Boolean(network?.toLocaleLowerCase().includes(query)) ||
                        txId.toLocaleLowerCase().includes(query);
                }

                return matched;
            }),
        [settlements, selectedFilterTab, searchQuery, statusFilter, assetFilter, cpFilter],
    );

    return (
        <>
            <Container spacing={8} paddingLeft={8} paddingRight={8}>
                <CardContainer flex={1} asCard minWidth="445px">
                    <Header>
                        <TabContext
                            value={selectedFilterTab}
                            handleClick={(v) =>
                                setSelectedFilterTab(v as "all" | "in" | "out" | "requests")
                            }
                        >
                            <FormTabList>
                                <FormTab
                                    title="All"
                                    label={getTabNumericLabel(
                                        incomingCount + outgoingCount + requestCount,
                                    )}
                                    value="all"
                                />
                                <FormTab
                                    title="Incoming"
                                    label={getTabNumericLabel(incomingCount)}
                                    value="in"
                                />
                                <FormTab
                                    title="Outgoing"
                                    label={getTabNumericLabel(outgoingCount)}
                                    value="out"
                                />
                                <FormTab
                                    title="Requests"
                                    label={getTabNumericLabel(requestCount)}
                                    value="requests"
                                />
                            </FormTabList>
                        </TabContext>
                        <HeaderActions>
                            <Search
                                size="small"
                                placeholder="Search"
                                query={searchQuery}
                                onChange={(value) => setSearchQuery(value)}
                                className="search"
                            />
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <MultipleDropdown
                                        values={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(trigger) => (
                                            <MultipleDropdown.Trigger
                                                {...trigger}
                                                size="small"
                                                variant="simple"
                                            >
                                                <CurrencyTriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOptions}
                                                    noIcon
                                                    size="small"
                                                />
                                            </MultipleDropdown.Trigger>
                                        )}
                                        options={assetFilterOptions}
                                        align="end"
                                        caption="Asset"
                                        asFilter
                                    >
                                        <CurrencyDropdownSheet
                                            size="medium"
                                            options={assetFilterOptions}
                                            Dropdown={MultipleDropdown}
                                        />
                                    </MultipleDropdown>
                                )}
                                name="assetFilter"
                            />
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <MultipleDropdown
                                        values={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(trigger) => (
                                            <MultipleDropdown.Trigger
                                                {...trigger}
                                                size="small"
                                                variant="simple"
                                            >
                                                <CounterpartyTriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOptions}
                                                    noIcon
                                                    size="small"
                                                />
                                            </MultipleDropdown.Trigger>
                                        )}
                                        options={cpFilterOptions}
                                        align="end"
                                        caption="CP"
                                        asFilter
                                    >
                                        <CounterpartiesSheet
                                            size="medium"
                                            options={cpFilterOptions}
                                            Dropdown={MultipleDropdown}
                                        />
                                    </MultipleDropdown>
                                )}
                                name="cpFilter"
                            />
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <MultipleDropdown
                                        values={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(trigger) => (
                                            <MultipleDropdown.Trigger
                                                {...trigger}
                                                size="small"
                                                variant="simple"
                                            >
                                                <MultipleDropdown.TriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOptions}
                                                    size="small"
                                                />
                                            </MultipleDropdown.Trigger>
                                        )}
                                        options={STATUS_OPTIONS}
                                        align="end"
                                        caption="Status"
                                        asFilter
                                    >
                                        <MultipleDropdown.BasicSheet
                                            size="small"
                                            options={STATUS_OPTIONS}
                                        />
                                    </MultipleDropdown>
                                )}
                                name="statusFilter"
                            />
                            {(isDirty || searchQuery.trim() !== "") && (
                                <Tooltip content="Reset filter" align="center">
                                    <IconButton
                                        variant="plain"
                                        type="button"
                                        Icon={Icons.Recent}
                                        onClick={resetFilter}
                                    />
                                </Tooltip>
                            )}
                            <PrimaryButton size="small" onClick={openCreateSettlementModal}>
                                Create
                            </PrimaryButton>
                        </HeaderActions>
                    </Header>
                    <SettlementsTable
                        data={filteredSettlements}
                        onSendClick={setSelectedSettlement}
                        onRemoveClick={removeSettlement}
                        onCreateTransactionByRequestClick={setSelectedSettlement}
                        onCommitClick={setSelectedSettlement}
                        onRowSelected={setSelectedSettlement}
                        searchQuery={searchQuery}
                        isLoading={isLoading}
                    />
                </CardContainer>
            </Container>
            {selectedSettlement && (
                <SettlementDetailsModal
                    settlement={selectedSettlement}
                    onContinueClick={setSelectedSettlement}
                    closeModal={() => setSelectedSettlement(null)}
                />
            )}
            {isCreateSettlementModalOpened && (
                <CreateSettlementModal
                    closeModal={closeCreateSettlementModal}
                    onContinueClick={setSelectedSettlement}
                    defaultValues={defaultNewSettlementValues}
                />
            )}
        </>
    );
};
