import { H2, Icons, PrimaryButton, Search } from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { onMobile } from "@fm-frontend/uikit/src/const";
import { POSITION_COUNTERPARTY_ID } from "const/position";
import { CounterpartiesPreview, Styled } from "feature/counterparties";
import { Counterparty } from "feature/counterparties/counterparty";
import { AddNewLimit } from "feature/counterparties/counterpartyLimitModal/AddNewLimit";
import { useCounterpartiesTitle } from "feature/counterparties/hooks/useCounterpartiesTitle";
import { ActionsBar } from "feature/counterparties/styled";
import { CounterpartyLimitDict } from "feature/counterparties/types";
import {
    CounterpartyStatusGroup,
    CounterpartyStatusGroupType,
    CounterpartyStatusType
} from "feature/counterparties/utils";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { FC, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CpStatus } from "store/counterpartiesSlice";
import { useFilteredAndExtendedCounterpartyLimitsSnapshot, useIsSubaccountUser } from "store/hooks";
import { Spacer } from "style";
import styled, { useTheme } from "styled-components";

const STATUS_GROUP_OPTIONS = Object.entries(CounterpartyStatusGroup).map(
    ([type, status]) =>
        ({
            text: status.title,
            value: type,
        } as DropdownOption),
);

type FormFieldValues = {
    statusGroups: string[];
};

type CounterpartyListProps = {
    counterpartiesStatuses: Record<string, CpStatus>;
    activeStatusGroups: CounterpartyStatusGroupType[];
    onChangeStatusGroups: (statusGroups: CounterpartyStatusGroupType[]) => void;
};

const CounterpartySearch = styled(Search)`
    width: 164px;
`;

const AddRiskProfileText = styled.span`
    ${onMobile} {
        display: none;
    }
`;

const DataContainer = styled.div`
    display: flex;
    gap: 8px;
`;

const CounterpartiesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
`;

const CounterpartiesPreviewContainer = styled.div`
    display: flex;
`;

const EmptyContainer = styled.div`
    display: flex;
    align-items: center;
    height: 200px;
`;

export const CounterpartyList: FC<CounterpartyListProps> = ({
    counterpartiesStatuses,
    activeStatusGroups,
    onChangeStatusGroups,
}) => {
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState("");
    const isSubaccountUser = useIsSubaccountUser();
    const { data: counterpartyLimits } = useFilteredAndExtendedCounterpartyLimitsSnapshot();
    const { getCpName } = useCpInfoHelpers();
    const title = useCounterpartiesTitle();

    const { control, watch, setValue } = useForm<FormFieldValues>({
        defaultValues: {
            statusGroups: activeStatusGroups.map(String),
        },
    });

    const searchStatusGroups = watch("statusGroups");

    useEffect(
        () => onChangeStatusGroups(searchStatusGroups.map(Number)),
        [JSON.stringify(searchStatusGroups), onChangeStatusGroups],
    );

    useEffect(() => setValue("statusGroups", activeStatusGroups.map(String)), [JSON.stringify(activeStatusGroups)]);

    const counterpartyLimitsObj = useMemo(
        () =>
            counterpartyLimits.reduce<CounterpartyLimitDict>((acc, extendedLimit) => {
                acc[extendedLimit.limit[POSITION_COUNTERPARTY_ID]] = extendedLimit;
                return acc;
            }, {}),
        [counterpartyLimits],
    );

    const allIds = useMemo(() => Object.keys(counterpartyLimitsObj), [counterpartyLimitsObj]);

    const allIdsWithUsernames = useMemo(
        () =>
            allIds
                .map((counterpartyId) => ({ id: counterpartyId, title: getCpName(counterpartyId, "full") }))
                .sort((cpLeft, cpRight) => {
                    if (!Object.keys(counterpartiesStatuses).length) {
                        return 0;
                    }

                    if (counterpartiesStatuses[cpLeft.id]?.status === CounterpartyStatusType.STOPPED) {
                        return 1;
                    }

                    if (counterpartiesStatuses[cpRight.id]?.status === CounterpartyStatusType.STOPPED) {
                        return -1;
                    }

                    return counterpartiesStatuses[cpRight.id]?.status - counterpartiesStatuses[cpLeft.id]?.status;
                }),
        [allIds, getCpName, counterpartiesStatuses],
    );
    const filteredIdsWithUsernames = useMemo(() => {
        const normalizedQuery = searchQuery.trim().toLowerCase();

        if (normalizedQuery === "" && activeStatusGroups.length === 0) {
            return allIdsWithUsernames;
        }

        return allIdsWithUsernames.filter((item) => {
            const isMatchQuery =
                normalizedQuery === "" ||
                item.id.startsWith(normalizedQuery) ||
                item.title.toLowerCase().includes(normalizedQuery);
            const isMatchStatus =
                activeStatusGroups.length === 0 ||
                activeStatusGroups.some((statusGroup) =>
                    CounterpartyStatusGroup[statusGroup].status.includes(counterpartiesStatuses[item.id]?.status),
                );

            return isMatchQuery && isMatchStatus;
        });
    }, [allIdsWithUsernames, searchQuery, activeStatusGroups]);

    const cpCount = Object.entries(counterpartiesStatuses)?.length;

    const [riskProfileKey, setRiskProfileKey] = useState(1);
    const handleCloseAddRiskProfile = () => setRiskProfileKey((id) => id + 1);

    return (
        <Styled.BlockContainer>
            {!isSubaccountUser && (
                <ActionsBar>
                    <Styled.Label>
                        <H2>{title}</H2>
                        <H2>{cpCount}</H2>
                    </Styled.Label>
                    <Spacer />
                    <CounterpartySearch
                        query={searchQuery}
                        onChange={setSearchQuery}
                        placeholder="Name or Client ID"
                        size="small"
                    />
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <MultipleDropdown
                                values={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                                        <MultipleDropdown.TriggerEssence
                                            {...trigger}
                                            option={trigger.selectedOptions}
                                            size="small"
                                        />
                                    </MultipleDropdown.Trigger>
                                )}
                                options={STATUS_GROUP_OPTIONS}
                                align="end"
                                caption="Status"
                                asFilter
                            >
                                <MultipleDropdown.BasicSheet size="small" options={STATUS_GROUP_OPTIONS} />
                            </MultipleDropdown>
                        )}
                        name="statusGroups"
                    />
                    <AddNewLimit
                        key={riskProfileKey}
                        renderButton={(openModal) => (
                            <PrimaryButton size="small" onClick={openModal}>
                                <Icons.Plus color={theme.colors.uiWhite72} />
                                <AddRiskProfileText>Add risk profile</AddRiskProfileText>
                            </PrimaryButton>
                        )}
                        onClose={handleCloseAddRiskProfile}
                    />
                </ActionsBar>
            )}
            <DataContainer>
                <CounterpartiesContainer>
                    {filteredIdsWithUsernames.map(({ id, title: cpTitle }) => (
                        <Counterparty
                            key={`${id}_${riskProfileKey}`}
                            cpId={id}
                            cpTitle={cpTitle}
                            cpStatus={counterpartiesStatuses[id]}
                            searchQuery={searchQuery.trim()}
                            extendedLimit={counterpartyLimitsObj[id]}
                        />
                    ))}
                </CounterpartiesContainer>
                <CounterpartiesPreviewContainer>
                    <Styled.SidebarContainer>
                        {cpCount > 1 && (
                            <CounterpartiesPreview
                                counterpartiesStatuses={counterpartiesStatuses}
                                activeStatusGroups={activeStatusGroups}
                                onChangeStatusGroups={onChangeStatusGroups}
                            />
                        )}
                    </Styled.SidebarContainer>
                </CounterpartiesPreviewContainer>
            </DataContainer>
            {filteredIdsWithUsernames.length === 0 && (
                <EmptyContainer>
                    <EmptySearch />
                </EmptyContainer>
            )}
        </Styled.BlockContainer>
    );
};
