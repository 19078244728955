import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { EMPTY_ARRAY } from "const";
import { useFilteredCpsIdsFromLimits } from "hooks/useCounterparties";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useMemo } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

type CpFilterProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
};

export const CpDropdownSelector = <T extends FieldValues>({
    control,
    name,
    cpsIds,
}: CpFilterProps<T> & { cpsIds: number[] }) => {
    const { getCpName } = useCpInfoHelpers();
    const options = useMemo(
        () =>
            cpsIds
                .map<DropdownOption<number>>((id) => ({
                    text: getCpName(id, "full"),
                    value: Number(id),
                }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [cpsIds, getCpName],
    );

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    options={options}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                            <CounterpartyTriggerEssence
                                {...trigger}
                                size="small"
                                option={trigger.selectedOptions}
                                noIcon
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    caption="CP"
                    asFilter
                >
                    <CounterpartiesSheet
                        size="medium"
                        options={options}
                        Dropdown={MultipleDropdown}
                    />
                </MultipleDropdown>
            )}
            name={name}
        />
    );
};

const REFETCH_LIMITS_INTERVAL_MS = 60_000;
export const CpFilter = ({ control, name }: any) => {
    const { cpsIds } = useFilteredCpsIdsFromLimits(REFETCH_LIMITS_INTERVAL_MS);

    return (
        <CpDropdownSelector
            control={control}
            name={name}
            cpsIds={cpsIds ?? (EMPTY_ARRAY as number[])}
        />
    );
};
