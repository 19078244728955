import { InstrumentWhitelistingSwitch } from "feature/assetsControl/components/InstrumentsTabContent/InstrumentWhitelistingSwitch";
import styled from "styled-components";
import { InstrumentTableData } from "../types";

const Container = styled.span`
    float: right;
`;

export const WhitelistingSwitchCell = ({ instrumentData }: { instrumentData: InstrumentTableData }) => (
    <Container onClick={(ev) => ev.stopPropagation()}>
        <InstrumentWhitelistingSwitch instrumentName={instrumentData.instrument.instrumentName} />
    </Container>
);
