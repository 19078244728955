import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { broadcastEvent, setConfirmModalContent } from "../feature/app";
import { logout } from "../feature/auth";

export const useHandleLogout = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(
            setConfirmModalContent({
                title: "Logout",
                onConfirm: () => {
                    dispatch(logout());
                    dispatch(broadcastEvent(logout()));
                },
                description: "Are you sure you want to logout?",
                confirmButtonTitle: "Logout",
            }),
        );
    }, []);
};
