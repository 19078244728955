import { Auth } from "aws-amplify";
import useSWR from "swr";

const fetchEmail = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        return user?.attributes?.email;
    } catch (error) {
        throw new Error("Failed to fetch email");
    }
};

type SWRKey = string;
export const useUserEmail = () => {
    const { data, isLoading } = useSWR<SWRKey, any, string>("userEmail", fetchEmail);

    return {
        email: data,
        isLoading: isLoading,
    };
};
