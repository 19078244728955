import React from "react";
import styled, { css, DefaultTheme, StyledComponent } from "styled-components";
import { PSmall } from "../../../typography";
import { TriggerIcon } from "../TriggerIcon";
import { TriggerSize, TriggerVariant } from "../types";

const Caption = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;
const InlineCaptionContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 4px;

    ${Caption} {
        color: ${(p) => p.theme.colors.ui52};
    }
`;
const Error = styled(PSmall)`
    color: ${(p) => p.theme.colors.negative100};
`;
const Hint = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui32};
`;

const Essence = styled.div<{ $variant: TriggerVariant }>`
    display: flex;
    flex: 1;
    gap: 6px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;

    ${(p) =>
        p.$variant === "simple" &&
        css`
            justify-content: flex-end;
        `}

    > span,
    p {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const StyledTrigger = styled.div<{ $active: boolean; $error: boolean; $filterActive: boolean }>`
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.ui8};
    width: 100%;

    :hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.colors.ui12};
        box-shadow: none;
    }

    :focus-visible {
        background-color: ${(p) => p.theme.colors.ui12};
        box-shadow: 0 0 0 3px rgba(22, 199, 176, 0.2), 0 0 0 1px #16c7b0 inset;
        outline: none;
    }

    ${(p) =>
        p.$active &&
        css`
            background-color: ${(p) => p.theme.colors.ui12};
        `};

    ${(p) =>
        p.$filterActive &&
        css`
            background-color: ${(p) => p.theme.colors.brand8};
            box-shadow: 0 0 0 1px ${(p) => p.theme.colors.brand20} inset,
                0 -1px 0 0 ${(p) => p.theme.colors.brand52} inset;
        `};

    ${(p) =>
        p.$error &&
        css`
            box-shadow: 0 0 0 3px rgba(246, 60, 48, 0.12), 0 0 0 1px #f63c30 inset !important;
        `};

    overflow: hidden;
`;

export const LargeTriggerLayout = styled(StyledTrigger)`
    display: flex;
    padding: 12px;
    max-height: 44px;
    height: 44px;
    align-items: center;
`;

export const MediumTriggerLayout = styled(StyledTrigger)`
    display: flex;
    padding: 6px 4px 6px 8px;
    max-height: 32px;
    height: 32px;
    align-items: center;
`;

export const SmallTriggerLayout = styled(StyledTrigger)`
    display: flex;
    padding: 2px 4px 2px 8px;
    max-height: 24px;
    height: 24px;
    align-items: center;
`;

const TriggerContainer = styled.div<{ $disabled: boolean; $fullWidth: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: ${(p) => (p.$fullWidth ? "100%" : "fit-content")};

    ${(p) =>
        p.$disabled &&
        css`
            ${StyledTrigger} {
                cursor: not-allowed !important;
                background-color: ${(p) => p.theme.colors.ui4} !important;

                svg path {
                    fill: ${(p) => p.theme.colors.ui32};
                }
            }
            ${Essence}, ${InlineCaptionContainer} , ${Caption}, ${Hint} {
                color: ${(p) => p.theme.colors.ui20}!important;

                * {
                    color: ${(p) => p.theme.colors.ui20}!important;
                }
            }
        `};
`;

const TRIGGER_LAYOUT_MAP: Record<
    TriggerSize,
    StyledComponent<"div", DefaultTheme, { $error: boolean; $active: boolean; $filterActive: boolean }>
> = {
    small: SmallTriggerLayout,
    medium: MediumTriggerLayout,
    large: LargeTriggerLayout,
};

export type TriggerRenderProps = {
    disabled?: boolean;
    placeholder?: string;

    caption?: (() => React.ReactNode) | string;
    hint?: (() => React.ReactNode) | string;
    error?: string;
    fullWidth?: boolean;
};

type TriggerFnProps = {
    active: boolean;
    size: TriggerSize;
    variant: TriggerVariant;
    children: React.ReactNode;
    filterActive?: boolean;
} & TriggerRenderProps;
export const TriggerFn = React.forwardRef<HTMLDivElement, TriggerFnProps>(
    (
        {
            size,
            variant,
            active,
            caption,
            hint,
            error,
            disabled = false,
            fullWidth = false,
            filterActive = false,
            children,
            ...rest
        },
        ref,
    ) => {
        const TriggerLayout = TRIGGER_LAYOUT_MAP[size];
        const hasError = Boolean(error);
        const hasHint = hint && !hasError;
        const hintContent = typeof hint === "function" ? hint?.() : <Hint>{hint}</Hint>;
        const hasCaption = Boolean(caption);
        const captionContent = typeof caption === "function" ? caption?.() : <Caption>{caption}</Caption>;

        return (
            <TriggerContainer $disabled={disabled} $fullWidth={fullWidth}>
                {hasCaption && variant === "basic" && captionContent}
                <TriggerLayout
                    {...rest}
                    data-field-group
                    $error={hasError}
                    $active={active}
                    $filterActive={filterActive}
                    tabIndex={0}
                    ref={ref}
                >
                    {hasCaption && variant == "simple" && (
                        <InlineCaptionContainer>{captionContent}</InlineCaptionContainer>
                    )}
                    <Essence $variant={variant}>{children}</Essence>
                    <TriggerIcon size={size} active={active} />
                </TriggerLayout>
                {hasError && <Error>{error}</Error>}
                {hasHint && hintContent}
            </TriggerContainer>
        );
    },
);
