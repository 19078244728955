import {
    Flex,
    FormTab,
    FormTabList,
    IconButton,
    Icons,
    PrimaryButton,
    Search,
    TabContext,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { Settlement } from "feature/settlements/types";
import { useSettlements } from "feature/settlements/useSettlements";
import { useMemo, useState, VFC } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { getTabNumericLabel } from "utils";
import { Transaction } from "../types";
import { TransactionsTable } from "./TransactionsTable";
import { useActions } from "./useActions";

const Container = styled(Flex)`
    overflow: auto;
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const Header = styled.div`
    margin: 12px;
    margin-left: 8px;
    display: flex;
    justify-content: space-between;
`;

const HeaderActions = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-end;

    .search {
        width: 160px;
        input::placeholder {
            font-size: 12px;
        }
    }
`;

type FilterValue = {
    statusFilter: Settlement["status"][];
    assetFilter: Settlement["asset"][];
    cpFilter: number[];
};

const DEFAULT_FILTER_VALUE: FilterValue = {
    statusFilter: [],
    assetFilter: [],
    cpFilter: [],
};

const STATUS_OPTIONS: DropdownOption<Settlement["status"]>[] = [
    {
        text: "Request",
        value: "request",
    },
    {
        text: "Created",
        value: "created",
    },
    {
        text: "Sent",
        value: "sent",
    },
    {
        text: "Received",
        value: "received",
    },
    {
        text: "Committed",
        value: "committed",
    },
    {
        text: "Cancelled",
        value: "cancelled",
    },
];

export const PBSubaccountsViewTransactionsPageContent: VFC = () => {
    const [selectedFilterTab, setSelectedFilterTab] = useState<
        "all" | "deposits" | "withdrawals" | "requests"
    >("all");
    const {
        commitDeposit,
        createTransaction,
        createTransactionByRequest,
        autoCommitTransaction,
        deleteSettlement,
        actionsContent,
    } = useActions();
    const [searchQuery, setSearchQuery] = useState("");

    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm<FilterValue>({
        defaultValues: { ...DEFAULT_FILTER_VALUE },
    });

    const filterValues = watch();
    const resetFilter = () => {
        reset({ ...DEFAULT_FILTER_VALUE });
    };

    const handleCreateTransactionClick = () =>
        createTransaction(
            (selectedFilterTab === "withdrawals" ? "withdrawal" : "deposit") as Transaction["type"],
        );

    const { settlements, isLoading } = useSettlements();
    const assetFilterOptions: DropdownOption<Settlement["asset"]>[] = [
        ...new Set(settlements.map(({ asset }) => asset)),
    ].map((asset) => ({
        text: asset.toLocaleUpperCase(),
        value: asset,
    }));
    const cpFilterOptions: DropdownOption<number>[] = Object.entries(
        settlements.reduce(
            (acc, { cpId, cpName }) => ({
                ...acc,
                [cpId]: cpName,
            }),
            {},
        ),
    ).map(([cpId, cpName]) => ({
        text: String(cpName),
        value: Number(cpId),
    }));

    const filteredSettlements = useMemo(
        () =>
            settlements.filter(({ type, comment, cpId, cpName, asset, network, status, txId }) => {
                let matched =
                    (filterValues.statusFilter.length === 0 ||
                        filterValues.statusFilter.includes(status)) &&
                    (filterValues.assetFilter.length === 0 ||
                        filterValues.assetFilter.includes(asset)) &&
                    (filterValues.cpFilter.length === 0 || filterValues.cpFilter.includes(cpId));
                if (matched && selectedFilterTab !== "all") {
                    if (selectedFilterTab === "deposits") {
                        matched = type === "in" && status !== "request";
                    }
                    if (selectedFilterTab === "withdrawals") {
                        matched = type === "out" && status !== "request";
                    }
                    if (selectedFilterTab === "requests") {
                        matched = status === "request";
                    }
                }

                const query = searchQuery.trim().toLocaleLowerCase();
                if (matched && query) {
                    matched =
                        comment.toLocaleLowerCase().includes(query) ||
                        String(cpId).toLocaleLowerCase().includes(query) ||
                        cpName.toLocaleLowerCase().includes(query) ||
                        Boolean(asset?.toLocaleLowerCase().includes(query)) ||
                        Boolean(network?.toLocaleLowerCase().includes(query)) ||
                        txId.toLocaleLowerCase().includes(query);
                }

                return matched;
            }),
        [settlements, selectedFilterTab, searchQuery, filterValues],
    );
    const { depositCount, withdrawalCount, requestCount } = useMemo(
        () =>
            settlements.reduce<{
                depositCount: number;
                withdrawalCount: number;
                requestCount: number;
            }>(
                (acc, { type, status }) => {
                    if (status === "request") {
                        acc.requestCount++;
                    } else if (status !== "committed" && status !== "cancelled") {
                        acc[type === "in" ? "depositCount" : "withdrawalCount"]++;
                    }
                    return acc;
                },
                {
                    depositCount: 0,
                    withdrawalCount: 0,
                    requestCount: 0,
                },
            ),
        [settlements],
    );

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <CardContainer flex={1} asCard minWidth="445px">
                <Header>
                    <TabContext
                        value={selectedFilterTab}
                        handleClick={(v) =>
                            setSelectedFilterTab(
                                v as "all" | "deposits" | "withdrawals" | "requests",
                            )
                        }
                    >
                        <FormTabList>
                            <FormTab
                                title="All"
                                label={getTabNumericLabel(
                                    depositCount + withdrawalCount + requestCount,
                                )}
                                value="all"
                            />
                            <FormTab
                                title="Deposits"
                                label={getTabNumericLabel(depositCount)}
                                value="deposits"
                            />
                            <FormTab
                                title="Withdrawals"
                                label={getTabNumericLabel(withdrawalCount)}
                                value="withdrawals"
                            />
                            <FormTab
                                title="Requests"
                                label={getTabNumericLabel(requestCount)}
                                value="requests"
                            />
                        </FormTabList>
                    </TabContext>
                    <HeaderActions>
                        <Search
                            size="small"
                            placeholder="Search"
                            query={searchQuery}
                            onChange={(value) => setSearchQuery(value)}
                            className="search"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <CurrencyTriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                noIcon
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={assetFilterOptions}
                                    align="end"
                                    caption="Asset"
                                    asFilter
                                >
                                    <CurrencyDropdownSheet
                                        size="medium"
                                        options={assetFilterOptions}
                                        Dropdown={MultipleDropdown}
                                    />
                                </MultipleDropdown>
                            )}
                            name="assetFilter"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <CounterpartyTriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                noIcon
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={cpFilterOptions}
                                    align="end"
                                    caption="CP"
                                    asFilter
                                >
                                    <CounterpartiesSheet
                                        size="medium"
                                        options={cpFilterOptions}
                                        Dropdown={MultipleDropdown}
                                    />
                                </MultipleDropdown>
                            )}
                            name="cpFilter"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <MultipleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={STATUS_OPTIONS}
                                    align="end"
                                    caption="Status"
                                    asFilter
                                >
                                    <MultipleDropdown.BasicSheet
                                        size="small"
                                        options={STATUS_OPTIONS}
                                    />
                                </MultipleDropdown>
                            )}
                            name="statusFilter"
                        />
                        {isDirty && (
                            <Tooltip content="Reset filter" align="center">
                                <IconButton
                                    variant="plain"
                                    type="button"
                                    Icon={Icons.Recent}
                                    onClick={resetFilter}
                                />
                            </Tooltip>
                        )}
                        <PrimaryButton size="small" onClick={handleCreateTransactionClick}>
                            Add new
                        </PrimaryButton>
                    </HeaderActions>
                </Header>
                <TransactionsTable
                    data={filteredSettlements}
                    isLoading={isLoading}
                    onCreateTransactionByRequest={createTransactionByRequest}
                    onCommitDepositClick={commitDeposit}
                    onAutoCommitTransactionClick={autoCommitTransaction}
                    onDeleteSettlementClick={deleteSettlement}
                />
            </CardContainer>
            {actionsContent}
        </Container>
    );
};
