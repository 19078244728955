import { VStack } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";
import { HintsContainer } from "../styled";

const Group = styled(VStack)`
    flex: 1;
    max-height: 100%;
`;

export const HintsLayout: FC = ({ children }) => (
    <HintsContainer>
        <Group>{children}</Group>
    </HintsContainer>
);
