import { post } from "api";
import useSWR from "swr";
import { PreparedInvite, prepareInvite } from "./types";

const fetcher = (url: string) => post(url, {}).then((data) => data.map(prepareInvite));

export const useInvitesList = () => {
    const { data, isLoading, mutate } = useSWR<PreparedInvite[]>("getInvitesList", fetcher);

    return {
        invitesList: data ?? [],
        mutate,
        isLoading,
    };
};
