import { HStack, Icons, PSmall, PSmallBold } from "@fm-frontend/uikit";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useUsdAmount } from "hooks/useUsdAmount";
import { useMemo } from "react";
import { useFilteredAndExtendedPositionsSnapshot } from "store/hooks";
import { useTheme } from "styled-components";
import { fmt, getAbsoluteValue } from "utils/format";
import { Amount, AssetAmount, Empty, HintContainer, HintContent, IconContainer, Loading, UsdAmount } from "../styled";
import { Hint } from "../types";
import { getFmtPrecisionConfig } from "../../../../utils";

export const LongPositions = ({ onPositionSelected }: { onPositionSelected: (requestInfo: Hint) => void }) => {
    const { colors } = useTheme();
    const { getCpName } = useCpInfoHelpers();
    const { getUsdAmount, isLoading: isUsdAmountLoading } = useUsdAmount();
    const { data: filteredExtendedPositionsSnapshot, isLoading: isPositionsLoading } =
        useFilteredAndExtendedPositionsSnapshot();
    const isLoading = isPositionsLoading || isUsdAmountLoading;
    const negativePositions = useMemo(() => {
        if (isLoading) {
            return [];
        }

        return filteredExtendedPositionsSnapshot
            .filter(({ position: [, value] }) => value > 0)
            .map(({ position: [asset, value, cpId] }) => {
                const amount = BigInt(getAbsoluteValue(value));

                return {
                    asset,
                    amount,
                    usdAmount: getUsdAmount(asset, amount),
                    cpId,
                    cpName: getCpName(cpId, "short"),
                };
            });
    }, [filteredExtendedPositionsSnapshot, getUsdAmount, getCpName, isLoading]);

    if (isLoading) {
        return <Loading />;
    }

    if (negativePositions.length === 0) {
        return <Empty message="No negative positions" />;
    }

    return (
        <>
            {negativePositions.map((positionInfo) => {
                const { cpName, asset, amount, usdAmount } = positionInfo;

                return (
                    <HintContainer key={`${cpName} ${asset}`} onClick={() => onPositionSelected(positionInfo)}>
                        <HintContent>
                            <HStack spacing={4}>
                                <PSmallBold>{cpName},</PSmallBold>
                                <PSmall>{asset}</PSmall>
                            </HStack>
                            <Amount>
                                <AssetAmount>
                                    {fmt(amount, {
                                        significantDigits: 8,
                                        type: "size64",
                                        unit: ` ${asset}`,
                                        unitPosition: "post",
                                        ...getFmtPrecisionConfig(asset),
                                    }).formattedValue}
                                </AssetAmount>
                                <UsdAmount>
                                    {fmt(usdAmount, {
                                        significantDigits: 2,
                                        type: "size64",
                                        unit: "$",
                                        unitPosition: "pre",
                                    }).formattedValue}
                                </UsdAmount>
                            </Amount>
                        </HintContent>
                        <IconContainer>
                            <Icons.ArrowReply color={colors.ui32} />
                        </IconContainer>
                    </HintContainer>
                );
            })}
        </>
    );
};
