import { useSearchParamState } from "hooks/useSearchParamState";

export const usePagesState = (defaultValue: number[]) => {
    return useSearchParamState<typeof defaultValue>("page", defaultValue, {
        persist: false,
        parseValue: Number,
        isArray: true,
    });
};

export const useRangeState = (defaultValue: { startDate?: Date | null; endDate?: Date | null }) => {
    return useSearchParamState<typeof defaultValue>("range", defaultValue, {
        persist: false,
        parseValue: (value = "") => {
            try {
                const [startDate, endDate] = JSON.parse(value);
                return {
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                };
            } catch (e) {
                return defaultValue;
            }
        },
        stringifyValue: (value) => {
            return JSON.stringify([value.startDate?.getTime(), value.endDate?.getTime()]);
        },
    });
};

export const useCounterpartiesState = (defaultValue: number[]) => {
    return useSearchParamState<typeof defaultValue>("cp", defaultValue, {
        persist: false,
        parseValue: Number,
        isArray: true,
    });
};

export const useInstrumentsState = (defaultValue: string[]) => {
    return useSearchParamState<typeof defaultValue>("instrument", defaultValue, {
        persist: false,
        isArray: true,
    });
};
