import { TextSmall } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";

export const ActiveInputCss = css`
    background-color: ${(p) => p.theme.colors.uiWhite100};
    box-shadow: 0 0 0 3px ${(p) => p.theme.colors.brand12}, inset 0 0 0 1px ${(p) => p.theme.colors.brand100};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    & > ${TextSmall}, ol {
        margin: 0;
        color: ${(p) => p.theme.colors.ui72};
    }

    ol {
        padding-left: 18px;
    }

    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
`;

export const EditContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    & > div > div {
        background-color: transparent;
        padding-right: 60px;

        :hover,
        :focus-within {
            box-shadow: none;
        }
    }

    input {
        text-align: right;
    }

    button {
        position: absolute;

        &:first-of-type {
            right: 30px;
        }

        &:last-of-type {
            right: 0;
            background-color: ${(p) => p.theme.colors.brand12};

            path {
                fill-opacity: 100;
                fill: ${(p) => p.theme.colors.brand100};
            }
        }
    }
`;
