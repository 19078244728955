import { useCounterpartiesWithTypes } from "feature/assetsControl/components/InstrumentsTabContent/MakerMasterInstrumentsTabContent/IntsrumentsTable/hooks";
import { useInstruments } from "hooks";
import { useMemo } from "react";
import { InstrumentTableData } from "../types";

export const useTableData = () => {
    const { instruments, isLoading: isInstrumentsLoading } = useInstruments();
    const { counterparties, cpIds, isLoading: isCpsLoading } = useCounterpartiesWithTypes();

    const instrumentsData: InstrumentTableData[] = useMemo(
        () =>
            Object.values(instruments)
                .sort(({ instrumentName: name1 }, { instrumentName: name2 }) =>
                    name1.localeCompare(name2),
                )
                .map((instrument) => ({
                    key: instrument.id,
                    instrument,
                    counterparties: cpIds
                        .map((cpId) => ({
                            id: cpId,
                            name: counterparties[cpId].name,
                            cpType: counterparties[cpId].cpType,
                        }))
                        .filter(({ cpType }) => cpType !== "maker")
                        .sort(({ name: name1, id: id1 }, { name: name2, id: id2 }) =>
                            name1 !== name2 ? name1.localeCompare(name2) : id1 - id2,
                        ),
                })),
        [cpIds, counterparties, instruments],
    );

    const isLoading = isInstrumentsLoading || isCpsLoading;

    return {
        instrumentsData,
        isLoading,
    };
};
