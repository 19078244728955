import { P } from "@fm-frontend/uikit";
import styled from "styled-components";

const Container = styled.div<{ $isEnabledByMaster: boolean }>`
    height: 100%;
    align-items: center;
    padding: 0 12px;
    display: flex;
    flex-direction: row-reverse;

    background-color: ${(p) => (p.$isEnabledByMaster ? "transparent" : p.theme.colors.negative4)};
    border-left: 1px solid ${(p) => p.theme.colors.ui8};
`;

export const MasterStatusCell = ({ isEnabledByMaster }: { isEnabledByMaster: boolean }) => {
    return (
        <Container $isEnabledByMaster={isEnabledByMaster}>
            <P>{isEnabledByMaster ? "Enabled" : "Disabled"}</P>
        </Container>
    );
};
