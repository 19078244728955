import { ModalButton, PrimaryButton } from "@fm-frontend/uikit";
import { useFormShouldCloseConfirm } from "@fm-frontend/utils";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getConfirmModalAction } from "hooks/useModalCloseWithConfirm";
import { AddNewTakerForm } from "../AddNewTakerForm";
import { useInvitesList } from "../useInvitesList";
import { REFERRALS_MODAL_KEY } from "../utils";

export const AddNewTakerButton: React.FC = () => {
    const dispatch = useDispatch();
    const { mutate: mutateInvitesList } = useInvitesList();

    const shouldCloseConfirm = useFormShouldCloseConfirm(REFERRALS_MODAL_KEY);

    const handleConfirm = useCallback((close: () => void) => {
        if (shouldCloseConfirm) {
            dispatch(getConfirmModalAction(close))
        } else {
            close();
        }
    }, [dispatch, shouldCloseConfirm]);

    return (
        <ModalButton
            beforeModalClose={mutateInvitesList}
            onConfirm={handleConfirm}
            renderModalContent={(closeModal) => <AddNewTakerForm onDone={closeModal} />}
            renderButton={(handleClick) => (
                <PrimaryButton size="small" onClick={handleClick}>
                    Invite new taker
                </PrimaryButton>
            )}
        />
    );
};
