import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { selectors } from "../../style/theme";
import { transition } from "../../utils";
const cssBool = (
    value: boolean | undefined,
    cssTrue: FlattenSimpleInterpolation,
    cssFalse: FlattenSimpleInterpolation,
) => (value ? cssTrue : cssFalse);
export const SlimContainer = styled.div<{ isExpanded?: boolean }>`
    background: ${(p) => p.theme.colors.mainBG};
    padding: 40px 15px 15px 15px;
    margin: 0 auto;
    width: 100%;
    ${(p) => transition(p.theme.transitions.default, "max-width")}
    ${(p) =>
        cssBool(
            p.isExpanded,
            css`
                max-width: 934px;
            `,
            css`
                min-width: 320px;
                max-width: 390px;
            `,
        )}
    & > subtitle {
        margin: 0 12px 20px 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.005em;
        color: ${selectors.textSubdued};
    }
`;

export const SlimWidget = styled.div`
    background: ${selectors.activeBG};
    border: 1px solid #e6e6e6;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 12px;
    overflow: hidden;
`;

export const SlimWidgetMessage = styled.div`
    background: ${selectors.activeBG};
    border: 1px solid #e6e6e6;
    box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 14px 32px 14px 48px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    position: relative;
    margin: 8px 0 24px;

    img {
        position: absolute;
        top: 17px;
        left: 12px;
    }

    svg {
        position: absolute;
        top: 17px;
        left: 12px;
    }
`;

export const HeadTitle = styled.div`
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: ${selectors.textFG};
    font-weight: 600;
`;

export const FormRow = styled.div`
    margin-top: 16px;

    label {
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -0.005em;
        color: ${selectors.textSubdued};
        display: block;
        padding-bottom: 8px;
        font-weight: 600;
    }

    input {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.005em;
        height: 44px;
        width: 100%;
        padding: 0px 12px 0px 12px;
        border-radius: 8px;
        background: #f2f2f2;

        :hover {
            background: linear-gradient(0deg, #ebebeb, #ebebeb), #f2f2f2;
        }

        ::placeholder {
            color: #ccc;
        }
    }
`;

export const Hint = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #ccc;
    padding-top: 4px;
`;

export const Text = styled.div`
    padding: 8px 0;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: ${selectors.textSubdued};

    b {
        font-weight: normal;
        color: ${selectors.textFG};
    }
    a {
        font-weight: 600;
        color: ${(p) => p.theme.colors.brand};
    }
    strong {
        font-weight: normal;
        color: ${selectors.textFG};
    }
`;

export const DefaultButton = styled.button`
    border: 2px solid #daf6f2;
    border-radius: 8px;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #16c7b0;
    padding: 0 12px;
    height: 44px;
    width: 100%;
    background: ${selectors.activeBG};
    cursor: pointer;
    font-weight: 600;

    :hover {
        border-color: rgba(22, 199, 176, 0.4);
    }

    :active {
        border-color: #33b8a7;
    }
`;

export const BottomLogin = styled.div`
    padding: 24px 12px 0 12px;
    font-size: 14px;
    letter-spacing: -0.005em;
    font-weight: 600;

    a {
        color: ${(p) => p.theme.colors.brand100};
        margin-right: 16px;
        font-weight: 600;

        :last-child {
            margin-right: 0;
        }
    }

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.005em;
        color: ${selectors.textSubdued};
        display: block;
    }

    a + span {
        margin-top: 8px;
    }
`;

export const GetAppRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f2f2f2;
        border-radius: 8px;
        height: 32px;
        min-width: 49%;
        line-height: 18px;
        margin-right: 4px;

        :hover {
            text-decoration: none;
            background: linear-gradient(0deg, #ebebeb, #ebebeb), #f2f2f2;
        }
    }

    svg {
        margin-right: 4px;
    }
`;

export const GetAppQRCode = styled.div`
    text-align: center;

    img {
        max-width: 280px;
    }
`;

export const GetAppCodeInput = styled.input`
    text-align: center;
`;

export const ButtonContainer = styled.p`
    margin-top: -8px;
    text-align: center;

    a {
        ${selectors.fontMedium}
        font-size: 14px;
    }
`;
